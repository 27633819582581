import _defineProperty from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectWithoutProperties";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _extends from "@babel/runtime-corejs3/helpers/extends";
import React from 'react';
import PropTypes from 'prop-types';
import { intlMessageShape, linkShape } from '@jutro/prop-types';
import { isIntlShape } from '@jutro/locale';
import { Popover } from '../Popover/Popover';
import { PopoverContainer } from '../PopoverContainer/PopoverContainer';
import styles from "./HelpPopover.module.css";
import { messages } from './HelpPopover.messages';
var helpPopoverPropTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node,
  title: intlMessageShape,
  footerLink: PropTypes.oneOfType([intlMessageShape, linkShape]),
  renderTrigger: PropTypes.func.isRequired
};
export var HelpPopover = function HelpPopover(_ref) {
  var id = _ref.id,
    title = _ref.title,
    footerLink = _ref.footerLink,
    renderTrigger = _ref.renderTrigger,
    popoverProps = _objectWithoutProperties(_ref, ["id", "title", "footerLink", "renderTrigger"]);
  footerLink = typeof footerLink === 'string' || isIntlShape(footerLink) ? {
    label: messages.viewAll,
    href: footerLink
  } : footerLink;
  return React.createElement(Popover, {
    id: id,
    renderTrigger: renderTrigger,
    className: styles.HelpPopover,
    isFlipEnabled: false
  }, React.createElement(PopoverContainer, _extends({
    title: title,
    footerLink: footerLink,
    className: styles.popoverContainer,
    internalClassNames: {
      body: styles.content,
      footer: styles.footer
    }
  }, popoverProps)));
};
HelpPopover.propTypes = helpPopoverPropTypes;
HelpPopover.defaultProps = _objectSpread({}, Popover.defaultProps, {
  title: messages.title
});
HelpPopover.__docgenInfo = {
  componentName: "HelpPopover",
  packageName: "@jutro/components",
  description: "",
  displayName: "HelpPopover",
  methods: [],
  actualName: "HelpPopover",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: true,
      description: "Used to identify this component."
    },
    children: {
      type: {
        name: "node"
      },
      required: false,
      description: "Help popover content"
    },
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Notification popover title",
      defaultValue: {
        value: "messages.title",
        computed: true
      }
    },
    footerLink: {
      type: {
        name: "union",
        value: [{
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "shape",
            value: {
              id: {
                name: "string",
                required: false
              },
              defaultMessage: {
                name: "string",
                required: false
              },
              args: {
                name: "shape",
                value: {},
                required: false
              }
            }
          }]
        }, {
          name: "shape",
          value: {
            label: {
              name: "custom",
              raw: "intlMessageShape.isRequired",
              required: true
            },
            onClick: {
              name: "func",
              required: false
            },
            href: {
              name: "custom",
              raw: "intlMessageShape",
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Description for footer link"
    },
    renderTrigger: {
      type: {
        name: "func"
      },
      required: true,
      description: "Function to render trigger element"
    }
  }
};