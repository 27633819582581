import _objectWithoutProperties from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectWithoutProperties";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { sanitizeProps } from '@jutro/platform';
var wrapperStyle = {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center'
};
var defaultTag = 'span';
var inlineLabelPropTypes = {
  icon: PropTypes.node,
  iconPosition: PropTypes.string,
  tag: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  children: PropTypes.node.isRequired
};
var InlineLabelInternal = React.forwardRef(function (_ref, ref) {
  var icon = _ref.icon,
    iconPosition = _ref.iconPosition,
    children = _ref.children,
    tag = _ref.tag,
    dangerouslySetInnerHTML = _ref.dangerouslySetInnerHTML,
    rest = _objectWithoutProperties(_ref, ["icon", "iconPosition", "children", "tag", "dangerouslySetInnerHTML"]);
  var isRightIcon = iconPosition === 'right';
  var isLeftIcon = !isRightIcon;
  var safeRest = sanitizeProps(rest);
  var Tag = tag || defaultTag;
  return React.createElement(Tag, _extends({
    ref: ref,
    style: wrapperStyle
  }, safeRest), isLeftIcon && icon, children, isRightIcon && icon);
});
InlineLabelInternal.propTypes = inlineLabelPropTypes;
InlineLabelInternal.defaultProps = {
  tag: defaultTag,
  iconPosition: 'left'
};
InlineLabelInternal.displayName = 'InlineLabel';
export var InlineLabel = memo(InlineLabelInternal);
InlineLabel.__docgenInfo = {
  componentName: "InlineLabel",
  packageName: "@jutro/components",
  description: "",
  displayName: "InlineLabel",
  methods: [],
  actualName: "InlineLabel",
  props: {
    icon: {
      type: {
        name: "node"
      },
      required: false,
      description: "Icon that's supposed to be attached"
    },
    iconPosition: {
      type: {
        name: "string"
      },
      required: false,
      description: "Flag indicating which side should the optional icon take",
      defaultValue: {
        value: "'left'",
        computed: false
      }
    },
    tag: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "elementType"
        }]
      },
      required: false,
      description: "Tag used to wrap the label",
      defaultValue: {
        value: "'span'",
        computed: false
      }
    },
    children: {
      type: {
        name: "node"
      },
      required: true,
      description: "Content to render"
    }
  }
};