import _defineProperty from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import React, { useContext } from 'react';
import { TranslatorContext } from '@jutro/locale';
import cx from 'classnames';
import styles from "./CheckboxField.module.css";
import { Icon } from '../../Icon/Icon';
export var Checkbox = function Checkbox(_ref) {
  var id = _ref.id,
    checked = _ref.checked,
    label = _ref.label,
    required = _ref.required,
    controlClassName = _ref.controlClassName,
    hideLabel = _ref.hideLabel,
    showInlineLabel = _ref.showInlineLabel,
    onClick = _ref.onClick,
    onChange = _ref.onChange,
    onBlur = _ref.onBlur,
    onFocus = _ref.onFocus,
    onKeyDown = _ref.onKeyDown,
    disabled = _ref.disabled,
    accessibilityProps = _ref.accessibilityProps,
    dataPathProps = _ref.dataPathProps,
    renderInlineLabel = _ref.renderInlineLabel,
    children = _ref.children,
    detailElement = _ref.detailElement,
    value = _ref.value;
  var translator = useContext(TranslatorContext);
  var tabIndex = disabled ? undefined : 0;
  var checkboxElementClasses = cx(styles.checkboxElement);
  var checkboxElement = React.createElement("span", {
    onClick: onClick,
    className: checkboxElementClasses,
    "aria-label": translator(label),
    tabIndex: tabIndex,
    onBlur: onBlur,
    onFocus: onFocus,
    onKeyDown: onKeyDown,
    role: "checkbox",
    "aria-checked": checked
  }, checked && React.createElement(Icon, {
    icon: "mi-check",
    className: styles.checkboxIcon,
    "aria-hidden": "true"
  }));
  var detailVisible = checked && (children || detailElement);
  var detail = React.createElement("div", {
    className: cx(_defineProperty({}, styles.detailVisible, detailVisible)),
    role: "region",
    "aria-live": "assertive",
    "aria-hidden": !detailVisible
  }, detailVisible && (children || detailElement));
  var classes = cx(styles.checkbox, controlClassName, {
    disabled: disabled
  });
  return React.createElement(React.Fragment, null, React.createElement("div", _extends({
    className: classes
  }, accessibilityProps), React.createElement("input", _extends({
    id: id,
    type: "checkbox",
    value: value,
    checked: checked,
    onChange: onChange,
    disabled: disabled,
    className: styles.input,
    required: required
  }, dataPathProps)), checkboxElement, !hideLabel && showInlineLabel && renderInlineLabel(styles)), detail);
};
Checkbox.__docgenInfo = {
  componentName: "Checkbox",
  packageName: "@jutro/components",
  description: "",
  displayName: "Checkbox",
  methods: [],
  actualName: "Checkbox"
};