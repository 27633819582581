import _defineProperty from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import pick from "lodash/pick";
import { renderContentFromMetadata } from '../render/renderMetadataHelper';
function MetadataForm(props) {
  var callbackMap = props.callbackMap,
    classNameMap = props.classNameMap,
    componentMap = props.componentMap,
    data = props.data,
    onDataChange = props.onDataChange,
    onValidationChange = props.onValidationChange,
    overrideProps = props.overrideProps,
    resolveComponent = props.resolveComponent,
    resolveDataProps = props.resolveDataProps,
    resolveValidation = props.resolveValidation,
    resolveValueProp = props.resolveValue,
    showErrors = props.showErrors,
    showOptional = props.showOptional,
    showRequired = props.showRequired,
    uiProps = props.uiProps,
    parentPath = props.parentPath;
  var fieldValidationMessagesRef = useRef(null);
  var fieldValidationMessages = fieldValidationMessagesRef.current;
  var _useState = useState(fieldValidationMessages),
    _useState2 = _slicedToArray(_useState, 2),
    pageMessages = _useState2[0],
    setPageMessages = _useState2[1];
  var _useState3 = useState(false),
    _useState4 = _slicedToArray(_useState3, 2),
    hasDoneInitialValidation = _useState4[0],
    setHasDoneInitialValidation = _useState4[1];
  var onFieldValidationChanged = useCallback(function (isValid, path, message) {
    var fullPath = parentPath ? "".concat(parentPath, ".").concat(path) : path;
    var messages = _objectSpread({}, fieldValidationMessagesRef.current);
    if (isValid) {
      delete messages[fullPath];
    } else {
      messages[fullPath] = [message];
    }
    setPageMessages(_objectSpread({}, messages));
    fieldValidationMessagesRef.current = _objectSpread({}, messages);
    if (!hasDoneInitialValidation) {
      setHasDoneInitialValidation(true);
    }
  }, [fieldValidationMessagesRef, hasDoneInitialValidation]);
  var resolveValue = resolveValueProp !== null && resolveValueProp !== void 0 ? resolveValueProp : function (_id, path) {
    return get(data, path);
  };
  var resolvers = {
    resolveCallbackMap: callbackMap,
    resolveComponent: resolveComponent,
    resolveComponentMap: componentMap,
    resolveClassNameMap: classNameMap,
    resolveDataProps: resolveDataProps,
    resolveValidation: resolveValidation,
    resolveValue: resolveValue
  };
  var resolveCommonOverrides = function resolveCommonOverrides(type) {
    var typeOverrides = overrideProps ? overrideProps["@".concat(type)] : {};
    return _objectSpread({}, typeOverrides, {
      resolvers: pick(resolvers, ['resolveComponentMap', 'resolveClassNameMap'])
    });
  };
  var extendedProps = _objectSpread({}, overrideProps, {
    '@field': _objectSpread({
      onValueChange: onDataChange,
      onValidationChange: onFieldValidationChanged,
      showErrors: showErrors,
      showOptional: showOptional,
      showRequired: showRequired
    }, resolveCommonOverrides('field')),
    '@container': function container(id, path) {
      var containerOverrides = resolveCommonOverrides('container');
      return path ? _objectSpread({
        onDataChange: onDataChange
      }, containerOverrides) : containerOverrides;
    }
  });
  useEffect(function () {
    var areFieldsValid = isEmpty(fieldValidationMessages);
    if (onValidationChange) {
      onValidationChange(areFieldsValid, fieldValidationMessages);
    }
  }, [data, onValidationChange, fieldValidationMessages, pageMessages]);
  return React.createElement(React.Fragment, null, renderContentFromMetadata(uiProps, extendedProps, resolvers));
}
MetadataForm.displayName = 'MetadataForm';
MetadataForm.propTypes = {
  callbackMap: PropTypes.object,
  classNameMap: PropTypes.object,
  componentMap: PropTypes.object,
  data: PropTypes.object,
  onDataChange: PropTypes.func,
  onValidationChange: PropTypes.func,
  overrideProps: PropTypes.object,
  resolveValidation: PropTypes.func,
  resolveDataProps: PropTypes.func,
  resolveComponent: PropTypes.func,
  resolveValue: PropTypes.func,
  showErrors: PropTypes.bool,
  showOptional: PropTypes.bool,
  showRequired: PropTypes.bool,
  uiProps: PropTypes.object.isRequired
};
export default MetadataForm;
MetadataForm.__docgenInfo = {
  componentName: "MetadataForm",
  packageName: "@jutro/uiconfig",
  description: "MetadataForm",
  displayName: "MetadataForm",
  methods: [],
  actualName: "MetadataForm",
  props: {
    callbackMap: {
      type: {
        name: "object"
      },
      required: false,
      description: "Resolve callback string to callback function"
    },
    classNameMap: {
      type: {
        name: "object"
      },
      required: false,
      description: "Resolve class names to css module names"
    },
    componentMap: {
      type: {
        name: "object"
      },
      required: false,
      description: "Resolve component string to component"
    },
    data: {
      type: {
        name: "object"
      },
      required: false,
      description: "Data for this form"
    },
    onDataChange: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback when value is changed; receives new value and path for this component"
    },
    onValidationChange: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback when validation is changed; receives isValid and validation props for this component"
    },
    overrideProps: {
      type: {
        name: "object"
      },
      required: false,
      description: "Override default data props for rendered content"
    },
    resolveValidation: {
      type: {
        name: "func"
      },
      required: false,
      description: "Resolve validation from json schema"
    },
    resolveDataProps: {
      type: {
        name: "func"
      },
      required: false,
      description: "Resolve data props from json schema"
    },
    resolveComponent: {
      type: {
        name: "func"
      },
      required: false,
      description: "Resolve component from string and/or datatype"
    },
    resolveValue: {
      type: {
        name: "func"
      },
      required: false,
      description: "Resolve value from data using path"
    },
    showErrors: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Override to force showing input errors"
    },
    showOptional: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true, displays the `Optional` span"
    },
    showRequired: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true, displays the require symbol on required fields"
    },
    uiProps: {
      type: {
        name: "object"
      },
      required: true,
      description: "Content metadata or an array of metadata"
    }
  }
};