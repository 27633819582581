import _defineProperty from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import hoistStatics from 'hoist-non-react-statics';
export var createHOC = function createHOC(config) {
  var ComponentToWrap = config.component,
    Wrapper = config.wrapper,
    displayName = config.displayName,
    wrapperParams = config.wrapperParams;
  var WrappedComponent = Wrapper(ComponentToWrap, _objectSpread({}, wrapperParams));
  WrappedComponent.displayName = displayName || "".concat(Wrapper.name || 'Wrapper', "(").concat(ComponentToWrap.displayName || ComponentToWrap.name, ")");
  WrappedComponent.WrappedComponent = ComponentToWrap;
  if (ComponentToWrap.propTypes) {
    WrappedComponent.propTypes = ComponentToWrap.propTypes;
  }
  if (ComponentToWrap.defaultProps) {
    WrappedComponent.defaultProps = ComponentToWrap.defaultProps;
  }
  return hoistStatics(WrappedComponent, ComponentToWrap);
};