import _defineProperty from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _reverseInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reverse";
import _startsWithInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/starts-with";
import _ from 'lodash';
import { log } from '@jutro/logger';
import { refTypeMap, REF_PROPERTY, REF_PROPERTY_SHORTCUT } from '../common/refs';
export function resolveContentReference(item, rootMetadata) {
  if (_.isString(item) && _startsWithInstanceProperty(item).call(item, REF_PROPERTY_SHORTCUT)) {
    return extendMetadata(undefined, item.substr(REF_PROPERTY_SHORTCUT.length), rootMetadata);
  }
  if (item[REF_PROPERTY]) {
    return extendMetadata(item, item[REF_PROPERTY], rootMetadata);
  }
  return item;
}
export function extendMetadata(targetItem, refKey, rootMetadata) {
  if (!refKey) {
    return targetItem;
  }
  var parts = refKey.split('/');
  var baseItem = _.get(rootMetadata, parts);
  if (!baseItem) {
    log.warning("unable to resolve @ref: \"".concat(refKey, "\""));
    return targetItem;
  }
  var extendedItem = baseItem;
  if (targetItem) {
    extendedItem = _objectSpread({}, baseItem, {}, targetItem);
    delete extendedItem[REF_PROPERTY];
    if (baseItem.componentProps && targetItem.componentProps) {
      extendedItem.componentProps = _objectSpread({}, baseItem.componentProps, {}, targetItem.componentProps);
    }
  }
  fillInMissingValues(extendedItem, _reverseInstanceProperty(parts).call(parts), refKey);
  return extendedItem;
}
function fillInMissingValues(extendedItem, parts, refKey) {
  if (!extendedItem.id || !extendedItem.type) {
    var _parts = _slicedToArray(parts, 2),
      idFromRefKey = _parts[0],
      typeFromRefKey = _parts[1];
    if (!extendedItem.id) {
      if (idFromRefKey) {
        extendedItem.id = idFromRefKey;
      } else {
        log.warning("unable to resolve 'id' from: \"".concat(refKey, "\""));
      }
    }
    if (!extendedItem.type) {
      if (typeFromRefKey) {
        extendedItem.type = refTypeMap[typeFromRefKey];
      } else {
        log.warning("unable to resolve 'type' from: \"".concat(refKey, "\""));
      }
    }
  }
}