import _defineProperty from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import _startsWithInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/starts-with";
import _someInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/some";
import mapValues from "lodash/mapValues";
import omit from "lodash/omit";
import { warning } from '@jutro/logger';
var SAFE_PREFIXES = ['/', 'http', 'https', 'mailto:', 'tel:'];
var DANGEROUS_PROPS = ['href', 'to', 'action'];
export function isSafeUrl(url) {
  if (!url) {
    return true;
  }
  return _someInstanceProperty(SAFE_PREFIXES).call(SAFE_PREFIXES, function (prefix) {
    return _startsWithInstanceProperty(url).call(url, prefix);
  });
}
export function sanitizeUrl(url) {
  var allowNoLeadingSlash = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  if (!isSafeUrl(url) && !allowNoLeadingSlash) {
    warning("'".concat(url, "' is not a safe url value. The only allowed urls are the ones starting with: ").concat(SAFE_PREFIXES.join(', ')));
    return '';
  }
  return url;
}
export function sanitizeRouterTo(to) {
  if (typeof to === 'string') {
    return sanitizeUrl(to);
  }
  if (!to) {
    return to;
  }
  return _objectSpread({}, to, {
    pathname: to.pathname && sanitizeUrl(to.pathname)
  });
}
export function sanitizeProps(props) {
  return mapValues(props, sanitizeProp);
}
function sanitizeProp(value, key) {
  return _includesInstanceProperty(DANGEROUS_PROPS).call(DANGEROUS_PROPS, key) && typeof value === 'string' ? sanitizeUrl(value) : value;
}
export function removeDangerousProps(props) {
  return omit(props, 'dangerouslySetInnerHTML');
}