import _defineProperty from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import React, { Children } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, InlineLabel, Link } from '@jutro/components';
import { DropdownMenu, DropdownMenuLink } from '@jutro/router';
import { uniqueInnerId } from '@jutro/platform';
import { BreakpointTracker } from '@jutro/layout';
import { ComponentPropTypes } from '@jutro/prop-types';
import cx from 'classnames';
import { TableColumn } from '../table/TableColumn';
import { ActionItem } from './ActionItem';
import styles from "./ActionColumn.module.css";
import tableStyles from "../DataTable.module.css";
export var ActionColumn = function ActionColumn() {
  if (false) {
    throw new Error('Component <ActionColumn /> should never render');
  }
  return React.createElement(React.Fragment, null);
};
ActionColumn.propTypes = _objectSpread({}, TableColumn.propTypes, {
  singleActionType: PropTypes.oneOf(['icon', 'link']),
  children: ComponentPropTypes.childOfComponentType(ActionItem)
});
ActionColumn.getRowActions = function (_ref) {
  var row = _ref.row,
    rowId = _ref.rowId,
    props = _ref.props,
    onEditStart = _ref.onEditStart;
  var children = props.children,
    breakpoint = props.breakpoint;
  var actionsProps = getBreakpointProps(children, breakpoint);
  return _mapInstanceProperty(actionsProps).call(actionsProps, function (_ref2) {
    var onClick = _ref2.onClick,
      triggerOnRowClick = _ref2.triggerOnRowClick,
      isEditTrigger = _ref2.isEditTrigger;
    if (!triggerOnRowClick) {
      return undefined;
    }
    if (isEditTrigger) {
      return function () {
        return onEditStart(row, rowId);
      };
    }
    return function () {
      return onClick(row, rowId);
    };
  });
};
ActionColumn.defaultCell = function (row, rowId, props, onEditStart) {
  var children = props.children,
    visible = props.visible,
    disableActions = props.disableActions,
    breakpoint = props.breakpoint;
  if (visible === false || disableActions || !children) {
    return null;
  }
  var actionsProps = getBreakpointProps(children, breakpoint);
  var numberOfActions = actionsProps.length;
  if (numberOfActions === 1) {
    return renderOneAction(actionsProps[0], row, rowId, onEditStart);
  }
  if (numberOfActions > 2) {
    return renderActionMenu(actionsProps, row, rowId, onEditStart);
  }
  return _mapInstanceProperty(actionsProps).call(actionsProps, function (buttonProps, buttonIndex) {
    return renderActionButton(buttonProps, row, rowId, buttonIndex, onEditStart);
  });
};
export function renderOneAction(actionProps, row, rowId, onEditStart) {
  var icon = actionProps.icon,
    onClick = actionProps.onClick,
    className = actionProps.className,
    label = actionProps.label,
    isEditTrigger = actionProps.isEditTrigger,
    singleActionType = actionProps.singleActionType,
    isOpen = actionProps.isOpen,
    other = _objectWithoutProperties(actionProps, ["icon", "onClick", "className", "label", "isEditTrigger", "singleActionType", "isOpen"]);
  var classes = cx(styles.actionItem, _defineProperty({}, styles.focusedActionItem, isOpen), className);
  var handleOnClick = isEditTrigger ? onEditStart : onClick;
  var onClickHandler = function onClickHandler() {
    return handleOnClick(row, rowId);
  };
  if (singleActionType === 'icon' && icon) {
    return renderActionButton(actionProps, row, rowId, 0, onEditStart);
  }
  if (singleActionType === 'link') {
    return React.createElement(Link, _extends({
      className: classes,
      icon: icon,
      onClick: onClickHandler
    }, other), label);
  }
  return React.createElement(Button, _extends({
    className: classes,
    onClick: onClickHandler,
    size: "small",
    icon: icon
  }, other), label);
}
export function renderActionButton(_ref3, row, rowId) {
  var icon = _ref3.icon,
    onClick = _ref3.onClick,
    className = _ref3.className,
    isEditTrigger = _ref3.isEditTrigger,
    isOpen = _ref3.isOpen,
    label = _ref3.label,
    ariaLabel = _ref3.ariaLabel,
    disabled = _ref3.disabled;
  var key = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : rowId;
  var onEditStart = arguments.length > 4 ? arguments[4] : undefined;
  var classes = cx(styles.actionItem, styles.actionButton, _defineProperty({}, styles.focusedActionItem, isOpen), className);
  var handleOnClick = isEditTrigger ? onEditStart : onClick;
  var onClickHandler = function onClickHandler() {
    return handleOnClick(row, rowId);
  };
  return React.createElement(Button, {
    key: "".concat(key),
    className: classes,
    onClick: onClickHandler,
    size: "small",
    type: "text",
    icon: icon,
    disabled: disabled,
    "aria-label": ariaLabel || (label === null || label === void 0 ? void 0 : label.toLowerCase()) || icon,
    "aria-expanded": isOpen,
    tabIndex: "0"
  });
}
export function renderActionMenu(actionsProps, row, rowId, onEditStart) {
  var handleRenderTrigger = function handleRenderTrigger(props, toggleMenu) {
    var isOpen = props.isOpen,
      menuId = props.menuId,
      rest = _objectWithoutProperties(props, ["isOpen", "menuId"]);
    var onClick = function onClick() {
      return toggleMenu(!isOpen);
    };
    return renderActionButton(_objectSpread({}, rest, {
      icon: 'mi-more-horiz',
      onClick: onClick,
      isOpen: isOpen,
      ariaLabel: 'View more'
    }), row, rowId);
  };
  var _uniqueInnerId = uniqueInnerId("".concat(rowId), 'menuId'),
    menuId = _uniqueInnerId.menuId;
  var renderDropdownMenuItem = function renderDropdownMenuItem(props, linkIndex) {
    var label = props.label,
      icon = props.icon,
      onClick = props.onClick,
      isEditTrigger = props.isEditTrigger,
      singleActionType = props.singleActionType,
      id = props.id,
      rest = _objectWithoutProperties(props, ["label", "icon", "onClick", "isEditTrigger", "singleActionType", "id"]);
    var handleOnClick = isEditTrigger ? onEditStart : onClick;
    var iconComponent = icon && React.createElement(Icon, {
      icon: icon,
      isFixedWidth: true
    });
    return React.createElement(DropdownMenuLink, _extends({
      id: "".concat(menuId, "__").concat(id || rowId),
      key: "".concat(linkIndex),
      onClick: function onClick(e) {
        e === null || e === void 0 ? void 0 : e.stopPropagation();
        e === null || e === void 0 ? void 0 : e.preventDefault();
        handleOnClick(row, rowId);
      }
    }, rest), React.createElement(InlineLabel, {
      tag: "div",
      icon: iconComponent
    }, React.createElement("span", {
      className: styles.menuItemLabel
    }, label)));
  };
  return React.createElement(DropdownMenu, {
    id: menuId,
    onRenderTrigger: handleRenderTrigger,
    className: styles.dropdownMenu,
    menuClassName: styles.menu,
    alignRight: true
  }, _mapInstanceProperty(actionsProps).call(actionsProps, renderDropdownMenuItem));
}
ActionColumn.editCell = function (_ref4) {
  var editActions = _ref4.editActions;
  var save = editActions.save,
    cancel = editActions.cancel;
  var classes = cx(styles.actionButton, styles.editButton);
  var cancelClasses = cx(classes, styles.cancelButton);
  return React.createElement(React.Fragment, null, React.createElement(Button, {
    className: classes,
    onClick: save,
    size: "small",
    icon: "mi-check",
    tabIndex: "0"
  }), React.createElement(Button, {
    className: cancelClasses,
    onClick: cancel,
    size: "small",
    type: "text",
    icon: "mi-close",
    tabIndex: "0"
  }));
};
ActionColumn.displayName = 'ActionColumn';
ActionColumn.defaultProps = {
  renderCell: ActionColumn.defaultCell,
  renderEditCell: ActionColumn.editCell,
  getRowActions: ActionColumn.getRowActions,
  columnClassName: tableStyles.actionColumn,
  cellClassName: styles.actionColumnCell,
  width: 120,
  sortable: false,
  textAlign: 'right',
  onFilter: function onFilter() {
    return function () {
      return false;
    };
  }
};
function getBreakpointProps(children, breakpoint) {
  var _context, _context2;
  return _mapInstanceProperty(_context = _filterInstanceProperty(_context2 = Children.toArray(children)).call(_context2, Boolean)).call(_context, function (_ref5) {
    var props = _ref5.props;
    return BreakpointTracker.applyBreakpointOverrides(props, breakpoint);
  });
}
ActionColumn.__docgenInfo = {
  componentName: "ActionColumn",
  packageName: "@jutro/datatable",
  description: "ActionColumn",
  displayName: "ActionColumn",
  methods: [],
  actualName: "ActionColumn",
  metadataType: "container",
  props: {
    singleActionType: {
      type: {
        name: "enum",
        value: [{
          value: "'icon'",
          computed: false
        }, {
          value: "'link'",
          computed: false
        }]
      },
      required: false,
      description: "Specifies the type of action 'icon' or 'link', when one action is rendered\nBy default renders `Button` action"
    },
    children: {
      type: {
        name: "custom",
        raw: "ComponentPropTypes.childOfComponentType(ActionItem)"
      },
      required: false,
      description: "List of ActionItems"
    },
    renderCell: {
      defaultValue: {
        value: "ActionColumn.defaultCell",
        computed: true
      },
      required: false
    },
    renderEditCell: {
      defaultValue: {
        value: "ActionColumn.editCell",
        computed: true
      },
      required: false
    },
    getRowActions: {
      defaultValue: {
        value: "ActionColumn.getRowActions",
        computed: true
      },
      required: false
    },
    columnClassName: {
      defaultValue: {
        value: "tableStyles.actionColumn",
        computed: true
      },
      required: false
    },
    cellClassName: {
      defaultValue: {
        value: "styles.actionColumnCell",
        computed: true
      },
      required: false
    },
    width: {
      defaultValue: {
        value: "120",
        computed: false
      },
      required: false
    },
    sortable: {
      defaultValue: {
        value: "false",
        computed: false
      },
      required: false
    },
    textAlign: {
      defaultValue: {
        value: "'right'",
        computed: false
      },
      required: false
    },
    onFilter: {
      defaultValue: {
        value: "() => () => false",
        computed: false
      },
      required: false
    }
  },
  composes: ["../table/TableColumn"]
};
renderOneAction.__docgenInfo = {
  componentName: "renderOneAction",
  packageName: "@jutro/datatable",
  description: "Render the button with action defined on onclick listener",
  displayName: "renderOneAction",
  methods: [],
  actualName: "renderOneAction"
};
renderActionButton.__docgenInfo = {
  componentName: "renderActionButton",
  packageName: "@jutro/datatable",
  description: "Render the button with action defined on onclick listener",
  displayName: "renderActionButton",
  methods: [],
  actualName: "renderActionButton"
};
renderActionMenu.__docgenInfo = {
  componentName: "renderActionMenu",
  packageName: "@jutro/datatable",
  description: "Render ellipsis with dropdown menu of actions",
  displayName: "renderActionMenu",
  methods: [],
  actualName: "renderActionMenu"
};