import _defineProperty2 from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty2(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _Object$assign from "@babel/runtime-corejs3/core-js-stable/object/assign";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _everyInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/every";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _get from "@babel/runtime-corejs3/helpers/get";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();
  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
      result;
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }
    return _possibleConstructorReturn(this, result);
  };
}
function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;
  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}
import React, { useMemo } from 'react';
import memoize from 'memoize-one';
import PropTypes from 'prop-types';
import { availableValueObjectShape, dataTypeShape, DATA_TYPE_STRING, DATA_TYPE_OBJECT, intlMessageShape } from '@jutro/prop-types';
import { GenericSelectControl } from '../GenericSelectControl/GenericSelectControl';
import { FieldComponent } from '../FieldComponent/FieldComponent';
import { TagCollection } from '../../Tag/TagCollection';
var TypeaheadSingleValueShape = PropTypes.oneOfType([PropTypes.string.isRequired, availableValueObjectShape]);
export var TypeaheadMultiSelectField = function (_FieldComponent) {
  _inherits(TypeaheadMultiSelectField, _FieldComponent);
  var _super = _createSuper(TypeaheadMultiSelectField);
  function TypeaheadMultiSelectField() {
    var _this;
    _classCallCheck(this, TypeaheadMultiSelectField);
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    _this = _super.call.apply(_super, [this].concat(args));
    _defineProperty(_assertThisInitialized(_this), "allowNewValue", function (value) {
      var _this$props = _this.props,
        availableValues = _this$props.availableValues,
        allowNew = _this$props.allowNew;
      return allowNew && value && _everyInstanceProperty(availableValues).call(availableValues, function (entry) {
        return entry.name !== value && entry.code !== value;
      });
    });
    _defineProperty(_assertThisInitialized(_this), "handleOnChangeMulti", function (value) {
      var dataType = _this.props.dataType;
      _this.beforeValue = _this.props.value;
      var newVal = dataType === DATA_TYPE_OBJECT || value === null ? value : value === null || value === void 0 ? void 0 : _mapInstanceProperty(value).call(value, _this.getOptionValue);
      _this.notifyChange(newVal);
    });
    _defineProperty(_assertThisInitialized(_this), "handleOnChangeSingle", function (value, action) {
      var dataType = _this.props.dataType;
      _this.beforeValue = _this.props.value;
      if (action.action === 'clear') {
        _this.notifyChange(value);
        return;
      }
      var newVal = dataType === DATA_TYPE_OBJECT ? value : _this.getOptionValue(value);
      _this.notifyChange(newVal);
    });
    _defineProperty(_assertThisInitialized(_this), "getOptionLabel", function (option) {
      return option.name || option.label;
    });
    _defineProperty(_assertThisInitialized(_this), "getOptionValue", function (option) {
      return option.code || option.value;
    });
    _defineProperty(_assertThisInitialized(_this), "translateAvailableValues", memoize(function (availableValues) {
      return availableValues && _mapInstanceProperty(availableValues).call(availableValues, function (value) {
        return {
          name: _this.translator(value.name),
          code: value.code
        };
      });
    }));
    _defineProperty(_assertThisInitialized(_this), "transformValueProp", memoize(function (value, availableValues, dataType) {
      var singleSelect = _this.props.singleSelect;
      if (!value || !availableValues) {
        return [];
      }
      if (dataType === DATA_TYPE_OBJECT) {
        return value;
      }
      var valuesMap = _reduceInstanceProperty(availableValues).call(availableValues, function (map, item) {
        return _Object$assign(map, _defineProperty2({}, item.code, item));
      }, {});
      if (singleSelect) {
        return valuesMap[value] || {
          code: value,
          name: value
        };
      }
      return _mapInstanceProperty(value).call(value, function (code) {
        return valuesMap[code] || {
          code: code,
          name: code
        };
      });
    }));
    return _this;
  }
  _createClass(TypeaheadMultiSelectField, [{
    key: "render",
    value: function render() {
      return _get(_getPrototypeOf(TypeaheadMultiSelectField.prototype), "render", this).call(this);
    }
  }, {
    key: "renderControlReadOnly",
    value: function renderControlReadOnly(breakpointProps) {
      var id = breakpointProps.id,
        className = breakpointProps.className;
      return React.createElement("div", {
        id: "".concat(id, "-readonly"),
        "data-read-only": true,
        className: className
      }, this.renderSelect({
        readOnly: true
      }));
    }
  }, {
    key: "renderSelect",
    value: function renderSelect(props) {
      var _this$props2 = this.props,
        id = _this$props2.id,
        availableValuesProp = _this$props2.availableValues,
        className = _this$props2.className,
        dataType = _this$props2.dataType,
        readOnlySeparator = _this$props2.readOnlySeparator,
        valueProp = _this$props2.value,
        singleSelect = _this$props2.singleSelect,
        usePortal = _this$props2.usePortal,
        isInitiallyOpen = _this$props2.isInitiallyOpen;
      var availableValues = this.translateAvailableValues(availableValuesProp);
      var value = this.transformValueProp(valueProp, availableValues, dataType);
      var components = useMemo(function () {
        return singleSelect ? {} : {
          ValueContainer: function ValueContainer(valueContainerProps) {
            return React.createElement(TagCollection, _extends({
              id: id
            }, valueContainerProps));
          }
        };
      }, [singleSelect, id]);
      return React.createElement(GenericSelectControl, _extends({
        id: id,
        availableValues: availableValues,
        className: className,
        getOptionLabel: this.getOptionLabel,
        getOptionValue: this.getOptionValue,
        readOnlySeparator: readOnlySeparator,
        components: components,
        value: value,
        usePortal: usePortal
      }, props, {
        isInitiallyOpen: isInitiallyOpen
      }));
    }
  }, {
    key: "renderControl",
    value: function renderControl(breakpointProps) {
      var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        isValid = _ref.isValid,
        labelId = _ref.labelId;
      var disabled = breakpointProps.disabled,
        placeholder = breakpointProps.placeholder,
        createNewMessage = breakpointProps.createNewMessage,
        singleSelect = breakpointProps.singleSelect,
        stickyIndicator = breakpointProps.stickyIndicator,
        noOptionsMessage = breakpointProps.noOptionsMessage,
        usePortal = breakpointProps.usePortal,
        className = breakpointProps.className,
        required = breakpointProps.required;
      var selectProps = {
        createNewMessage: createNewMessage,
        disabled: disabled,
        isClearable: false,
        allowNewValue: this.allowNewValue,
        labelId: labelId,
        noOptionsMessage: noOptionsMessage,
        onBlur: this.handleBlur,
        onFocus: this.handleFocus,
        onValueChange: singleSelect ? this.handleOnChangeSingle : this.handleOnChangeMulti,
        placeholder: placeholder,
        multiSelect: !singleSelect,
        stickyIndicator: stickyIndicator,
        usePortal: usePortal,
        valid: isValid,
        className: className,
        required: required
      };
      return this.renderSelect(selectProps);
    }
  }, {
    key: "isEmpty",
    value: function isEmpty() {
      var _this$props3 = this.props,
        valueProp = _this$props3.value,
        availableValues = _this$props3.availableValues;
      var value = this.transformValueProp(valueProp, availableValues);
      return !value || value.length === 0;
    }
  }]);
  return TypeaheadMultiSelectField;
}(FieldComponent);
_defineProperty(TypeaheadMultiSelectField, "defaultProps", _objectSpread({}, FieldComponent.defaultProps, {
  dataType: DATA_TYPE_STRING,
  usePortal: false
}));
_defineProperty(TypeaheadMultiSelectField, "propTypes", _objectSpread({}, FieldComponent.propTypes, {
  availableValues: PropTypes.arrayOf(availableValueObjectShape),
  value: PropTypes.oneOfType([TypeaheadSingleValueShape, PropTypes.arrayOf(TypeaheadSingleValueShape)]),
  dataType: dataTypeShape,
  allowNew: PropTypes.bool,
  noOptionsMessage: intlMessageShape,
  createNewMessage: intlMessageShape,
  readOnlySeparator: PropTypes.string,
  singleSelect: PropTypes.bool,
  stickyIndicator: PropTypes.bool,
  usePortal: PropTypes.bool,
  isInitiallyOpen: PropTypes.bool
}));
_defineProperty(TypeaheadMultiSelectField, "contextType", FieldComponent.contextType);
TypeaheadMultiSelectField.__docgenInfo = {
  componentName: "TypeaheadMultiSelectField",
  packageName: "@jutro/components",
  description: "Renders a multi-select input where the user can type to match\nfrom any of the predefined values. As the user types, the text\nis matched against the available values to display choices.",
  displayName: "TypeaheadMultiSelectField",
  methods: [{
    name: "renderControlReadOnly",
    docblock: "Render readonly control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render readonly control for this component."
  }, {
    name: "allowNewValue",
    docblock: "allowNewValue\n@param {object} value - new value being entered\n@returns {boolean} true if new values are allowed and it doesn't match an existing one",
    modifiers: [],
    params: [{
      name: "value",
      description: "new value being entered",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: "true if new values are allowed and it doesn't match an existing one",
      type: {
        name: "boolean"
      }
    },
    description: "allowNewValue"
  }, {
    name: "renderSelect",
    docblock: null,
    modifiers: [],
    params: [{
      name: "props",
      optional: undefined,
      type: null
    }],
    returns: null
  }, {
    name: "renderControl",
    docblock: "Renders the control\n\n@param {object} breakpointProps - breakpoint-specific props\n@param {object} options\n@returns {React.ReactElement} - rendered component",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: false
    }, {
      name: "{ isValid, labelId }"
    }],
    returns: {
      description: "rendered component",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Renders the control"
  }, {
    name: "handleOnChangeMulti",
    docblock: "onChange callback passed to Select\n\n@param {object[]} value - the value of the control\n@param {string} value.name\n@param {string} value.code",
    modifiers: [],
    params: [{
      name: "value",
      description: "the value of the control",
      type: {
        name: "Array",
        elements: [{
          name: "object"
        }]
      },
      optional: false
    }],
    returns: null,
    description: "onChange callback passed to Select"
  }, {
    name: "handleOnChangeSingle",
    docblock: "onChange callback passed to Select\n\n@param {object[]} value - the value of the control\n@param {string} value.name\n@param {string} value.code\n@param {object} action - the action type (clear, blur, ect.)",
    modifiers: [],
    params: [{
      name: "value",
      description: "the value of the control",
      type: {
        name: "Array",
        elements: [{
          name: "object"
        }]
      },
      optional: false
    }, {
      name: "action",
      description: "the action type (clear, blur, ect.)",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: null,
    description: "onChange callback passed to Select"
  }, {
    name: "isEmpty",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "getOptionLabel",
    docblock: "Gets the option display text.\nNeeds to handle the internal option.label format of the Creatable component\nwhen new values are created.\n\n@param {object} option\n\n@returns {string} - the text to display",
    modifiers: [],
    params: [{
      name: "option",
      description: null,
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: "the text to display",
      type: {
        name: "string"
      }
    },
    description: "Gets the option display text.\nNeeds to handle the internal option.label format of the Creatable component\nwhen new values are created."
  }, {
    name: "getOptionValue",
    docblock: "Gets the option value\nNeeds to handle the internal option.value format of the Creatable component\nwhen new values are created.\n\n@param {object} option\n\n@returns {string} - the value that indicates user choice",
    modifiers: [],
    params: [{
      name: "option",
      description: null,
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: "the value that indicates user choice",
      type: {
        name: "string"
      }
    },
    description: "Gets the option value\nNeeds to handle the internal option.value format of the Creatable component\nwhen new values are created."
  }],
  actualName: "TypeaheadMultiSelectField",
  metadataType: "field",
  props: {
    availableValues: {
      type: {
        name: "arrayOf",
        value: {
          name: "union",
          value: [{
            name: "shape",
            value: {
              code: {
                name: "union",
                value: [{
                  name: "string"
                }, {
                  name: "number"
                }],
                required: false
              },
              name: {
                name: "custom",
                raw: "intlMessageShape",
                required: false
              },
              subtitle: {
                name: "custom",
                raw: "intlMessageShape",
                required: false
              },
              secondaryLabel: {
                name: "custom",
                raw: "intlMessageShape",
                required: false
              }
            }
          }, {
            name: "shape",
            value: {
              id: {
                name: "union",
                value: [{
                  name: "string"
                }, {
                  name: "number"
                }],
                required: false
              },
              displayName: {
                name: "custom",
                raw: "intlMessageShape",
                required: false
              },
              subtitle: {
                name: "custom",
                raw: "intlMessageShape",
                required: false
              },
              secondaryLabel: {
                name: "custom",
                raw: "intlMessageShape",
                required: false
              }
            }
          }]
        }
      },
      required: false,
      description: "List of values that the user can select from.\n\n`name` - the text to display, for example 'Married', 'Single'\n`code` - the value that is sent on submit."
    },
    value: {
      type: {
        name: "union",
        value: [{
          name: "custom",
          raw: "TypeaheadSingleValueShape"
        }, {
          name: "arrayOf",
          value: {
            name: "custom",
            raw: "TypeaheadSingleValueShape"
          }
        }]
      },
      required: false,
      description: "The list of codes that are selected. Each code is matched\nagainst the codes in `availableValues` to retrieve a `name` to display"
    },
    dataType: {
      type: {
        name: "enum",
        value: [{
          value: "'object'",
          computed: false
        }, {
          value: "'string'",
          computed: false
        }]
      },
      required: false,
      description: "The format of the items in the value array",
      defaultValue: {
        value: "'string'",
        computed: false
      }
    },
    allowNew: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Determines if creating new values is allowed"
    },
    noOptionsMessage: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "The message to display where there are no options that match\nthe text the user enters."
    },
    createNewMessage: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "The message to display when a new option is being created by the user.\ncreateNewMessage should be passed with the {message} placeholder for example: 'New option: {message}'."
    },
    readOnlySeparator: {
      type: {
        name: "string"
      },
      required: false,
      description: "Separator for the readonly value list"
    },
    singleSelect: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Should component only allow a single selection to be made"
    },
    stickyIndicator: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Determines if dropdown indicator will be sticky"
    },
    usePortal: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Default false; if set to true, it will target the body as the menu portal",
      defaultValue: {
        value: "false",
        computed: false
      }
    },
    isInitiallyOpen: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Should dropdown be initially opened"
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
};