import _defineProperty from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from "./AppFloorPlan.module.css";
var appFloorPlanLayoutPropTypes = {
  rightSide: PropTypes.node,
  className: PropTypes.string,
  footer: PropTypes.node,
  header: PropTypes.node,
  subHeader: PropTypes.node,
  leftSide: PropTypes.node,
  scrollContent: PropTypes.bool
};
var AppFloorPlanLayout = function AppFloorPlanLayout(props) {
  var rightSide = props.rightSide,
    children = props.children,
    className = props.className,
    footer = props.footer,
    header = props.header,
    subHeader = props.subHeader,
    leftSide = props.leftSide,
    scrollContent = props.scrollContent;
  var floorPlanClasses = cx(styles.appFloorPlan, _defineProperty({}, styles.scrollContent, scrollContent), className);
  return React.createElement("div", {
    className: floorPlanClasses
  }, header && React.createElement("header", {
    className: styles.header
  }, header), subHeader && React.createElement("nav", {
    className: styles.subHeader
  }, subHeader), leftSide && React.createElement("nav", {
    className: styles.leftSide
  }, leftSide), React.createElement("main", {
    className: styles.main,
    tabIndex: -1
  }, children), rightSide && React.createElement("aside", {
    className: styles.rightSide
  }, rightSide), footer && React.createElement("footer", {
    className: styles.footer
  }, footer));
};
export default AppFloorPlanLayout;
AppFloorPlanLayout.__docgenInfo = {
  componentName: "AppFloorPlanLayout",
  packageName: "@jutro/floorplan",
  description: "",
  displayName: "AppFloorPlanLayout",
  methods: [],
  actualName: "AppFloorPlanLayout"
};