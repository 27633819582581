import _defineProperty2 from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _Promise from "@babel/runtime-corejs3/core-js-stable/promise";
import _Date$now from "@babel/runtime-corejs3/core-js-stable/date/now";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();
  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
      result;
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }
    return _possibleConstructorReturn(this, result);
  };
}
function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;
  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { isPromise, ContextConsumer } from '@jutro/platform';
import { intlMessageShape } from '@jutro/prop-types';
import styles from "./Loader.module.css";
import { CubeLoader } from './CubeLoader';
export var Loader = function (_Component) {
  _inherits(Loader, _Component);
  var _super = _createSuper(Loader);
  _createClass(Loader, null, [{
    key: "isLoadingActive",
    value: function isLoadingActive(loaded) {
      if (typeof loaded === 'boolean' || loaded === undefined) {
        return !loaded;
      }
      return _.isArray(loaded) || isPromise(loaded);
    }
  }]);
  function Loader(props) {
    var _this;
    _classCallCheck(this, Loader);
    _this = _super.call(this, props);
    _defineProperty(_assertThisInitialized(_this), "stopLoader", function (loaderId) {
      if (_this.mounted && _this.state.loaderActiveId === loaderId) {
        _this.setState({
          loaderActive: false
        });
      }
    });
    _defineProperty(_assertThisInitialized(_this), "renderComponent", function (translator) {
      var _this$props = _this.props,
        children = _this$props.children,
        className = _this$props.className;
      var loaderActive = _this.state.loaderActive;
      var classes = cx(_defineProperty2({}, styles.loader, loaderActive), className);
      var content = loaderActive ? _this.renderLoaderUI(translator) : children;
      return React.createElement("div", {
        className: classes
      }, content);
    });
    _this.state = {
      loaderActive: Loader.isLoadingActive(props.loaded),
      loaderActiveId: _Date$now()
    };
    if (_this.state.loaderActive) {
      _this.setupStopLoader(_this.state.loaderActiveId, props.loaded);
    }
    return _this;
  }
  _createClass(Loader, [{
    key: "componentWillMount",
    value: function componentWillMount() {
      this.mounted = true;
    }
  }, {
    key: "componentWillReceiveProps",
    value: function componentWillReceiveProps(nextProps) {
      var loaded = this.props.loaded;
      if (loaded !== nextProps.loaded) {
        var loaderActiveId = _Date$now();
        var isActive = Loader.isLoadingActive(nextProps.loaded);
        this.setState({
          loaderActive: isActive,
          loaderActiveId: loaderActiveId
        });
        if (isActive) {
          this.setupStopLoader(loaderActiveId, nextProps.loaded);
        }
      }
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      this.mounted = false;
    }
  }, {
    key: "setupStopLoader",
    value: function setupStopLoader(loaderId, loaded) {
      var _this2 = this;
      if (isPromise(loaded) || _.isArray(loaded)) {
        var loaderPromise = _.isArray(loaded) ? _Promise.all(loaded) : loaded;
        loaderPromise.then(function () {
          return _this2.stopLoader(loaderId);
        }).catch(function () {
          return _this2.stopLoader(loaderId);
        });
      }
    }
  }, {
    key: "renderLoaderUI",
    value: function renderLoaderUI(translator) {
      var _this$props2 = this.props,
        text = _this$props2.text,
        renderLoader = _this$props2.renderLoader;
      var loaderText = text ? React.createElement("div", {
        className: styles.text
      }, translator(text)) : null;
      return React.createElement("span", {
        role: "alert",
        className: styles.loaderAlertWrapper
      }, renderLoader(), loaderText);
    }
  }, {
    key: "render",
    value: function render() {
      return React.createElement(ContextConsumer, {
        contexts: [TranslatorContext]
      }, this.renderComponent);
    }
  }]);
  return Loader;
}(Component);
_defineProperty(Loader, "propTypes", {
  className: PropTypes.string,
  children: PropTypes.node,
  loaded: PropTypes.oneOfType([PropTypes.bool, PropTypes.array, PropTypes.object]),
  text: PropTypes.oneOfType([PropTypes.node, intlMessageShape]),
  renderLoader: PropTypes.func
});
_defineProperty(Loader, "defaultProps", {
  renderLoader: function renderLoader(props) {
    return React.createElement(CubeLoader, props);
  }
});
Loader.__docgenInfo = {
  componentName: "Loader",
  packageName: "@jutro/components",
  description: "Renders an animated component that you can show when loading some other content.",
  displayName: "Loader",
  methods: [{
    name: "isLoadingActive",
    docblock: "Determines whether the `loaded` value indicates that the `Loader` should be active.  If the type of the value is\nboolean, then `loaded === false` means the loader is active otherwise it is NOT.  If the type of the value is\nan array or promise, then the loader is active, otherwise it is NOT.\n\n@param {boolean|Array<any>|object} loaded - The value of the loaded prop\n@returns {boolean} - True if `loaded` has a value indicating the loader is active, false otherwise",
    modifiers: ["static"],
    params: [{
      name: "loaded",
      description: "The value of the loaded prop",
      type: {
        name: "union",
        elements: [{
          name: "boolean"
        }, {
          name: "Array",
          elements: [{
            name: "any"
          }]
        }, {
          name: "object"
        }]
      },
      optional: false
    }],
    returns: {
      description: "True if `loaded` has a value indicating the loader is active, false otherwise",
      type: {
        name: "boolean"
      }
    },
    description: "Determines whether the `loaded` value indicates that the `Loader` should be active.  If the type of the value is\nboolean, then `loaded === false` means the loader is active otherwise it is NOT.  If the type of the value is\nan array or promise, then the loader is active, otherwise it is NOT."
  }, {
    name: "setupStopLoader",
    docblock: "Detects whether the given `loaded` prop is a promise (or an array of promises) and if so, sets up a callback\nto the `stopLoader(loaderId)` function when the promise or promises have completed.\n\n@param {number} loaderId - The unique identifier of the loader associated with the `loaded` prop\n@param {Promise | Promise[]} loaded - promise(s) to wait",
    modifiers: [],
    params: [{
      name: "loaderId",
      description: "The unique identifier of the loader associated with the `loaded` prop",
      type: {
        name: "number"
      },
      optional: false
    }, {
      name: "loaded",
      description: "promise(s) to wait",
      type: {
        name: "union",
        elements: [{
          name: "Promise"
        }, {
          name: "Array",
          elements: [{
            name: "Promise"
          }]
        }]
      },
      optional: false
    }],
    returns: null,
    description: "Detects whether the given `loaded` prop is a promise (or an array of promises) and if so, sets up a callback\nto the `stopLoader(loaderId)` function when the promise or promises have completed."
  }, {
    name: "stopLoader",
    docblock: "Callback used when the promise or promises provided in the `loaded` prop have completed.  If the component is\nstill mounted AND the current `loaderActiveId` matches the `loaderId` at the time the promise was created, then\nthe `loaderActive` state is set to `false`.  If the two ids do not match, then the loader was re-rendeded with a\ndifferent `loaded` value than the one associated with the callback, so there isn't a need to do anything.\n\n@param {number} loaderId - The unique ID of the `Loader` at the time the promise callback was created",
    modifiers: [],
    params: [{
      name: "loaderId",
      description: "The unique ID of the `Loader` at the time the promise callback was created",
      type: {
        name: "number"
      },
      optional: false
    }],
    returns: null,
    description: "Callback used when the promise or promises provided in the `loaded` prop have completed.  If the component is\nstill mounted AND the current `loaderActiveId` matches the `loaderId` at the time the promise was created, then\nthe `loaderActive` state is set to `false`.  If the two ids do not match, then the loader was re-rendeded with a\ndifferent `loaded` value than the one associated with the callback, so there isn't a need to do anything."
  }, {
    name: "renderLoaderUI",
    docblock: "Renders the Loader UI with any `text` information as provided by the `props`.\n\n@param {Function} translator translator function\n@returns {React.ReactElement} - The rendered Loader UI",
    modifiers: [],
    params: [{
      name: "translator",
      description: "translator function",
      type: {
        name: "Function"
      },
      optional: false
    }],
    returns: {
      description: "The rendered Loader UI",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Renders the Loader UI with any `text` information as provided by the `props`."
  }, {
    name: "renderComponent",
    docblock: "The `children` prop is rendered when the `loaderActive` state is false OR if the `gwLoaderTransparent`\nis true, otherwise it is not.  If the `loaderActive` state is true, then the Loader UI is rendered.\n@param {Function} translator translator function\n@returns {React.ReactElement} - The rendered Loader",
    modifiers: [],
    params: [{
      name: "translator",
      description: "translator function",
      type: {
        name: "Function"
      },
      optional: false
    }],
    returns: {
      description: "The rendered Loader",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "The `children` prop is rendered when the `loaderActive` state is false OR if the `gwLoaderTransparent`\nis true, otherwise it is not.  If the `loaderActive` state is true, then the Loader UI is rendered."
  }],
  actualName: "Loader",
  metadataType: "element",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "CSS class name for this component"
    },
    children: {
      type: {
        name: "node"
      },
      required: false,
      description: "The children elements to render inside of this component's body"
    },
    loaded: {
      type: {
        name: "union",
        value: [{
          name: "bool"
        }, {
          name: "array"
        }, {
          name: "object"
        }]
      },
      required: false,
      description: "Controls whether the Loader UI is shown vs the `children`;\n     For boolean values, if `false` is passed then the Loader UI is shown otherwise the `children` are; For\n     Promise value(s), the Loader UI will be shown until the promise has completed then the `children` are."
    },
    text: {
      type: {
        name: "union",
        value: [{
          name: "node"
        }, {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "shape",
            value: {
              id: {
                name: "string",
                required: false
              },
              defaultMessage: {
                name: "string",
                required: false
              },
              args: {
                name: "shape",
                value: {},
                required: false
              }
            }
          }]
        }]
      },
      required: false,
      description: "Optional message text to display when the Loader UI is shown"
    },
    renderLoader: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback to render loader",
      defaultValue: {
        value: "props => <CubeLoader {...props} />",
        computed: false
      }
    }
  }
};