import _defineProperty from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { FieldComponent, DateField } from '@jutro/components';
import { publish, JUTRO_TOPICS } from '@jutro/events';
import { Grid } from '@jutro/layout';
import { DATA_TYPE_OBJECT, dateTimeZoneValueShape, intlMessageShape, nestedTooltipShape } from '@jutro/prop-types';
import styles from "./DateTimeZoneField.module.css";
import { DateTimeField } from '../DateTimeField/DateTimeField';
import { TimeZoneField } from './TimeZoneField';
import { useTimeZoneDropdownValues } from './useTimeZoneDropdownValues';
import { parseDateTimeZoneFromDataType, formatDateTimeZoneToDataType } from './helpers';
var dateTimeZoneFieldPropTypes = _objectSpread({}, DateTimeField.propTypes, {
  value: dateTimeZoneValueShape,
  defaultValue: dateTimeZoneValueShape,
  showTimeZone: PropTypes.bool,
  timeZoneLabel: intlMessageShape,
  timeZoneSecondaryLabel: intlMessageShape,
  timeZoneTooltip: PropTypes.oneOfType([PropTypes.string, nestedTooltipShape]),
  timeZoneHideLabel: PropTypes.bool,
  timeZones: PropTypes.arrayOf(PropTypes.string.isRequired),
  defaultTimeZone: PropTypes.string
});
export var DateTimeZoneField = function DateTimeZoneField(props) {
  var className = props.className,
    isClearable = props.isClearable,
    showTime = props.showTime,
    tooltipTime = props.tooltipTime,
    placeholderTime = props.placeholderTime,
    labelTime = props.labelTime,
    hideLabelTime = props.hideLabelTime,
    secondaryLabelTime = props.secondaryLabelTime,
    showTimeZone = props.showTimeZone,
    timeZoneLabel = props.timeZoneLabel,
    timeZoneSecondaryLabel = props.timeZoneSecondaryLabel,
    timeZoneTooltip = props.timeZoneTooltip,
    timeZoneHideLabel = props.timeZoneHideLabel,
    timeZones = props.timeZones,
    defaultTimeZone = props.defaultTimeZone,
    id = props.id,
    _props$dataType = props.dataType,
    dataType = _props$dataType === void 0 ? DATA_TYPE_OBJECT : _props$dataType,
    _props$value = props.value,
    valueProp = _props$value === void 0 ? {} : _props$value,
    _props$defaultValue = props.defaultValue,
    defaultValue = _props$defaultValue === void 0 ? {} : _props$defaultValue,
    path = props.path,
    model = props.model,
    label = props.label,
    hideLabel = props.hideLabel,
    dataPath = props.dataPath,
    onValueChange = props.onValueChange,
    commonFieldProps = _objectWithoutProperties(props, ["className", "isClearable", "showTime", "tooltipTime", "placeholderTime", "labelTime", "hideLabelTime", "secondaryLabelTime", "showTimeZone", "timeZoneLabel", "timeZoneSecondaryLabel", "timeZoneTooltip", "timeZoneHideLabel", "timeZones", "defaultTimeZone", "id", "dataType", "value", "defaultValue", "path", "model", "label", "hideLabel", "dataPath", "onValueChange"]);
  var allLabelsHidden = (!!hideLabel || !label) && (timeZoneHideLabel || !timeZoneLabel) && (hideLabelTime || !labelTime);
  var _useTimeZoneDropdownV = useTimeZoneDropdownValues(timeZones),
    _useTimeZoneDropdownV2 = _slicedToArray(_useTimeZoneDropdownV, 2),
    currentTimeZone = _useTimeZoneDropdownV2[0],
    timeZoneValues = _useTimeZoneDropdownV2[1];
  var parsedValue = parseDateTimeZoneFromDataType(valueProp, defaultTimeZone || currentTimeZone, showTime);
  var handleChange = function handleChange(value) {
    var newValue = _objectSpread({}, parsedValue, {}, value);
    var formattedValue = formatDateTimeZoneToDataType(newValue, dataType, showTime);
    if (!onValueChange) {
      return;
    }
    onValueChange(formattedValue, model || path, {
      id: id,
      valueProp: valueProp,
      dataPath: dataPath
    });
    publish(JUTRO_TOPICS.VALUE_CHANGED, FieldComponent.fieldEventFormatter(_objectSpread({}, props, {
      value: formattedValue
    })));
  };
  var renderDateTime = function renderDateTime() {
    var handleDateTimeChange = function handleDateTimeChange(datetime) {
      return handleChange(_objectSpread({}, parsedValue, {
        datetime: datetime
      }));
    };
    var dateTimeHideLabel = allLabelsHidden;
    var labelClassName = cx(_defineProperty({}, styles.hideVisibility, !allLabelsHidden && hideLabel));
    return React.createElement(DateTimeField, _extends({}, commonFieldProps, {
      id: "".concat(id, "-datetime"),
      isClearable: isClearable,
      label: label,
      hideLabel: dateTimeHideLabel,
      labelClassName: labelClassName,
      showTime: showTime,
      dataType: DATA_TYPE_OBJECT,
      onValueChange: handleDateTimeChange,
      value: parsedValue.datetime,
      defaultValue: defaultValue.datetime,
      tooltipTime: tooltipTime,
      placeholderTime: placeholderTime,
      labelTime: labelTime,
      hideLabelTime: hideLabelTime,
      secondaryLabelTime: secondaryLabelTime
    }));
  };
  var renderTimeZone = function renderTimeZone() {
    var handleTimeZoneChange = function handleTimeZoneChange(timezone) {
      return handleChange(_objectSpread({}, parsedValue, {
        timezone: timezone
      }));
    };
    var timeZoneOverrideHideLabel = allLabelsHidden;
    var labelClassName = cx(_defineProperty({}, styles.hideVisibility, !allLabelsHidden && timeZoneHideLabel));
    return React.createElement(TimeZoneField, _extends({}, commonFieldProps, {
      id: "".concat(id, "-timezone"),
      className: styles.timeZoneField,
      labelClassName: labelClassName,
      availableValues: timeZoneValues,
      value: parsedValue.timezone,
      defaultValue: defaultValue.timezone,
      hideLabel: timeZoneOverrideHideLabel,
      label: timeZoneLabel,
      secondaryLabel: timeZoneSecondaryLabel,
      tooltip: timeZoneTooltip,
      onValueChange: handleTimeZoneChange
    }));
  };
  return React.createElement(Grid, {
    columns: commonFieldProps.labelPosition === 'top' ? ['2fr', 'auto'] : ['1fr'],
    rows: ['1fr'],
    phone: {
      columns: ['1fr']
    },
    className: className
  }, renderDateTime(), showTimeZone && renderTimeZone());
};
DateTimeZoneField.displayName = 'DateTimeZoneField';
DateTimeZoneField.propTypes = dateTimeZoneFieldPropTypes;
DateTimeZoneField.defaultProps = _objectSpread({}, DateField.defaultProps, {
  dataType: DATA_TYPE_OBJECT,
  showTime: true,
  showTimeZone: true
});
DateTimeZoneField.__docgenInfo = {
  componentName: "DateTimeZoneField",
  packageName: "@jutro/lab-preview-date",
  description: "",
  displayName: "DateTimeZoneField",
  methods: [],
  actualName: "DateTimeZoneField",
  metadataType: "field",
  props: {
    value: {
      type: {
        name: "custom",
        raw: "dateTimeZoneValueShape"
      },
      required: false,
      description: "Value to display"
    },
    defaultValue: {
      type: {
        name: "custom",
        raw: "dateTimeZoneValueShape"
      },
      required: false,
      description: "Set the default field value on render if there is a default value; needs onValueChange to work"
    },
    showTimeZone: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Show timezone picker",
      defaultValue: {
        value: "true",
        computed: false
      }
    },
    timeZoneLabel: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Intl message for time zone field label"
    },
    timeZoneSecondaryLabel: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Intl message for time zone field secondary label (same as secondaryLabel prop by default)"
    },
    timeZoneTooltip: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "custom",
          raw: "nestedTooltipShape"
        }]
      },
      required: false,
      description: "Tooltip for time zone field (same as tooltip prop by default)"
    },
    timeZoneHideLabel: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Hide time zone label (same as hideLabel by default)"
    },
    timeZones: {
      type: {
        name: "arrayOf",
        value: {
          name: "string"
        }
      },
      required: false,
      description: "Time zones to show in the dropdown"
    },
    defaultTimeZone: {
      type: {
        name: "string"
      },
      required: false,
      description: "Default time zone to be used (local by default)"
    },
    dataType: {
      defaultValue: {
        value: "DATA_TYPE_OBJECT",
        computed: true
      },
      required: false
    },
    showTime: {
      defaultValue: {
        value: "true",
        computed: false
      },
      required: false
    }
  },
  composes: ["../DateTimeField/DateTimeField"]
};