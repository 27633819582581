import _defineProperty from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { intlMessageShape } from '@jutro/prop-types';
import { uniqueInnerId } from '@jutro/platform';
import styles from "./Panel.module.css";
import { PanelHeader, alignments } from './PanelHeader';
import { PanelLayout } from '../../layouts/PanelLayout';
var panelPropTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  alignment: PropTypes.oneOf(alignments),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  title: intlMessageShape,
  subTitle: intlMessageShape,
  renderHeader: PropTypes.func,
  headerClass: PropTypes.string,
  fluid: PropTypes.bool
};
export var Panel = function Panel(_ref) {
  var id = _ref.id,
    className = _ref.className,
    alignment = _ref.alignment,
    title = _ref.title,
    subTitle = _ref.subTitle,
    renderHeader = _ref.renderHeader,
    headerClass = _ref.headerClass,
    fluid = _ref.fluid,
    children = _ref.children;
  var panelClass = cx(styles.panel, _defineProperty({}, styles.fluid, fluid), className);
  var _uniqueInnerId = uniqueInnerId(id, 'panelHeaderId'),
    panelHeaderId = _uniqueInnerId.panelHeaderId;
  var headerProps = {
    id: panelHeaderId,
    alignment: alignment,
    title: title,
    subTitle: subTitle,
    className: headerClass
  };
  var Layout = fluid ? 'div' : PanelLayout;
  return React.createElement(Layout, {
    id: id,
    className: panelClass
  }, renderHeader ? renderHeader(headerProps) : React.createElement(PanelHeader, headerProps), children);
};
Panel.propTypes = panelPropTypes;
Panel.__docgenInfo = {
  componentName: "Panel",
  packageName: "@jutro/components",
  description: "A container for child components. You can set a title and subtitle through the props.\n\nThis component is a typical wrapper for a form, but can have any content.",
  displayName: "Panel",
  methods: [],
  actualName: "Panel",
  metadataType: "container",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: true,
      description: "Used to identify panel component."
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional styles to be applied to Panel"
    },
    alignment: {
      type: {
        name: "enum",
        computed: false,
        value: [{
          value: "'center'",
          computed: false
        }, {
          value: "'left'",
          computed: false
        }, {
          value: "'right'",
          computed: false
        }]
      },
      required: false,
      description: "Alignment of the panel header"
    },
    children: {
      type: {
        name: "union",
        value: [{
          name: "arrayOf",
          value: {
            name: "node"
          }
        }, {
          name: "node"
        }]
      },
      required: false,
      description: "Body of Panel"
    },
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Title of Panel"
    },
    subTitle: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Subtitle of Panel"
    },
    renderHeader: {
      type: {
        name: "func"
      },
      required: false,
      description: "Function rendering panel header element instead of the default one"
    },
    headerClass: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional styles to be applied to Panel header"
    },
    fluid: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Content spans the entire width."
    }
  }
};