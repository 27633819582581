import _defineProperty from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import React, { useState, useEffect, useContext } from 'react';
import cx from 'classnames';
import range from "lodash/range";
import { getFormattedMonthsForLocale, TranslatorContext } from '@jutro/locale';
import { addMonths, subMonths, setMonth, setYear } from 'date-fns';
import { DropdownSelectField } from '../DropdownSelectField/DropdownSelectField';
import { Icon } from '../../Icon/Icon';
import datePickerStyles from "./DatePickerField.module.css";
import { messages } from './DateField.messages';
export var CustomHeader = function CustomHeader(_ref) {
  var date = _ref.date,
    changeYear = _ref.changeYear,
    changeMonth = _ref.changeMonth,
    decreaseMonth = _ref.decreaseMonth,
    increaseMonth = _ref.increaseMonth,
    locale = _ref.locale,
    prevMonthButtonDisabled = _ref.prevMonthButtonDisabled,
    nextMonthButtonDisabled = _ref.nextMonthButtonDisabled,
    minYear = _ref.minYear,
    maxYear = _ref.maxYear;
  var _useState = useState(date),
    _useState2 = _slicedToArray(_useState, 2),
    displayedMonth = _useState2[0],
    setDisplayedMonth = _useState2[1];
  var translator = useContext(TranslatorContext);
  useEffect(function () {
    setDisplayedMonth(date);
  }, [date]);
  var listOfMonths = getFormattedMonthsForLocale(locale);
  var setNextMonth = function setNextMonth() {
    return setDisplayedMonth(addMonths(displayedMonth, 1));
  };
  var setPreviousMonth = function setPreviousMonth() {
    return setDisplayedMonth(subMonths(displayedMonth, 1));
  };
  var onMonthChange = function onMonthChange(value) {
    setDisplayedMonth(setMonth(displayedMonth, value));
    changeMonth(value);
  };
  var onYearChange = function onYearChange(value) {
    setDisplayedMonth(setYear(displayedMonth, value));
    changeYear(value);
  };
  var onNextMonthClicked = function onNextMonthClicked() {
    increaseMonth();
    setNextMonth();
  };
  var onPreviousMonthClicked = function onPreviousMonthClicked() {
    decreaseMonth();
    setPreviousMonth();
  };
  var navigationIconPrevClassName = cx(datePickerStyles.navigationPrevious, _defineProperty({}, datePickerStyles.navigationPreviousDisabled, prevMonthButtonDisabled));
  var navigationIconNextClassName = cx(datePickerStyles.navigationNext, _defineProperty({}, datePickerStyles.navigationNextDisabled, nextMonthButtonDisabled));
  var createMonthOptionsList = function createMonthOptionsList(listOfValues) {
    return _mapInstanceProperty(listOfValues).call(listOfValues, function (item, index) {
      return {
        name: item,
        code: index
      };
    });
  };
  var createYearOptionsList = function createYearOptionsList(listOfValues) {
    return _mapInstanceProperty(listOfValues).call(listOfValues, function (item) {
      return {
        name: item,
        code: item
      };
    });
  };
  var getAvailableYearValues = function getAvailableYearValues() {
    return createYearOptionsList(range(minYear, maxYear + 1));
  };
  var dropdownClassNames = {
    control: datePickerStyles.control,
    controlFocused: datePickerStyles.controlFocused,
    menuList: datePickerStyles.menu,
    option: datePickerStyles.option,
    selectIcon: datePickerStyles.selectIcon
  };
  return React.createElement("div", {
    className: datePickerStyles.dateHeader
  }, React.createElement(Icon, {
    icon: "mi-chevron-left",
    className: navigationIconPrevClassName,
    tag: "button",
    onClick: onPreviousMonthClicked,
    disabled: prevMonthButtonDisabled,
    "aria-label": translator(messages.previous)
  }), React.createElement(DropdownSelectField, {
    className: datePickerStyles.monthDropdownSelect,
    id: "month-dropdown",
    value: displayedMonth.getMonth(),
    availableValues: createMonthOptionsList(listOfMonths),
    internalClassNames: dropdownClassNames,
    showOptional: false,
    onValueChange: onMonthChange,
    usePortal: false
  }), React.createElement(DropdownSelectField, {
    className: datePickerStyles.yearDropdownSelect,
    id: "year-dropdown",
    value: displayedMonth.getFullYear(),
    availableValues: getAvailableYearValues(),
    internalClassNames: dropdownClassNames,
    showOptional: false,
    onValueChange: onYearChange,
    usePortal: false,
    searchable: true
  }), React.createElement(Icon, {
    icon: "mi-chevron-right",
    className: navigationIconNextClassName,
    tag: "button",
    onClick: onNextMonthClicked,
    disabled: nextMonthButtonDisabled,
    "aria-label": translator(messages.next)
  }));
};
CustomHeader.__docgenInfo = {
  componentName: "CustomHeader",
  packageName: "@jutro/components",
  description: "Custom header component for the DatePicker.",
  displayName: "CustomHeader",
  methods: [],
  actualName: "CustomHeader"
};