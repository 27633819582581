import _toConsumableArray from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/toConsumableArray";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import React from 'react';
import cx from 'classnames';
export var withClassName = function withClassName(Component) {
  for (var _len = arguments.length, classNames = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    classNames[_key - 1] = arguments[_key];
  }
  return function (props) {
    return React.createElement(Component, _extends({}, props, {
      className: cx.apply(void 0, _toConsumableArray(_mapInstanceProperty(classNames).call(classNames, function (className) {
        return props.selectProps.genericSelectStyles[className];
      })).concat([props.className]))
    }));
  };
};
export var getMenuListId = function getMenuListId(controlId) {
  return "".concat(controlId, "--menu-list");
};