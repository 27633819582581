import _defineProperty2 from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();
  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
      result;
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }
    return _possibleConstructorReturn(this, result);
  };
}
function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;
  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { uniqueInnerId } from '@jutro/platform';
import { ComponentPropTypes } from '@jutro/prop-types';
import { Tab } from './Tab';
import styles from "./TabSet.module.css";
import { TabBar } from './TabBar';
import { TabSetContext } from './TabSetContext';
export var TabSet = function (_Component) {
  _inherits(TabSet, _Component);
  var _super = _createSuper(TabSet);
  function TabSet() {
    var _this;
    _classCallCheck(this, TabSet);
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    _this = _super.call.apply(_super, [this].concat(args));
    _defineProperty(_assertThisInitialized(_this), "state", {
      activeTab: _this.props.activeTab || _this.props.defaultActiveTab
    });
    _defineProperty(_assertThisInitialized(_this), "onTabClick", function (evt) {
      var _currentTarget$datase;
      var currentTarget = evt.currentTarget;
      var activeTab = currentTarget === null || currentTarget === void 0 ? void 0 : (_currentTarget$datase = currentTarget.dataset) === null || _currentTarget$datase === void 0 ? void 0 : _currentTarget$datase.tab;
      evt.preventDefault();
      var onTabChange = _this.props.onTabChange;
      if (onTabChange) {
        onTabChange(activeTab);
      } else {
        _this.setState({
          activeTab: activeTab
        });
      }
    });
    _defineProperty(_assertThisInitialized(_this), "renderTabSet", function () {
      var _this$props = _this.props,
        id = _this$props.id,
        label = _this$props.label;
      var _uniqueInnerId = uniqueInnerId(id, 'contentId'),
        contentId = _uniqueInnerId.contentId;
      var _uniqueInnerId2 = uniqueInnerId(id, 'barId'),
        barId = _uniqueInnerId2.barId;
      var _this$props2 = _this.props,
        children = _this$props2.children,
        showFrame = _this$props2.showFrame,
        className = _this$props2.className;
      return React.createElement("div", {
        className: cx(styles.tabSet, _defineProperty2({}, styles.frame, showFrame), className)
      }, React.createElement(TabSetContext.Provider, {
        value: _this.state.activeTab
      }, React.createElement(TabBar, {
        id: barId,
        onHeadingClick: _this.onTabClick,
        tabs: _this.extractTabsMetadata(),
        ariaControls: contentId,
        label: label
      }), React.createElement("hr", {
        className: styles.divider
      }), React.createElement("div", {
        id: contentId,
        className: cx(styles.content),
        role: "region"
      }, children)));
    });
    return _this;
  }
  _createClass(TabSet, [{
    key: "componentWillReceiveProps",
    value: function componentWillReceiveProps(nextProps) {
      var _this$props3 = this.props,
        activeTab = _this$props3.activeTab,
        defaultActiveTab = _this$props3.defaultActiveTab;
      if (activeTab !== nextProps.activeTab) {
        this.setState({
          activeTab: nextProps.activeTab
        });
      }
      if (activeTab === undefined && nextProps.activeTab === undefined && defaultActiveTab !== nextProps.defaultActiveTab) {
        this.setState({
          activeTab: nextProps.defaultActiveTab
        });
      }
    }
  }, {
    key: "extractTabsMetadata",
    value: function extractTabsMetadata() {
      var _this2 = this;
      var _context;
      return _mapInstanceProperty(_context = React.Children.toArray(childrenIgnoringFragment(this.props))).call(_context, function (child) {
        var _child$props = child.props,
          id = _child$props.id,
          heading = _child$props.heading,
          disabled = _child$props.disabled,
          visible = _child$props.visible,
          className = _child$props.className;
        return {
          id: id,
          heading: heading,
          disabled: disabled,
          visible: visible,
          className: className,
          active: _this2.state.activeTab === id
        };
      }) || [];
    }
  }, {
    key: "render",
    value: function render() {
      return this.renderTabSet();
    }
  }]);
  return TabSet;
}(Component);
_defineProperty(TabSet, "propTypes", {
  id: PropTypes.string.isRequired,
  activeTab: PropTypes.string,
  onTabChange: PropTypes.func,
  className: PropTypes.string,
  children: ComponentPropTypes.childOfComponentType(Tab),
  showFrame: PropTypes.bool,
  defaultActiveTab: PropTypes.string,
  label: PropTypes.string
});
_defineProperty(TabSet, "defaultProps", {
  showFrame: false
});
function childrenIgnoringFragment(_ref) {
  var children = _ref.children;
  if (typeof children === 'object' && children.type === React.Fragment) {
    return children.props.children;
  }
  return children;
}
TabSet.__docgenInfo = {
  componentName: "TabSet",
  packageName: "@jutro/components",
  description: "Renders a container for a set of `Tab` components.  Extracts the `heading`\nprop from each of its `Tab` children and uses them to render the tab navigation bar.",
  displayName: "TabSet",
  methods: [{
    name: "onTabClick",
    docblock: "Click handler to manage the selection of the new active tab.  It either updates the `activeTab` in the\nstate or will call out to the `onTabChange()` handler if it is provided.\n\n@param {string} evt - the event from the click",
    modifiers: [],
    params: [{
      name: "evt",
      description: "the event from the click",
      type: {
        name: "string"
      },
      optional: false
    }],
    returns: null,
    description: "Click handler to manage the selection of the new active tab.  It either updates the `activeTab` in the\nstate or will call out to the `onTabChange()` handler if it is provided."
  }, {
    name: "extractTabsMetadata",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "renderTabSet",
    docblock: "Renders this TabSet component, extracting the TabSet's navigation links from the contained children Tab components\n\n@returns {React.ReactElement} - the rendered TabSet",
    modifiers: [],
    params: [],
    returns: {
      description: "the rendered TabSet",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Renders this TabSet component, extracting the TabSet's navigation links from the contained children Tab components"
  }],
  actualName: "TabSet",
  metadataType: "container",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: true,
      description: "Component unique identifier"
    },
    activeTab: {
      type: {
        name: "string"
      },
      required: false,
      description: "The 'tabId' of the active tab; used in conjunction with `onTabChange`"
    },
    onTabChange: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback to call when a tab is changed; if provided, `activeTab` must also be provided"
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Optional class name to apply to this component"
    },
    children: {
      type: {
        name: "custom",
        raw: "ComponentPropTypes.childOfComponentType(Tab)"
      },
      required: false,
      description: "A set of `<Tab>` children for this component"
    },
    showFrame: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If `true`, displays a frame surrounding `TabSet`",
      defaultValue: {
        value: "false",
        computed: false
      }
    },
    defaultActiveTab: {
      type: {
        name: "string"
      },
      required: false,
      description: "Which tab is active by default"
    },
    label: {
      type: {
        name: "string"
      },
      required: false,
      description: "Optional label describing the purpose of the tablist"
    }
  }
};