import _defineProperty from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import { JUTRO_TOPICS } from '@jutro/events';
export var HttpEventTypes;
(function (HttpEventTypes) {
  HttpEventTypes["request"] = "request";
  HttpEventTypes["response"] = "response";
  HttpEventTypes["error"] = "error";
  HttpEventTypes["exception"] = "exception";
})(HttpEventTypes || (HttpEventTypes = {}));
export var eventTopicMap = _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, HttpEventTypes.request, JUTRO_TOPICS.FETCH_REQUEST), HttpEventTypes.response, JUTRO_TOPICS.FETCH_RESPONSE), HttpEventTypes.error, JUTRO_TOPICS.FETCH_ERROR), HttpEventTypes.exception, JUTRO_TOPICS.FETCH_ERROR);
export var JSON_CONTENT_TYPE = 'application/json';
export var BLOB_CONTENT_TYPE = 'application/octet-stream';
export var CONTENT_TYPE = 'Content-Type';