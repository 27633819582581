import _objectWithoutProperties from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectWithoutProperties";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from "./SideBody.module.css";
export var SideBody = function SideBody(_ref) {
  var className = _ref.className,
    children = _ref.children,
    rest = _objectWithoutProperties(_ref, ["className", "children"]);
  var classes = cx(styles.sideBody, className);
  return React.createElement("section", _extends({
    className: classes,
    tabIndex: "0",
    role: "tabpanel"
  }, rest), children);
};
SideBody.propTypes = {
  className: PropTypes.string
};
SideBody.__docgenInfo = {
  componentName: "SideBody",
  packageName: "@jutro/router",
  description: "The `SideBody` component is designed as a container for Icon tabs",
  displayName: "SideBody",
  methods: [],
  actualName: "SideBody",
  metadataType: "container",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional class names for component."
    }
  }
};