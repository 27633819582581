import _defineProperty from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _slicedToArray from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/slicedToArray";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _forEachInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/for-each";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Object$entries from "@babel/runtime-corejs3/core-js-stable/object/entries";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
var _context;
import React from 'react';
import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";
import { warning, makeUniqueWarningLog } from '@jutro/logger';
import { Accordion, AccordionCard, AddressDisplay, Avatar, Badge, Breadcrumb, Button, ToggleField, Card, CheckboxField, CheckboxGroupField, ClickableCard, IconButton, ColorPicker, ColorSwatch, CurrencyField, CurrencyValue, DateField, DateValue, ImageRadioButtonField, Image, InfoLabel, InlineNotification, InputField, InputMaskField, InputNumberField, FileUploadField, FieldValue, FormattedCurrency, FormattedDate, FormattedDateRange, FormattedNumber, GlobalizationChooser, Link, Loader, LocalizedDateRange, LookupField, MapArea, Money, NumberValue, PhoneNumberField, RadioButtonCardField, RadioButtonField, DropdownSelectField, StepperField, SwitchField, Slider, TabSet, Tab, TextAreaField, IntlElement, TypeaheadMultiSelectField, YearField, Panel, Header, HeaderActions, LogoTitle, Footer, FooterText, FooterNavBar, FooterCopyright, FooterNavLink, StickyFooter, InlineLoader, NotificationAction, IFrame, TagCollection, Tag, FieldIcon, Icon, Chevron, HelpHeading, HelpParagraph, HelpLink, SimpleProgressBar, PhaseProgressBar, StepProgressBar, ThemeChooser } from '@jutro/components';
import { Grid, GridItem, GridLayout, ColumnsLayout, Flex, FlexItem } from '@jutro/layout';
import { AsyncLink, AsyncButtonLink, ButtonLink, DropdownMenu, DropdownMenuAvatar, DropdownMenuButton, DropdownMenuHeader, DropdownMenuLink, DropdownMenuSeparator, TopNavigation, SideNavigation } from '@jutro/router';
import { DateTimeField, DateRangeField, DateTimeZoneField } from '@jutro/lab-preview-date';
import { IntlPhoneNumberField, FormattedPhoneNumber, PhoneNumberValue } from '@jutro/lab-preview-intl-phone-number';
import { TitleElement, ActionTitleBar, EditCancelSaveTitleBar } from '@jutro/lab-preview-title-bars';
export var simpleDatatypeComponentMap = {
  boolean: {
    component: ToggleField
  },
  checkbox: {
    component: CheckboxField
  },
  currency: {
    component: CurrencyField
  },
  number: {
    component: InputNumberField
  },
  integer: {
    component: InputNumberField
  },
  radio: {
    component: RadioButtonField
  },
  select: {
    component: DropdownSelectField
  },
  string: {
    component: InputField
  },
  tel: {
    component: PhoneNumberField
  },
  text: {
    component: InputField
  },
  textarea: {
    component: TextAreaField
  },
  typelist: {
    component: DropdownSelectField
  },
  date: {
    component: DateField
  },
  year: {
    component: YearField
  },
  yesno: {
    component: ToggleField
  }
};
export var simpleNamedComponentMap = {
  Accordion: {
    component: Accordion
  },
  AccordionCard: {
    component: AccordionCard
  },
  AddressDisplay: {
    component: AddressDisplay
  },
  AsyncLink: {
    component: AsyncLink
  },
  AsyncButtonLink: {
    component: AsyncButtonLink
  },
  Avatar: {
    component: Avatar
  },
  Badge: {
    component: Badge
  },
  Breadcrumb: {
    component: Breadcrumb
  },
  Button: {
    component: Button
  },
  ButtonLink: {
    component: ButtonLink
  },
  IconButton: {
    component: IconButton
  },
  Toggle: {
    component: ToggleField
  },
  Card: {
    component: Card
  },
  ClickableCard: {
    component: ClickableCard
  },
  Checkbox: {
    component: CheckboxField
  },
  CheckboxGroup: {
    component: CheckboxGroupField
  },
  Chevron: {
    component: Chevron
  },
  ColorPicker: {
    component: ColorPicker
  },
  ColorSwatch: {
    component: ColorSwatch
  },
  Currency: {
    component: CurrencyField
  },
  CurrencyValue: {
    component: CurrencyValue
  },
  DateValue: {
    component: DateValue
  },
  DropdownMenu: {
    component: DropdownMenu
  },
  DropdownMenuAvatar: {
    component: DropdownMenuAvatar
  },
  DropdownMenuButton: {
    component: DropdownMenuButton
  },
  DropdownMenuHeader: {
    component: DropdownMenuHeader
  },
  DropdownMenuLink: {
    component: DropdownMenuLink
  },
  DropdownMenuSeparator: {
    component: DropdownMenuSeparator
  },
  FieldIcon: {
    component: FieldIcon
  },
  FieldValue: {
    component: FieldValue
  },
  Flex: {
    component: Flex
  },
  FlexItem: {
    component: FlexItem
  },
  Footer: {
    component: Footer
  },
  FooterText: {
    component: FooterText
  },
  FooterNavBar: {
    component: FooterNavBar
  },
  FooterCopyright: {
    component: FooterCopyright
  },
  FooterNavLink: {
    component: FooterNavLink
  },
  FormattedCurrency: {
    component: FormattedCurrency
  },
  FormattedDate: {
    component: FormattedDate
  },
  FormattedDateRange: {
    component: FormattedDateRange
  },
  FormattedNumber: {
    component: FormattedNumber
  },
  Fragment: {
    component: React.Fragment
  },
  Grid: {
    component: Grid
  },
  GridItem: {
    component: GridItem
  },
  GridLayout: {
    component: GridLayout
  },
  ColumnsLayout: {
    component: ColumnsLayout
  },
  Icon: {
    component: Icon
  },
  Image: {
    component: Image
  },
  ImageRadioButton: {
    component: ImageRadioButtonField
  },
  InfoLabel: {
    component: InfoLabel
  },
  InlineNotification: {
    component: InlineNotification
  },
  Input: {
    component: InputField
  },
  InputMask: {
    component: InputMaskField
  },
  InputNumber: {
    component: InputNumberField
  },
  PhoneNumber: {
    component: PhoneNumberField
  },
  FileUpload: {
    component: FileUploadField
  },
  Link: {
    component: Link
  },
  Loader: {
    component: Loader
  },
  Lookup: {
    component: LookupField
  },
  GlobalizationChooser: {
    component: GlobalizationChooser
  },
  LocalizedDateRange: {
    component: LocalizedDateRange
  },
  MapArea: {
    component: MapArea
  },
  NotificationAction: {
    component: NotificationAction
  },
  NumberValue: {
    component: NumberValue
  },
  RadioButtonCard: {
    component: RadioButtonCardField
  },
  RadioButton: {
    component: RadioButtonField
  },
  DropdownSelect: {
    component: DropdownSelectField
  },
  Stepper: {
    component: StepperField
  },
  Switch: {
    component: SwitchField
  },
  Slider: {
    component: Slider
  },
  TabSet: {
    component: TabSet
  },
  Tab: {
    component: Tab
  },
  TextArea: {
    component: TextAreaField
  },
  ThemeChooser: {
    component: ThemeChooser
  },
  TypeaheadMultiSelect: {
    component: TypeaheadMultiSelectField
  },
  Date: {
    component: DateField
  },
  DateTime: {
    component: DateTimeField
  },
  DateTimeZone: {
    component: DateTimeZoneField
  },
  Year: {
    component: YearField
  },
  Panel: {
    component: Panel
  },
  Header: {
    component: Header
  },
  HeaderActions: {
    component: HeaderActions
  },
  LogoTitle: {
    component: LogoTitle
  },
  TopNavigation: {
    component: TopNavigation
  },
  SideNavigation: {
    component: SideNavigation
  },
  StickyFooter: {
    component: StickyFooter
  },
  InlineLoader: {
    component: InlineLoader
  },
  IFrame: {
    component: IFrame
  },
  IntlElement: {
    component: IntlElement
  },
  Money: {
    component: Money
  },
  Tag: {
    component: Tag
  },
  TagCollection: {
    component: TagCollection
  },
  HelpHeading: {
    component: HelpHeading
  },
  HelpParagraph: {
    component: HelpParagraph
  },
  HelpLink: {
    component: HelpLink
  },
  SimpleProgressBar: {
    component: SimpleProgressBar
  },
  PhaseProgressBar: {
    component: PhaseProgressBar
  },
  StepProgressBar: {
    component: StepProgressBar
  },
  IntlPhoneNumber: {
    component: IntlPhoneNumberField
  },
  DateRange: {
    component: DateRangeField
  },
  FormattedPhoneNumber: {
    component: FormattedPhoneNumber
  },
  PhoneNumberValue: {
    component: PhoneNumberValue
  },
  TitleElement: {
    component: TitleElement
  },
  ActionTitleBar: {
    component: ActionTitleBar
  },
  EditCancelSaveTitleBar: {
    component: EditCancelSaveTitleBar
  }
};
export var lowercaseSimpleNamedComponentMap = _reduceInstanceProperty(_context = _Object$entries(simpleNamedComponentMap)).call(_context, function (componentMap, _ref) {
  var _ref2 = _slicedToArray(_ref, 2),
    key = _ref2[0],
    val = _ref2[1];
  return _objectSpread({}, componentMap, _defineProperty({}, key.toLowerCase(), val));
}, {});
export function resolveComponentFromDatatype(datatype) {
  var useDefault = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var componentInfo = simpleDatatypeComponentMap[datatype && datatype.toLowerCase()];
  if (componentInfo) {
    return componentInfo;
  }
  return useDefault ? simpleDatatypeComponentMap.string : null;
}
export function resolveComponentFromName(name) {
  if (!name) {
    return undefined;
  }
  return simpleNamedComponentMap[name] || resolveComponentFromLowercaseName(name);
}
var logDeprecationWarning = makeUniqueWarningLog('using case-insensitive keys for "component" property in metadata has been deprecated. Match the casing used by the component Name');
var resolveComponentFromLowercaseName = function resolveComponentFromLowercaseName(name) {
  var component = lowercaseSimpleNamedComponentMap[name.toLowerCase()];
  if (component) {
    logDeprecationWarning();
  }
  return component;
};
export function setComponentMapOverrides(componentMap, components) {
  var overrideMap = cloneDeep(componentMap);
  var keys = _Object$keys(overrideMap);
  _forEachInstanceProperty(keys).call(keys, function (key) {
    var overrideComponent = overrideMap[key].component;
    if (overrideComponent) {
      if (components) {
        var component = components[overrideComponent];
        if (component) {
          if (overrideComponent === overrideComponent.toLowerCase()) {
            lowercaseSimpleNamedComponentMap[overrideComponent.toLowerCase()] = {
              component: component
            };
          } else {
            simpleNamedComponentMap[overrideComponent] = {
              component: component
            };
          }
        }
      }
      if (resolveComponentFromName(overrideComponent)) {
        var resolvedComponent = resolveComponentFromName(overrideComponent);
        overrideMap[key].component = resolvedComponent.component;
      }
    }
    if (simpleDatatypeComponentMap[key]) {
      if (overrideMap[key].replace === true) {
        simpleDatatypeComponentMap[key] = overrideMap[key];
      } else {
        merge(simpleDatatypeComponentMap[key], overrideMap[key]);
      }
    }
  });
}
export function setSimpleNamedComponentMapOverrides(componentMapOverrides) {
  var _context2;
  _forEachInstanceProperty(_context2 = _Object$keys(componentMapOverrides)).call(_context2, function (key) {
    if (simpleNamedComponentMap[key]) {
      simpleNamedComponentMap[key] = _objectSpread({}, simpleNamedComponentMap[key], {}, componentMapOverrides[key]);
    }
    if (lowercaseSimpleNamedComponentMap[key.toLowerCase()]) {
      var lowerCaseKey = key.toLowerCase();
      lowercaseSimpleNamedComponentMap[lowerCaseKey] = _objectSpread({}, lowercaseSimpleNamedComponentMap[lowerCaseKey], {}, componentMapOverrides[key]);
    }
  });
}
export function appendSimpleNamedComponentMap(componentsMap) {
  var _context3;
  _forEachInstanceProperty(_context3 = _Object$entries(componentsMap)).call(_context3, function (_ref3) {
    var _ref4 = _slicedToArray(_ref3, 2),
      key = _ref4[0],
      value = _ref4[1];
    if (simpleNamedComponentMap[key] || lowercaseSimpleNamedComponentMap[key.toLowerCase()]) {
      warning("".concat(key, " already exists in simpleNamedComponentMap"));
      return;
    }
    simpleNamedComponentMap[key] = value;
    lowercaseSimpleNamedComponentMap[key.toLowerCase()] = value;
  });
}
export function setSimpleDatatypeComponentMapOverrides(componentMapOverrides) {
  var _context4;
  _forEachInstanceProperty(_context4 = _Object$keys(componentMapOverrides)).call(_context4, function (key) {
    if (simpleDatatypeComponentMap[key]) {
      simpleDatatypeComponentMap[key] = _objectSpread({}, simpleDatatypeComponentMap[key], {}, componentMapOverrides[key]);
    }
  });
}