import _defineProperty from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _objectWithoutProperties from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectWithoutProperties";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _someInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/some";
import React, { useEffect, useCallback, useRef, useContext, useMemo } from 'react';
import cx from 'classnames';
import { useLocation } from 'react-router-dom';
import { InlineLabel, Icon } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { DropdownMenu } from '../DropdownMenu/DropdownMenu';
import { NavigationContent } from '../NavBar/NavigationContent';
import { removeNavLinkIcon } from '../NavBar/navBarHelper';
import { NavBarAccordion } from '../NavBar/SideNavigation/NavBarAccordion/NavBarAccordion';
import { messages } from './ApplicationHeader.messages';
import styles from "../NavBar/NavBarItem.module.css";
import appHeaderStyles from "./ApplicationHeader.module.css";
var useLocationChange = function useLocationChange(callback) {
  var deps = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var location = useLocation();
  var oldLocation = useRef(location);
  var cb = useCallback(callback, deps);
  useEffect(function () {
    if (location !== oldLocation.current) {
      cb();
    }
    oldLocation.current = location;
  }, [cb, location]);
};
var MoreButtonContent = function MoreButtonContent(_ref) {
  var toggle = _ref.toggle,
    routes = _ref.routes,
    setMoreButtonWidth = _ref.setMoreButtonWidth,
    isOpen = _ref.isOpen,
    accessibilityProps = _ref.accessibilityProps,
    id = _ref.id;
  var location = useLocation();
  var translator = useContext(TranslatorContext);
  var onToggle = function onToggle() {
    toggle(!isOpen);
  };
  useLocationChange(function () {
    return toggle(false);
  }, [isOpen]);
  var isActivePath = function isActivePath(_ref2) {
    var path = _ref2.path;
    return path === location.pathname;
  };
  var hasActiveRoute = _someInstanceProperty(routes).call(routes, function (_ref3) {
    var path = _ref3.path,
      subRoutes = _ref3.routes;
    return isActivePath({
      path: path
    }) || (subRoutes === null || subRoutes === void 0 ? void 0 : _someInstanceProperty(subRoutes).call(subRoutes, isActivePath));
  });
  useEffect(function () {
    var _accessibilityProps$r, _accessibilityProps$r2;
    if ((_accessibilityProps$r = accessibilityProps.ref.current) !== null && _accessibilityProps$r !== void 0 && _accessibilityProps$r.offsetWidth) setMoreButtonWidth((_accessibilityProps$r2 = accessibilityProps.ref.current) === null || _accessibilityProps$r2 === void 0 ? void 0 : _accessibilityProps$r2.offsetWidth);
  }, [setMoreButtonWidth, accessibilityProps.ref]);
  var classes = cx(appHeaderStyles.navItem, styles.topNavBarItem, styles.navBarItem, hasActiveRoute && appHeaderStyles.activeNavItem);
  return React.createElement("button", _extends({
    id: id,
    onClick: onToggle,
    className: classes,
    tabIndex: 0,
    role: "menuitem"
  }, accessibilityProps), React.createElement(InlineLabel, {
    iconPosition: "right",
    icon: React.createElement(Icon, {
      icon: "mi-more-vert",
      style: {
        fontSize: '1rem'
      }
    }),
    className: appHeaderStyles.titleWrapper
  }, translator(messages.moreButtonText)));
};
var NestedRoutesComponent = function NestedRoutesComponent(_ref4) {
  var routes = _ref4.routes,
    setMoreButtonWidth = _ref4.setMoreButtonWidth,
    id = _ref4.id,
    moreButtonListMenuOnLeft = _ref4.moreButtonListMenuOnLeft;
  var nestedRoutes = useMemo(function () {
    var _context;
    return _mapInstanceProperty(_context = _mapInstanceProperty(routes).call(routes, removeNavLinkIcon)).call(_context, function (_ref5) {
      var navLink = _ref5.navLink,
        rest = _objectWithoutProperties(_ref5, ["navLink"]);
      return _objectSpread({}, rest, {
        navLink: _objectSpread({}, navLink, {
          className: cx(navLink === null || navLink === void 0 ? void 0 : navLink.className, appHeaderStyles.moreMenuItem)
        })
      });
    });
  }, [routes]);
  var onRenderTriggerWrapFN = useCallback(function (_ref6, toggle) {
    var triggerId = _ref6.id,
      menuId = _ref6.menuId,
      isOpen = _ref6.isOpen,
      __ = _ref6.onMenuItemClick,
      accessibilityProps = _objectWithoutProperties(_ref6, ["id", "menuId", "isOpen", "onMenuItemClick"]);
    return React.createElement(MoreButtonContent, {
      toggle: toggle,
      routes: nestedRoutes,
      setMoreButtonWidth: setMoreButtonWidth,
      isOpen: isOpen,
      id: triggerId,
      accessibilityProps: accessibilityProps
    });
  }, [nestedRoutes, appHeaderStyles, setMoreButtonWidth]);
  if (!nestedRoutes.length) {
    return null;
  }
  return React.createElement(DropdownMenu, {
    id: id,
    onRenderTrigger: onRenderTriggerWrapFN,
    alignRight: moreButtonListMenuOnLeft,
    className: appHeaderStyles.moreButtonDropdown
  }, React.createElement(NavigationContent, {
    routes: nestedRoutes,
    nestedRoutesComponent: NavBarAccordion,
    alignment: "top"
  }));
};
export var createMoreButton = function createMoreButton(_ref7) {
  var routes = _ref7.routes,
    setMoreButtonWidth = _ref7.setMoreButtonWidth,
    moreButtonListMenuOnLeft = _ref7.moreButtonListMenuOnLeft;
  return {
    nestedRoutesComponent: function nestedRoutesComponent(_ref8) {
      var focused = _ref8.focused,
        id = _ref8.id;
      return React.createElement(NestedRoutesComponent, {
        routes: routes,
        setMoreButtonWidth: setMoreButtonWidth,
        focused: focused,
        id: id,
        moreButtonListMenuOnLeft: moreButtonListMenuOnLeft
      });
    },
    routes: [{
      path: '/'
    }]
  };
};
MoreButtonContent.__docgenInfo = {
  componentName: "MoreButtonContent",
  packageName: "@jutro/router",
  description: "",
  displayName: "MoreButtonContent",
  methods: [],
  actualName: "MoreButtonContent"
};
NestedRoutesComponent.__docgenInfo = {
  componentName: "NestedRoutesComponent",
  packageName: "@jutro/router",
  description: "",
  displayName: "NestedRoutesComponent",
  methods: [],
  actualName: "NestedRoutesComponent"
};