import _defineProperty from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import isFunction from "lodash/isFunction";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { log } from '@jutro/logger';
import { resolveAnimationFromName, animationDelayDefault } from '../config/animationMap';
import styles from "./animations.module.css";
var animationGroupPropTypes = {
  animation: PropTypes.oneOf(['expand', 'insert', 'highlight', 'reveal']),
  className: PropTypes.string,
  children: PropTypes.node
};
export var AnimationGroup = function AnimationGroup(props) {
  var animation = props.animation,
    children = props.children,
    dangerouslySetInnerHTML = props.dangerouslySetInnerHTML,
    onEntered = props.onEntered,
    other = _objectWithoutProperties(props, ["animation", "children", "dangerouslySetInnerHTML", "onEntered"]);
  var animationProps = useMemo(function () {
    var animationConfig = resolveAnimationFromName(animation, false);
    return isFunction(animationConfig) ? animationConfig() : animationConfig;
  }, [animation]);
  var onEnteredCallback = useCallback(function (node, isAppearing) {
    node.style = _objectSpread({}, node.style, {
      height: 'auto'
    });
    onEntered === null || onEntered === void 0 ? void 0 : onEntered(node, isAppearing);
  }, [onEntered]);
  if (animation && !animationProps) {
    log.warning("Unable to find animation alias for '".concat(animation, "'. Using default properties."));
    animationProps = {
      classNames: animation,
      timeout: animationDelayDefault
    };
  }
  var combinedProps = _objectSpread({}, animationProps, {}, other, {
    onEntered: onEnteredCallback
  });
  var classNames = combinedProps.classNames;
  var enterClasses = {
    enter: styles["".concat(classNames, "_enter")],
    enterActive: styles["".concat(classNames, "_enter_active")]
  };
  var exitClasses = {
    exit: styles["".concat(classNames, "_exit")],
    exitActive: styles["".concat(classNames, "_exit_active")]
  };
  var appearClasses;
  if (combinedProps.appear) {
    appearClasses = {
      appear: styles["".concat(classNames, "_enter")],
      appearActive: styles["".concat(classNames, "_enter_active")]
    };
  }
  combinedProps.classNames = _objectSpread({}, enterClasses, {}, exitClasses, {}, appearClasses);
  var transitionWrapper = combinedProps.transitionWrapper,
    groupProps = _objectWithoutProperties(combinedProps, ["transitionWrapper"]);
  var kidCount = React.Children.count(children);
  var transitionGroup;
  if (kidCount > 1) {
    var _context;
    var childTransitions = _mapInstanceProperty(_context = React.Children.toArray(children)).call(_context, function (child, index) {
      return React.createElement(CSSTransition, _extends({
        key: child.props.key || index
      }, groupProps), child);
    });
    transitionGroup = React.createElement(TransitionGroup, null, childTransitions);
  } else {
    transitionGroup = React.createElement(CSSTransition, groupProps, React.createElement("span", null, children));
  }
  if (transitionWrapper) {
    var wrapperClass = styles[transitionWrapper];
    return React.createElement("div", {
      className: wrapperClass
    }, transitionGroup);
  }
  return transitionGroup;
};
AnimationGroup.propTypes = animationGroupPropTypes;
AnimationGroup.__docgenInfo = {
  componentName: "AnimationGroup",
  packageName: "@jutro/components",
  description: "Defines an Animation Group.\nThis wraps TransitionGroup/CSSTransition and provides support for animation aliases to define transition properties.\nThe animations are triggered when elements are added to or removed from the children of the animation group.\nFor the 'swap' type animations where you are replacing one element with another, each element requires a unique\n'key' to trigger the animation.\n\nProperties: {@link AnimationGroup.propTypes}",
  displayName: "AnimationGroup",
  methods: [],
  actualName: "AnimationGroup",
  props: {
    animation: {
      type: {
        name: "enum",
        value: [{
          value: "'expand'",
          computed: false
        }, {
          value: "'insert'",
          computed: false
        }, {
          value: "'highlight'",
          computed: false
        }, {
          value: "'reveal'",
          computed: false
        }]
      },
      required: false,
      description: "Optional animation name/alias"
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Optional css class(es) to add to the grid element"
    },
    children: {
      type: {
        name: "node"
      },
      required: false,
      description: "Children, preferably 'Row'"
    }
  }
};