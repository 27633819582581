import _defineProperty from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import React, { useContext, useState, useMemo } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { IconButton } from '@jutro/components';
import { Flex, FlexItem } from '@jutro/layout';
import { TranslatorContext } from '@jutro/locale';
import { sideContentShape } from '@jutro/prop-types';
import styles from "./RightSideBar.module.css";
import { SideBody } from './SideBody/SideBody';
import { IconsBar } from './IconsBar/IconsBar';
import { messages } from './RightSideBar.messages';
import { resolveComponentFromMap } from '../resolveComponentFromMap';
export var RightSideBar = function RightSideBar(_ref) {
  var className = _ref.className,
    collapsible = _ref.collapsible,
    isInitiallyCollapsed = _ref.isInitiallyCollapsed,
    sides = _ref.sides,
    componentMap = _ref.componentMap;
  var _useState = useState(isInitiallyCollapsed),
    _useState2 = _slicedToArray(_useState, 2),
    isCollapsed = _useState2[0],
    setIsCollapsed = _useState2[1];
  var _useState3 = useState(0),
    _useState4 = _slicedToArray(_useState3, 2),
    activeSide = _useState4[0],
    setActiveSide = _useState4[1];
  var translator = useContext(TranslatorContext);
  var classes = cx(styles.rightSideBar, className);
  var showIconsBar = sides.length > 1;
  var showBar = collapsible || showIconsBar;
  var handleSelection = function handleSelection(sideIndex) {
    if (sideIndex === activeSide) {
      setIsCollapsed(!isCollapsed);
    } else {
      setActiveSide(sideIndex);
      if (isCollapsed) {
        setIsCollapsed(false);
      }
    }
  };
  var renderedContents = useMemo(function () {
    return _mapInstanceProperty(sides).call(sides, function (side, index) {
      var component = side.component,
        _side$componentProps = side.componentProps,
        componentProps = _side$componentProps === void 0 ? {} : _side$componentProps;
      var ResolvedComponent = resolveComponentFromMap(component, componentMap);
      var sideClassName = cx(_defineProperty({}, styles.hiddenSide, index !== activeSide));
      return React.createElement(SideBody, {
        id: "right-side-panel-".concat(index),
        className: sideClassName,
        "aria-labelledby": "right-side-icon-tab-".concat(index)
      }, React.createElement(ResolvedComponent, componentProps));
    });
  }, [sides, componentMap, activeSide]);
  var footer = collapsible && React.createElement(FlexItem, {
    className: styles.footer
  }, React.createElement(IconButton, {
    icon: isCollapsed ? 'mi-chevron-left' : 'mi-chevron-right',
    onClick: function onClick() {
      setIsCollapsed(!isCollapsed);
    },
    ariaLabel: translator(isCollapsed ? messages.collapsedButtonLabel : messages.expandedButtonLabel)
  }));
  var wrapperClasses = cx(styles.contentWrapper, _defineProperty({}, styles.contentWrapperCollapsed, collapsible && isCollapsed));
  return React.createElement(Flex, {
    gap: "none",
    className: classes,
    wrap: false
  }, React.createElement(FlexItem, {
    className: wrapperClasses
  }, React.createElement("div", {
    className: styles.contentWrapperInner
  }, renderedContents)), showBar && React.createElement(Flex, {
    direction: "column",
    className: styles.bar,
    gap: "none",
    justifyContent: "bottom"
  }, sides.length > 1 && React.createElement(IconsBar, {
    sides: sides,
    activeSide: activeSide,
    onSelection: handleSelection,
    className: styles.icons
  }), footer));
};
RightSideBar.propTypes = {
  className: PropTypes.string,
  collapsible: PropTypes.bool,
  isInitiallyCollapsed: PropTypes.bool,
  sides: PropTypes.arrayOf(sideContentShape).isRequired,
  componentMap: PropTypes.object
};
RightSideBar.defaultProps = {
  collapsible: true,
  isInitiallyCollapsed: false
};
RightSideBar.__docgenInfo = {
  componentName: "RightSideBar",
  packageName: "@jutro/router",
  description: "The `RightSideBar` component is designed as a container for 'IconsBar' and \"SideBody\"",
  displayName: "RightSideBar",
  methods: [],
  actualName: "RightSideBar",
  metadataType: "container",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Additional class names for component."
    },
    collapsible: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Defines whether component can be collapsed",
      defaultValue: {
        value: "true",
        computed: false
      }
    },
    isInitiallyCollapsed: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Default state for RightSideBar",
      defaultValue: {
        value: "false",
        computed: false
      }
    },
    sides: {
      type: {
        name: "arrayOf",
        value: {
          name: "shape",
          value: {
            label: {
              name: "custom",
              raw: "intlMessageShape.isRequired",
              description: "Label describing the content used for accessibility",
              required: true
            },
            icon: {
              name: "string",
              description: "Icon that will represent the content on the icon bar",
              required: false
            },
            component: {
              name: "elementType",
              description: "Component to render as content",
              required: true
            },
            componentProps: {
              name: "shape",
              value: {},
              description: "Component props",
              required: false
            }
          }
        }
      },
      required: true,
      description: "Array of contents and icons to display"
    },
    componentMap: {
      type: {
        name: "object"
      },
      required: false,
      description: "Map of component name to component classes"
    }
  }
};