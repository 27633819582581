import _defineProperty2 from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty2(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _everyInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/every";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _get from "@babel/runtime-corejs3/helpers/get";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();
  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
      result;
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }
    return _possibleConstructorReturn(this, result);
  };
}
function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;
  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}
import React from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import cx from 'classnames';
import { mappingDelta, mergeStyles, getMessageProp } from '@jutro/platform';
import { intlMessageShape } from '@jutro/prop-types';
import { FieldComponent } from '../FieldComponent/FieldComponent';
import styles from "./InputMaskField.module.css";
import inputStyles from "../InputField/InputField.module.css";
import { FieldIcon } from '../FieldComponent/FieldIcon';
import { applyInsertMode } from './inputMaskUtils';
import { messages } from './InputMaskField.messages';
var escapeChar = '\\';
var defaultFormatChars = {
  9: '[0-9]',
  a: '[A-Za-z]',
  '*': '[A-Za-z0-9]'
};
export var InputMaskField = function (_FieldComponent) {
  _inherits(InputMaskField, _FieldComponent);
  var _super = _createSuper(InputMaskField);
  function InputMaskField() {
    var _this;
    _classCallCheck(this, InputMaskField);
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    _this = _super.call.apply(_super, [this].concat(args));
    _defineProperty(_assertThisInitialized(_this), "state", {});
    _defineProperty(_assertThisInitialized(_this), "customHandleChange", function (event) {
      if (event.target.value === _this.getValue()) {
        return;
      }
      if (_this.pristine && event.target.value === _this.state.parsedMask) {
        return;
      }
      if (event.target.value === _this.state.parsedMask) {
        event.target.value = null;
      }
      _this.handleChange(event);
    });
    _defineProperty(_assertThisInitialized(_this), "beforeMaskedValueChange", function (newState, oldState, userInput, maskOptions) {
      return applyInsertMode(newState, oldState, userInput, maskOptions, _this.customHandleChange);
    });
    return _this;
  }
  _createClass(InputMaskField, [{
    key: "render",
    value: function render() {
      return _get(_getPrototypeOf(InputMaskField.prototype), "render", this).call(this);
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(prevProps) {
      if (this.props.maskChar !== prevProps.maskChar || this.props.mask !== prevProps.mask) {
        this.notifyChange(null);
        this.pristine = true;
        this.focusPristine = true;
      }
    }
  }, {
    key: "applyMaskOnValue",
    value: function applyMaskOnValue(value) {
      var _context;
      if (!value) {
        return value;
      }
      var _this$state = this.state,
        maskChar = _this$state.maskChar,
        parsedMask = _this$state.parsedMask,
        patternMap = _this$state.patternMap;
      var valueArray = value.split('');
      return _mapInstanceProperty(_context = parsedMask.split('')).call(_context, function (char, i) {
        return char === maskChar || !patternMap[i] && char === valueArray[0] ? valueArray.shift() : char;
      }).join('');
    }
  }, {
    key: "isComplete",
    value: function isComplete(value) {
      var _context2;
      var mask = this.props.mask;
      var _this$state2 = this.state,
        parsedMask = _this$state2.parsedMask,
        patternMap = _this$state2.patternMap;
      if (!mask) {
        return true;
      }
      if (!value || value.length < parsedMask.length) {
        return false;
      }
      return _everyInstanceProperty(_context2 = value.split('')).call(_context2, function (char, index) {
        var pattern = patternMap[index];
        return !pattern || char.match(pattern);
      });
    }
  }, {
    key: "getValidationMessages",
    value: function getValidationMessages() {
      var _this$props = this.props,
        readOnly = _this$props.readOnly,
        onGetValidationMessages = _this$props.onGetValidationMessages,
        messageProps = _this$props.messageProps,
        value = _this$props.value;
      var incompleteInput = getMessageProp('incompleteInput', messageProps, messages);
      if (!readOnly && !this.isEmpty()) {
        if (this.isComplete(this.applyMaskOnValue(value))) {
          if (onGetValidationMessages) {
            var customValidationMessages = onGetValidationMessages();
            if (customValidationMessages) {
              return [this.translator(customValidationMessages)];
            }
          }
        } else {
          return [this.translator(incompleteInput)];
        }
      }
      return _get(_getPrototypeOf(InputMaskField.prototype), "getValidationMessages", this).call(this);
    }
  }, {
    key: "renderControlReadOnly",
    value: function renderControlReadOnly(breakpointProps) {
      var id = breakpointProps.id,
        value = breakpointProps.value;
      var classes = cx(styles.inputMask, styles.inputMaskReadOnly);
      var formattedValue = this.applyMaskOnValue(value);
      var readonlyValue = this.isComplete(formattedValue) ? formattedValue : FieldComponent.defaultROEmptyValue;
      return React.createElement("div", {
        id: id,
        "data-read-only": true,
        className: classes
      }, readonlyValue);
    }
  }, {
    key: "isEmpty",
    value: function isEmpty() {
      var value = this.props.value;
      return _get(_getPrototypeOf(InputMaskField.prototype), "isEmpty", this).call(this, value) || this.state.parsedMask === value;
    }
  }, {
    key: "generateAccessibilityProperties",
    value: function generateAccessibilityProperties() {
      var placeholder = this.state.placeholder;
      var realPlaceholder = this.props.alwaysShowMask ? placeholder : this.translator(placeholder);
      return _objectSpread({}, _get(_getPrototypeOf(InputMaskField.prototype), "generateAccessibilityProperties", this).call(this), {
        'aria-placeholder': placeholder,
        placeholder: realPlaceholder
      });
    }
  }, {
    key: "renderControl",
    value: function renderControl(breakpointProps, _ref) {
      var isValid = _ref.isValid;
      var combinedStyles = mergeStyles(inputStyles, styles);
      var id = breakpointProps.id,
        value = breakpointProps.value,
        disabled = breakpointProps.disabled,
        controlClassName = breakpointProps.controlClassName,
        required = breakpointProps.required,
        mask = breakpointProps.mask,
        inputType = breakpointProps.inputType;
      var focusHandlers = this.getInputFocusHandlers();
      var classes = cx(combinedStyles.input, combinedStyles.inputMask, _defineProperty2(_defineProperty2({}, combinedStyles.empty, this.isEmpty()), "invalid", !isValid), controlClassName);
      return React.createElement(FieldIcon, breakpointProps, React.createElement(InputMask, _extends({
        id: id,
        mask: mask,
        maskChar: this.state.maskChar,
        type: inputType,
        className: classes,
        value: value || undefined,
        onChange: this.customHandleChange
      }, focusHandlers, {
        disabled: disabled,
        required: required,
        formatChars: breakpointProps.formatChars,
        autoComplete: "off",
        beforeMaskedValueChange: breakpointProps.insertMode ? this.beforeMaskedValueChange : undefined
      }, this.generateDataPathProperty(), this.generateAccessibilityProperties())));
    }
  }], [{
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(nextProps, prevState) {
      var maskChar = nextProps.maskChar ? nextProps.maskChar.charAt(0) : '';
      var _InputMaskField$parse = InputMaskField.parseMask(nextProps.mask, maskChar, nextProps.formatChars),
        parsedMask = _InputMaskField$parse.parsedMask,
        patternMap = _InputMaskField$parse.patternMap;
      var placeholder = nextProps.alwaysShowMask ? parsedMask : nextProps.placeholder;
      return mappingDelta({
        parsedMask: parsedMask,
        maskChar: maskChar,
        placeholder: placeholder,
        patternMap: patternMap
      }, prevState);
    }
  }, {
    key: "parseMask",
    value: function parseMask(mask, maskChar, formatChars) {
      var escape = false;
      var parsedMask = '';
      var patternMap = [];
      var _iterator = _createForOfIteratorHelper(mask),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var char = _step.value;
          if (char === escapeChar) {
            escape = !escape;
            if (!escape) {
              parsedMask += char;
              patternMap.push(null);
            }
          } else {
            parsedMask += formatChars[char] && !escape ? maskChar : char;
            patternMap.push(escape ? null : formatChars[char]);
            escape = false;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      return {
        parsedMask: parsedMask,
        patternMap: patternMap
      };
    }
  }]);
  return InputMaskField;
}(FieldComponent);
_defineProperty(InputMaskField, "propTypes", _objectSpread({}, FieldComponent.propTypes, {
  mask: PropTypes.string.isRequired,
  maskChar: PropTypes.string,
  alwaysShowMask: PropTypes.bool,
  formatChars: PropTypes.object,
  onGetValidationMessages: PropTypes.func,
  insertMode: PropTypes.bool,
  messageProps: PropTypes.shape({
    incompleteInput: intlMessageShape
  }),
  value: PropTypes.string
}));
_defineProperty(InputMaskField, "defaultProps", _objectSpread({}, FieldComponent.defaultProps, {
  maskChar: '_',
  formatChars: defaultFormatChars
}));
_defineProperty(InputMaskField, "contextType", FieldComponent.contextType);
InputMaskField.__docgenInfo = {
  componentName: "InputMaskField",
  packageName: "@jutro/components",
  description: "Renders an input field with a mask that you can control through props.",
  displayName: "InputMaskField",
  methods: [{
    name: "parseMask",
    docblock: "Obtain a parsed input mask as displayed on the input when value is empty and\na map of all the placeholder chars with validation patterns.\n\n@param {string} mask the mask string\n@param {string} maskChar the mask char\n@param {object} formatChars formatting characters and patterns\n@returns {object} parsed masked string and the pattern map",
    modifiers: ["static"],
    params: [{
      name: "mask",
      description: "the mask string",
      type: {
        name: "string"
      },
      optional: false
    }, {
      name: "maskChar",
      description: "the mask char",
      type: {
        name: "string"
      },
      optional: false
    }, {
      name: "formatChars",
      description: "formatting characters and patterns",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: "parsed masked string and the pattern map",
      type: {
        name: "object"
      }
    },
    description: "Obtain a parsed input mask as displayed on the input when value is empty and\na map of all the placeholder chars with validation patterns."
  }, {
    name: "applyMaskOnValue",
    docblock: null,
    modifiers: [],
    params: [{
      name: "value",
      optional: undefined,
      type: null
    }],
    returns: null
  }, {
    name: "isComplete",
    docblock: "Tests if input is complete (all mask chars are substituted with values)\nIf the maskChar itself is a valid value, positions with the maskChar are treated\nas populated.\n\n@param {string} value the value to be tested\n@returns {boolean}",
    modifiers: [],
    params: [{
      name: "value",
      description: "the value to be tested",
      type: {
        name: "string"
      },
      optional: false
    }],
    returns: {
      description: null,
      type: {
        name: "boolean"
      }
    },
    description: "Tests if input is complete (all mask chars are substituted with values)\nIf the maskChar itself is a valid value, positions with the maskChar are treated\nas populated."
  }, {
    name: "customHandleChange",
    docblock: "Saves initial value from input mask\n\n@param {event} event - onChange event",
    modifiers: [],
    params: [{
      name: "event",
      description: "onChange event",
      type: {
        name: "event"
      },
      optional: false
    }],
    returns: null,
    description: "Saves initial value from input mask"
  }, {
    name: "getValidationMessages",
    docblock: "Get the validation messages to display.\nIf input is not readonly, incomplete and not empty returns incomplete validation messages.\nThe onGetValidationMessage is called if provided and if the input value is complete\nOtherwise, has the same behavior as in FieldComponent.\n\n@returns {Array<any>} validation messages",
    modifiers: [],
    params: [],
    returns: {
      description: "validation messages",
      type: {
        name: "Array",
        elements: [{
          name: "any"
        }]
      }
    },
    description: "Get the validation messages to display.\nIf input is not readonly, incomplete and not empty returns incomplete validation messages.\nThe onGetValidationMessage is called if provided and if the input value is complete\nOtherwise, has the same behavior as in FieldComponent."
  }, {
    name: "renderControlReadOnly",
    docblock: "Should display default readOnly char?\n@override\n\n@param {object} breakpointProps - breakpoint-specific props\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Should display default readOnly char?"
  }, {
    name: "isEmpty",
    docblock: "Tests if component is empty\n@override\n\n@returns {boolean}",
    modifiers: [],
    params: [],
    returns: {
      description: null,
      type: {
        name: "boolean"
      }
    },
    description: "Tests if component is empty"
  }, {
    name: "generateAccessibilityProperties",
    docblock: "generates accessibility properties for the field component\n@returns {object} set of applicable wai-aria tags",
    modifiers: [],
    params: [],
    returns: {
      description: "set of applicable wai-aria tags",
      type: {
        name: "object"
      }
    },
    description: "generates accessibility properties for the field component"
  }, {
    name: "beforeMaskedValueChange",
    docblock: "Custom beforeMaskedValueChange (see react-input-mask documentation for details) allowing for notifying value after initialization.\nThis newState parameter contains the formatted value even if the value in props was not formatted.\n\n@param {object} newState - New input state\n@param {object} oldState - Input state before change\n@param {string} userInput - Raw entered or pasted string\n@param {object} maskOptions - Mask options\n\n@returns {object} formatted value along with selection",
    modifiers: [],
    params: [{
      name: "newState",
      description: "New input state",
      type: {
        name: "object"
      },
      optional: false
    }, {
      name: "oldState",
      description: "Input state before change",
      type: {
        name: "object"
      },
      optional: false
    }, {
      name: "userInput",
      description: "Raw entered or pasted string",
      type: {
        name: "string"
      },
      optional: false
    }, {
      name: "maskOptions",
      description: "Mask options",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: "formatted value along with selection",
      type: {
        name: "object"
      }
    },
    description: "Custom beforeMaskedValueChange (see react-input-mask documentation for details) allowing for notifying value after initialization.\nThis newState parameter contains the formatted value even if the value in props was not formatted."
  }, {
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: false
    }, {
      name: "{ isValid }"
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }],
  actualName: "InputMaskField",
  metadataType: "field",
  props: {
    mask: {
      type: {
        name: "string"
      },
      required: true,
      description: "The string that formats the mask to display, for example 999-999-9999. By default '9' indicates a number,\n'a' a letter and '*' a number or a letter. You can escape the special characters with a backslash."
    },
    maskChar: {
      type: {
        name: "string"
      },
      required: false,
      description: "The character used in the mask, for example `x` combined with `mask=99-99` displays `xx-xx`. If empty\nthe mask restrictions are still being enforced, but the mask is not visible.\nNOTE: Mask char should not be the same as one of possible input characters because this can generate unintended incorrect values.",
      defaultValue: {
        value: "'_'",
        computed: false
      }
    },
    alwaysShowMask: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true, the mask is always visible, if false the field will display the placeholder if not focused and empty."
    },
    formatChars: {
      type: {
        name: "object"
      },
      required: false,
      description: "A map of special mask formatting characters and the corresponding regular expressions the input must satisfy",
      defaultValue: {
        value: "{\n    9: '[0-9]',\n    a: '[A-Za-z]',\n    '*': '[A-Za-z0-9]',\n}",
        computed: false
      }
    },
    onGetValidationMessages: {
      type: {
        name: "func"
      },
      required: false,
      description: "A callback invoked from getValidationMessges, should return an array of error messages.\nNOTE: This prop is likely to be removed in the future and replaced by a more generic solution."
    },
    insertMode: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If set to true, the mask input will work in insert mode instead of overwrite mode.\nIf maskChar is empty, the input always works in insert mode"
    },
    messageProps: {
      type: {
        name: "shape",
        value: {
          incompleteInput: {
            name: "union",
            description: "Validation message for incomplete input field",
            required: false,
            value: [{
              name: "string"
            }, {
              name: "shape",
              value: {
                id: {
                  name: "string",
                  required: false
                },
                defaultMessage: {
                  name: "string",
                  required: false
                },
                args: {
                  name: "shape",
                  value: {},
                  required: false
                }
              }
            }]
          }
        }
      },
      required: false,
      description: "Message props(error message/aria-label)"
    },
    value: {
      type: {
        name: "string"
      },
      required: false,
      description: "Value to display in control"
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
};