import _defineProperty from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
var _Grid$propTypes, _Grid$propTypes2;
import React from 'react';
import PropTypes from 'prop-types';
import omit from "lodash/omit";
import { ComponentPropTypes } from '@jutro/prop-types';
import { Grid } from './Grid';
import { GridItem } from './GridItem';
import { useBreakpoint } from '../breakpoint/useBreakpoint';
var gridItemOptions = omit(GridItem.propTypes, ['children']);
var gridItemOptionsShape = PropTypes.shape(gridItemOptions);
var elementsWithId = ComponentPropTypes.elementsWithId;
var gridLayoutProps = _objectSpread({}, Grid.propTypes, {
  id: PropTypes.string.isRequired,
  children: elementsWithId.isRequired,
  defaultGridItem: gridItemOptionsShape,
  gridItems: PropTypes.arrayOf(gridItemOptionsShape),
  gap: (_Grid$propTypes = Grid.propTypes) === null || _Grid$propTypes === void 0 ? void 0 : _Grid$propTypes.gap,
  tag: (_Grid$propTypes2 = Grid.propTypes) === null || _Grid$propTypes2 === void 0 ? void 0 : _Grid$propTypes2.tag,
  phone: PropTypes.object,
  phoneWide: PropTypes.object,
  tablet: PropTypes.object
});
export var GridLayout = function GridLayout(props) {
  var _context;
  var _useBreakpoint = useBreakpoint(props),
    breakpointProps = _useBreakpoint.breakpointProps;
  var gridItems = breakpointProps.gridItems,
    defaultGridItem = breakpointProps.defaultGridItem,
    children = breakpointProps.children,
    phone = breakpointProps.phone,
    phoneWide = breakpointProps.phoneWide,
    tablet = breakpointProps.tablet,
    gap = breakpointProps.gap,
    other = _objectWithoutProperties(breakpointProps, ["gridItems", "defaultGridItem", "children", "phone", "phoneWide", "tablet", "gap"]);
  return React.createElement(Grid, _extends({}, other, {
    gap: gap
  }), _mapInstanceProperty(_context = React.Children).call(_context, children, function (child, index) {
    if (!child) {
      return null;
    }
    var itemOptions = gridItems === null || gridItems === void 0 ? void 0 : gridItems[index];
    return React.createElement(GridItem, _extends({
      key: child.props.id,
      clone: true
    }, defaultGridItem, itemOptions), child);
  }));
};
GridLayout.propTypes = gridLayoutProps;
GridLayout.defaultProps = _objectSpread({}, omit(Grid.defaultProps, ['children']), {
  gridItems: [],
  tablet: {
    columns: [1],
    gridItems: []
  },
  phoneWide: {
    columns: [1],
    gridItems: []
  },
  phone: {
    columns: [1],
    gridItems: []
  },
  gap: 'large',
  tag: 'section'
});
GridLayout.displayName = 'GridLayout';
GridLayout.__docgenInfo = {
  componentName: "GridLayout",
  packageName: "@jutro/layout",
  description: "Renders a CSS Grid and applies GridItem styles to each child\n(using GridItem's `clone` property (React.cloneElement)).\nFor tablet and mobile renders single column grid by default.",
  displayName: "GridLayout",
  methods: [],
  actualName: "GridLayout",
  metadataType: "layout",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: true,
      description: "Unique identifier"
    },
    children: {
      type: {
        name: "custom",
        raw: "elementsWithId"
      },
      required: true,
      description: "React elements with `id` properties"
    },
    defaultGridItem: {
      type: {
        name: "custom",
        raw: "gridItemOptionsShape"
      },
      required: false,
      description: "`GridItem` properties that will to be applied to each child by default."
    },
    gridItems: {
      type: {
        name: "arrayOf",
        value: {
          name: "custom",
          raw: "gridItemOptionsShape"
        }
      },
      required: false,
      description: "An array of GridItem properties to be applied to child with the same index.\nIf provided, it will extends/override the `defaultGridItem` properties for each item.\nPassing `null` or `undefined` will skip applying it to GridItem.",
      defaultValue: {
        value: "[]",
        computed: false
      }
    },
    gap: {
      type: {
        name: "custom",
        raw: "Grid.propTypes?.gap"
      },
      required: false,
      description: "Gap between rows and columns",
      defaultValue: {
        value: "'large'",
        computed: false
      }
    },
    tag: {
      type: {
        name: "custom",
        raw: "Grid.propTypes?.tag"
      },
      required: false,
      description: "Optional dom tag to render",
      defaultValue: {
        value: "'section'",
        computed: false
      }
    },
    phone: {
      type: {
        name: "object"
      },
      required: false,
      description: "Include any GridLayout property for use at 'phone' breakpoint",
      defaultValue: {
        value: "{\n    columns: [1],\n    gridItems: [],\n}",
        computed: false
      }
    },
    phoneWide: {
      type: {
        name: "object"
      },
      required: false,
      description: "Include any GridLayout property for use at 'phoneWide' breakpoint",
      defaultValue: {
        value: "{\n    columns: [1],\n    gridItems: [],\n}",
        computed: false
      }
    },
    tablet: {
      type: {
        name: "object"
      },
      required: false,
      description: "Include any GridLayout property for use at 'tablet' and 'phone' breakpoint;",
      defaultValue: {
        value: "{\n    columns: [1],\n    gridItems: [],\n}",
        computed: false
      }
    }
  },
  composes: ["./Grid"]
};