import _defineProperty from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _sliceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/slice";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import { useMemo } from 'react';
import identity from "lodash/identity";
import { useControlledReducer, bindActionCreators } from '../helper';
import { reducer } from './reducer';
import * as actions from './actions';
export function useSelection(initialState, selectedRows, selectionType, onSelectionChange) {
  var updateState = selectedRows && function (oldState) {
    return _objectSpread({}, oldState, {
      rows: selectedRows
    });
  };
  var _useControlledReducer = useControlledReducer({
      reducer: reducer,
      initialState: initialState,
      controlledState: withNormalizedRows(updateState, selectionType),
      onStateChange: onStateChange,
      updateInControlledMode: !updateState
    }),
    _useControlledReducer2 = _slicedToArray(_useControlledReducer, 2),
    state = _useControlledReducer2[0],
    dispatch = _useControlledReducer2[1];
  var boundActions = useMemo(function () {
    return bindActionCreators(dispatch, actions);
  }, []);
  return [state, boundActions];
  function onStateChange(_ref) {
    var rows = _ref.rows;
    if (onSelectionChange && rows !== state.rows) {
      onSelectionChange(rows);
    }
  }
}
function withNormalizedRows() {
  var updateState = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : identity;
  var selectionType = arguments.length > 1 ? arguments[1] : undefined;
  return function (state) {
    var _updateState = updateState(state),
      rows = _updateState.rows,
      rest = _objectWithoutProperties(_updateState, ["rows"]);
    return _objectSpread({}, rest, {
      rows: normalizeSelectedRows(rows, selectionType)
    });
  };
}
function normalizeSelectedRows(selectedRows, selectionType) {
  if (selectionType === 'none') {
    return [];
  }
  return selectionType === 'multi' ? selectedRows : _sliceInstanceProperty(selectedRows).call(selectedRows, 0, 1);
}