import _defineProperty from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import PropTypes from 'prop-types';
import { intlMessageShape } from './intlMessageShape';
export var placementOptions = ['top', 'top-start', 'top-end', 'right', 'right-start', 'right-end', 'bottom', 'bottom-start', 'bottom-end', 'left', 'left-start', 'left-end', 'auto', 'auto-start', 'auto-end'];
export var tooltipBaseProps = {
  text: intlMessageShape,
  title: intlMessageShape,
  link: intlMessageShape,
  href: intlMessageShape,
  renderContent: PropTypes.func
};
var tooltipShapeProps = _objectSpread({}, tooltipBaseProps, {
  placement: PropTypes.oneOf(placementOptions)
});
export var tooltipShape = PropTypes.shape(tooltipShapeProps);
export var nestedTooltipShape = PropTypes.shape(_objectSpread({}, tooltipShapeProps, {
  id: PropTypes.string,
  icon: PropTypes.string
}));