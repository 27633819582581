import _objectWithoutProperties from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectWithoutProperties";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import React, { useContext, useState, useCallback, useMemo } from 'react';
import cx from 'classnames';
import { IntlContext } from '@jutro/locale';
import { getDecimalRegex, isSafeNumber } from '@jutro/data';
import { IconButton } from '../../IconButton/IconButton';
import styles from "./NumberInput.module.css";
export var NumberInput = function NumberInput(_ref) {
  var value = _ref.value,
    onChange = _ref.onChange,
    onFocus = _ref.onFocus,
    onBlur = _ref.onBlur,
    decimalPlaces = _ref.decimalPlaces,
    disabled = _ref.disabled,
    restProps = _objectWithoutProperties(_ref, ["value", "onChange", "onFocus", "onBlur", "decimalPlaces", "disabled"]);
  var _useContext = useContext(IntlContext),
    formatNumber = _useContext.formatNumber;
  var _useState = useState(),
    _useState2 = _slicedToArray(_useState, 2),
    isFocused = _useState2[0],
    setFocused = _useState2[1];
  var numericValue = Number(value) || 0;
  var step = Number(restProps.step);
  var handleValueChange = useCallback(function (event) {
    var _event$target$value, _event$target;
    var newValue = (_event$target$value = (_event$target = event.target) === null || _event$target === void 0 ? void 0 : _event$target.value) !== null && _event$target$value !== void 0 ? _event$target$value : '';
    if (isSafeNumber(newValue) && getDecimalRegex(decimalPlaces).test(newValue)) {
      onChange(newValue);
    }
  }, [decimalPlaces, onChange]);
  var handleUpArrowClick = useCallback(function () {
    if (disabled) {
      return;
    }
    var newValue = numericValue + step;
    if (restProps.max === undefined || newValue <= restProps.max) {
      onChange(newValue);
    }
  }, [numericValue, step]);
  var handleDownArrowClick = useCallback(function () {
    if (disabled) {
      return;
    }
    var newValue = numericValue - step;
    if (restProps.min === undefined || newValue >= restProps.min) {
      onChange(newValue);
    }
  }, [numericValue, step]);
  var handleOnArrowMouseDown = useCallback(function (event) {
    event.preventDefault();
  }, []);
  var handleBlur = useCallback(function (event) {
    setFocused(false);
    onBlur(event);
  }, [onBlur]);
  var handleFocus = useCallback(function (event) {
    onFocus(event);
    setFocused(true);
  }, [onFocus]);
  var handleBeforeInput = useCallback(function (event) {
    var data = event.data;
    if (!data.match(/^[0-9,-. ]+$/)) {
      event.preventDefault();
    }
  }, []);
  var formattedValue = useMemo(function () {
    return !value && value !== 0 ? '' : formatNumber(value, {
      maximumFractionDigits: decimalPlaces
    });
  }, [decimalPlaces, formatNumber, value]);
  return React.createElement("div", {
    className: styles.numberInput
  }, React.createElement("input", _extends({
    inputMode: "decimal",
    type: isFocused ? 'number' : 'text',
    value: isFocused ? value : formattedValue,
    onChange: handleValueChange,
    onBlur: handleBlur,
    onBeforeInput: handleBeforeInput,
    onFocus: handleFocus,
    disabled: disabled
  }, restProps)), isFocused && React.createElement(React.Fragment, null, React.createElement(IconButton, {
    icon: "mi-arrow-drop-up",
    onMouseDown: handleOnArrowMouseDown,
    onClick: handleUpArrowClick,
    className: cx(styles.numberInputButton, styles.numberInputUp),
    tabIndex: -1
  }), React.createElement(IconButton, {
    icon: "mi-arrow-drop-down",
    onMouseDown: handleOnArrowMouseDown,
    onClick: handleDownArrowClick,
    className: cx(styles.numberInputButton, styles.numberInputDown),
    tabIndex: -1
  })));
};
NumberInput.__docgenInfo = {
  componentName: "NumberInput",
  packageName: "@jutro/components",
  description: "",
  displayName: "NumberInput",
  methods: [],
  actualName: "NumberInput"
};