import _defineProperty from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _repeatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/repeat";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _Object$keys from "@babel/runtime-corejs3/core-js-stable/object/keys";
import _Number$isNaN from "@babel/runtime-corejs3/core-js-stable/number/is-nan";
import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import mapValues from "lodash/mapValues";
import map from "lodash/fp/map";
import styles from "./Grid.module.css";
import { useBreakpoint } from '../breakpoint/useBreakpoint';
var mapToFraction = map(function (el) {
  return _Number$isNaN(Number(el)) ? el : "".concat(el, "fr");
});
export var gaps = {
  none: 'None',
  small: 'Small',
  medium: 'Medium',
  large: 'Large'
};
var vgapMapping = mapValues(gaps, function (type) {
  return styles["vgap".concat(type)];
});
var hgapMapping = mapValues(gaps, function (type) {
  return styles["hgap".concat(type)];
});
var alignments = {
  top: 'Start',
  middle: 'Center',
  bottom: 'End',
  baseline: 'Baseline',
  stretch: 'Stretch'
};
export var gridAlignments = _Object$keys(alignments);
var valignItemsMapping = mapValues(alignments, function (type) {
  return styles["alignItems".concat(type)];
});
var valignContentMapping = mapValues(alignments, function (type) {
  return styles["alignContent".concat(type)];
});
var justifications = {
  left: 'Start',
  center: 'Center',
  right: 'End',
  around: 'Around',
  between: 'Between',
  evenly: 'Evenly',
  stretch: 'Stretch'
};
var justifyItemsMapping = mapValues(justifications, function (type) {
  return styles["justifyItems".concat(type)];
});
var justifyContentMapping = mapValues(justifications, function (type) {
  return styles["justifyContent".concat(type)];
});
export var styleMappings = null;
if (window.__TEST__) {
  styleMappings = {
    vgap: vgapMapping,
    hgap: hgapMapping,
    alignItems: valignItemsMapping,
    alignContent: valignContentMapping,
    justifyItems: justifyItemsMapping,
    justifyContent: justifyContentMapping
  };
}
export var Grid = function Grid(props) {
  var _useBreakpoint = useBreakpoint(props),
    breakpointProps = _useBreakpoint.breakpointProps;
  var columnsProp = breakpointProps.columns,
    repeat = _repeatInstanceProperty(breakpointProps),
    rowsProp = breakpointProps.rows,
    autoRows = breakpointProps.autoRows,
    gap = breakpointProps.gap,
    _breakpointProps$vgap = breakpointProps.vgap,
    vgap = _breakpointProps$vgap === void 0 ? gap : _breakpointProps$vgap,
    _breakpointProps$hgap = breakpointProps.hgap,
    hgap = _breakpointProps$hgap === void 0 ? gap : _breakpointProps$hgap,
    justifyContent = breakpointProps.justifyContent,
    valignContent = breakpointProps.valignContent,
    justifyItems = breakpointProps.justifyItems,
    valignItems = breakpointProps.valignItems,
    tag = breakpointProps.tag,
    children = breakpointProps.children,
    className = breakpointProps.className,
    blockPointerEvents = breakpointProps.blockPointerEvents,
    style = breakpointProps.style,
    phone = breakpointProps.phone,
    phoneWide = breakpointProps.phoneWide,
    tablet = breakpointProps.tablet,
    dangerouslySetInnerHTML = breakpointProps.dangerouslySetInnerHTML,
    other = _objectWithoutProperties(breakpointProps, ["columns", "repeat", "rows", "autoRows", "gap", "vgap", "hgap", "justifyContent", "valignContent", "justifyItems", "valignItems", "tag", "children", "className", "blockPointerEvents", "style", "phone", "phoneWide", "tablet", "dangerouslySetInnerHTML"]);
  var columns = mapToFraction(columnsProp);
  var rows = mapToFraction(rowsProp);
  var vgapClass = vgap && vgapMapping[vgap];
  var hgapClass = hgap && hgapMapping[hgap];
  var valignContentClass = valignContent && valignContentMapping[valignContent];
  var justifyContentClass = justifyContent && justifyContentMapping[justifyContent];
  var valignItemsClass = valignItems && valignItemsMapping[valignItems];
  var justifyItemsClass = justifyItems && justifyItemsMapping[justifyItems];
  var classes = cx(styles.grid, vgapClass, hgapClass, valignContentClass, justifyContentClass, valignItemsClass, justifyItemsClass, className);
  var hasHiddenColumns = false;
  var gridColumns;
  if (columns) {
    if (repeat) {
      gridColumns = "repeat(".concat(repeat, ", ").concat(columns.join(' '));
    } else {
      var filteredColumns = _filterInstanceProperty(columns).call(columns, function (column) {
        return column !== '0fr';
      });
      gridColumns = filteredColumns.join(' ');
      hasHiddenColumns = columns.length !== filteredColumns.length;
    }
  }
  var gridRows;
  if (rows) {
    gridRows = rows.join(' ');
  }
  var gridAutoRows;
  if (autoRows) {
    gridAutoRows = autoRows.join(' ');
  }
  var inlineStyles = _objectSpread({}, style);
  if (gridColumns || gridRows || gridAutoRows) {
    if (gridColumns) {
      inlineStyles.gridTemplateColumns = gridColumns;
    }
    if (gridRows) {
      inlineStyles.gridTemplateRows = gridRows;
    }
    if (gridAutoRows) {
      inlineStyles.gridAutoRows = gridAutoRows;
    }
    if (blockPointerEvents) {
      inlineStyles.pointerEvents = 'none';
    }
  }
  var realChildren = children;
  if (hasHiddenColumns && columns) {
    var _context;
    var colLength = columns.length;
    realChildren = _mapInstanceProperty(_context = React.Children).call(_context, children, function (child, index) {
      var colIndex = index % colLength;
      return columns[colIndex] !== '0fr' ? child : null;
    });
  }
  var Tag = tag;
  return React.createElement(Tag, _extends({
    className: classes,
    style: inlineStyles
  }, other), realChildren);
};
export var gridPropTypes = {
  columns: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired),
  repeat: PropTypes.oneOfType([PropTypes.oneOf(['auto-fit', 'auto-fill']), PropTypes.number, PropTypes.string]),
  rows: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired),
  autoRows: PropTypes.array,
  gap: PropTypes.oneOf(_Object$keys(gaps)),
  hgap: PropTypes.oneOf(_Object$keys(gaps)),
  vgap: PropTypes.oneOf(_Object$keys(gaps)),
  valignContent: PropTypes.oneOf(gridAlignments),
  justifyContent: PropTypes.oneOf(_Object$keys(justifications)),
  valignItems: PropTypes.oneOf(gridAlignments),
  justifyItems: PropTypes.oneOf(_Object$keys(justifications)),
  tag: PropTypes.elementType,
  className: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
  phone: PropTypes.object,
  phoneWide: PropTypes.object,
  tablet: PropTypes.object,
  blockPointerEvents: PropTypes.bool
};
Grid.propTypes = gridPropTypes;
Grid.defaultProps = {
  gap: 'medium',
  tag: 'div'
};
Grid.displayName = 'Grid';
Grid.__docgenInfo = {
  componentName: "Grid",
  packageName: "@jutro/layout",
  description: "Renders a css grid. For each grid item, place its children in an Jutro `GridItem`.",
  displayName: "Grid",
  methods: [],
  actualName: "Grid",
  metadataType: "layout",
  props: {
    columns: {
      type: {
        name: "arrayOf",
        value: {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "number"
          }]
        }
      },
      required: false,
      description: "Define explicit columns widths"
    },
    repeat: {
      type: {
        name: "union",
        value: [{
          name: "enum",
          value: [{
            value: "'auto-fit'",
            computed: false
          }, {
            value: "'auto-fill'",
            computed: false
          }]
        }, {
          name: "number"
        }, {
          name: "string"
        }]
      },
      required: false,
      description: "Repeat columns"
    },
    rows: {
      type: {
        name: "arrayOf",
        value: {
          name: "union",
          value: [{
            name: "string"
          }, {
            name: "number"
          }]
        }
      },
      required: false,
      description: "Define explicit rows heights"
    },
    autoRows: {
      type: {
        name: "array"
      },
      required: false,
      description: "Define implicit rows"
    },
    gap: {
      type: {
        name: "enum",
        value: [{
          value: "\"none\"",
          computed: false
        }, {
          value: "\"small\"",
          computed: false
        }, {
          value: "\"medium\"",
          computed: false
        }, {
          value: "\"large\"",
          computed: false
        }]
      },
      required: false,
      description: "Gap between rows and columns ('none', 'small', 'medium', 'large')",
      defaultValue: {
        value: "'medium'",
        computed: false
      }
    },
    hgap: {
      type: {
        name: "enum",
        value: [{
          value: "\"none\"",
          computed: false
        }, {
          value: "\"small\"",
          computed: false
        }, {
          value: "\"medium\"",
          computed: false
        }, {
          value: "\"large\"",
          computed: false
        }]
      },
      required: false,
      description: "Gap between columns ('none', 'small', 'medium', 'large')\nIf unspecified, will fallback to 'gap' property."
    },
    vgap: {
      type: {
        name: "enum",
        value: [{
          value: "\"none\"",
          computed: false
        }, {
          value: "\"small\"",
          computed: false
        }, {
          value: "\"medium\"",
          computed: false
        }, {
          value: "\"large\"",
          computed: false
        }]
      },
      required: false,
      description: "Gap between rows ('none', 'small', 'medium', 'large')\nIf unspecified, will fallback to 'gap' property."
    },
    valignContent: {
      type: {
        name: "enum",
        value: [{
          value: "\"top\"",
          computed: false
        }, {
          value: "\"middle\"",
          computed: false
        }, {
          value: "\"bottom\"",
          computed: false
        }, {
          value: "\"baseline\"",
          computed: false
        }, {
          value: "\"stretch\"",
          computed: false
        }]
      },
      required: false,
      description: "Vertical align the grid within its container; default - 'stretch'"
    },
    justifyContent: {
      type: {
        name: "enum",
        value: [{
          value: "\"left\"",
          computed: false
        }, {
          value: "\"center\"",
          computed: false
        }, {
          value: "\"right\"",
          computed: false
        }, {
          value: "\"around\"",
          computed: false
        }, {
          value: "\"between\"",
          computed: false
        }, {
          value: "\"evenly\"",
          computed: false
        }, {
          value: "\"stretch\"",
          computed: false
        }]
      },
      required: false,
      description: "Justify the grid within its container (left, center, right, around, between, evenly)"
    },
    valignItems: {
      type: {
        name: "enum",
        value: [{
          value: "\"top\"",
          computed: false
        }, {
          value: "\"middle\"",
          computed: false
        }, {
          value: "\"bottom\"",
          computed: false
        }, {
          value: "\"baseline\"",
          computed: false
        }, {
          value: "\"stretch\"",
          computed: false
        }]
      },
      required: false,
      description: "Vertical align all items within the grid; default - 'stretch'"
    },
    justifyItems: {
      type: {
        name: "enum",
        value: [{
          value: "\"left\"",
          computed: false
        }, {
          value: "\"center\"",
          computed: false
        }, {
          value: "\"right\"",
          computed: false
        }, {
          value: "\"around\"",
          computed: false
        }, {
          value: "\"between\"",
          computed: false
        }, {
          value: "\"evenly\"",
          computed: false
        }, {
          value: "\"stretch\"",
          computed: false
        }]
      },
      required: false,
      description: "Justify all items within the grid (left, center, right, around, between, evenly)"
    },
    tag: {
      type: {
        name: "elementType"
      },
      required: false,
      description: "Optional dom tag to render",
      defaultValue: {
        value: "'div'",
        computed: false
      }
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "Optional css class(es) to add to the grid tag"
    },
    children: {
      type: {
        name: "node"
      },
      required: false,
      description: "Children; preferably 'GridItem'; works with any child"
    },
    style: {
      type: {
        name: "object"
      },
      required: false,
      description: "DOM element styles"
    },
    phone: {
      type: {
        name: "object"
      },
      required: false,
      description: "Include any Grid property for use at 'phone' breakpoint"
    },
    phoneWide: {
      type: {
        name: "object"
      },
      required: false,
      description: "Include any Grid property for use at 'phoneWide' breakpoint"
    },
    tablet: {
      type: {
        name: "object"
      },
      required: false,
      description: "Include any Grid property for use at 'tablet' and 'phone' breakpoint;"
    },
    blockPointerEvents: {
      type: {
        name: "bool"
      },
      required: false,
      description: ""
    }
  }
};