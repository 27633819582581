import _defineProperty2 from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();
  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
      result;
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }
    return _possibleConstructorReturn(this, result);
  };
}
function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;
  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}
import React, { Component, useRef, useEffect } from 'react';
import { ToastContainer, toast as _toast, Slide } from 'react-toastify';
import cx from 'classnames';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import { renderWithTheme } from '@jutro/theme';
import { intlMessageShape } from '@jutro/prop-types';
import styles from "./Toast.module.css";
import { IconButton } from '../IconButton/IconButton';
import { InlineNotification } from '../inlineNotification/InlineNotification';
import { getKeyPressHandler } from '../../accessibility/getKeyPressHandler';
var CloseToastButton = function CloseToastButton(_ref) {
  var closeToast = _ref.closeToast,
    iconColor = _ref.iconColor,
    className = _ref.className,
    setFocus = _ref.setFocus;
  var focusReturn = useRef(document.activeElement);
  var buttonRef = useRef(null);
  var setFocusReturn = function setFocusReturn(event) {
    if (event.target !== buttonRef.current) {
      focusReturn.current = document.activeElement;
    }
  };
  useEffect(function () {
    document.addEventListener('focusin', setFocusReturn);
    if (setFocus) {
      buttonRef.current.focus();
    }
    return function () {
      document.removeEventListener('focusin', setFocusReturn);
    };
  }, [setFocus]);
  var closeHandler = function closeHandler(event) {
    closeToast(event);
    focusReturn.current.focus();
  };
  return React.createElement(IconButton, {
    icon: "mi-close",
    iconColor: iconColor,
    className: className,
    onKeyPress: getKeyPressHandler(closeHandler),
    onClick: closeHandler,
    ref: buttonRef
  });
};
export var ToastProvider = function (_Component) {
  _inherits(ToastProvider, _Component);
  var _super = _createSuper(ToastProvider);
  function ToastProvider() {
    _classCallCheck(this, ToastProvider);
    return _super.apply(this, arguments);
  }
  _createClass(ToastProvider, [{
    key: "renderToastContainer",
    value: function renderToastContainer(theme) {
      ToastProvider.theme = theme;
      return React.createElement(ToastContainer, {
        hideProgressBar: true,
        transition: Slide,
        closeOnClick: false,
        role: "status"
      });
    }
  }, {
    key: "render",
    value: function render() {
      return renderWithTheme(this.renderToastContainer);
    }
  }], [{
    key: "toast",
    value: function toast(_ref2) {
      var message = _ref2.message,
        type = _ref2.type,
        autoClose = _ref2.autoClose,
        autoFocus = _ref2.autoFocus;
      var closeButtonClasses = cx(styles.dismissButton, _defineProperty2({}, styles.toastWarning, type === 'warning'));
      var closeButtonColor = type === 'warning' ? 'dark' : 'light';
      var setFocus = autoFocus || !autoClose;
      var toastContent = React.createElement(InlineNotification, {
        id: "toast_".concat(type, "-").concat(uuid.v4()),
        message: message,
        type: type,
        isEmbeddedNotification: true,
        isDismissable: false
      });
      _toast(toastContent, {
        autoClose: autoClose,
        type: type,
        closeButton: React.createElement(CloseToastButton, {
          iconColor: closeButtonColor,
          className: closeButtonClasses,
          setFocus: setFocus
        }),
        className: styles.toast,
        bodyClassName: styles.toastBody
      });
    }
  }]);
  return ToastProvider;
}(Component);
_defineProperty(ToastProvider, "propTypes", {
  autoClose: PropTypes.bool,
  type: PropTypes.oneOf(['success', 'info', 'warning', 'error']),
  message: intlMessageShape,
  autoFocus: PropTypes.bool
});
CloseToastButton.__docgenInfo = {
  componentName: "CloseToastButton",
  packageName: "@jutro/components",
  description: "",
  displayName: "CloseToastButton",
  methods: [],
  actualName: "CloseToastButton"
};
ToastProvider.__docgenInfo = {
  componentName: "ToastProvider",
  packageName: "@jutro/components",
  description: "",
  displayName: "ToastProvider",
  methods: [{
    name: "toast",
    docblock: null,
    modifiers: ["static"],
    params: [{
      name: "{ message, type, autoClose, autoFocus }",
      optional: undefined,
      type: null
    }],
    returns: null
  }, {
    name: "renderToastContainer",
    docblock: null,
    modifiers: [],
    params: [{
      name: "theme",
      optional: false,
      type: null
    }],
    returns: null
  }],
  actualName: "ToastProvider",
  props: {
    autoClose: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true, the toast would be closed automatically after 5s\nIf false, focus will be drawn to the dismiss button"
    },
    type: {
      type: {
        name: "enum",
        value: [{
          value: "'success'",
          computed: false
        }, {
          value: "'info'",
          computed: false
        }, {
          value: "'warning'",
          computed: false
        }, {
          value: "'error'",
          computed: false
        }]
      },
      required: false,
      description: "Phrase allowing to set color and appearance of toast (success, warning, error, info)"
    },
    message: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Text to display on notification"
    },
    autoFocus: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true, focus will be drawn to the dismiss button on opening even if autoClose={true}"
    }
  }
};