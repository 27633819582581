import _defineProperty from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import React, { useMemo, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import { useAccessibleRef } from '@jutro/platform';
import { dropdownMenuLinkPropTypes, IntlMessageShape, IntlToShape } from '@jutro/prop-types';
import { useSafeTranslatedUrls, TranslatorContext } from '@jutro/locale';
import styles from "./DropdownMenu.module.css";
export var DropdownMenuLink = function DropdownMenuLink(props) {
  var id = props.id,
    className = props.className,
    activeClassName = props.activeClassName,
    disabled = props.disabled,
    children = props.children,
    onClickProp = props.onClick,
    visible = props.visible,
    focused = props.focused,
    onMenuItemClick = props.onMenuItemClick,
    dangerouslySetInnerHTML = props.dangerouslySetInnerHTML,
    to = props.to,
    href = props.href,
    target = props.target,
    relProp = props.rel,
    other = _objectWithoutProperties(props, ["id", "className", "activeClassName", "disabled", "children", "onClick", "visible", "focused", "onMenuItemClick", "dangerouslySetInnerHTML", "to", "href", "target", "rel"]);
  var translator = useContext(TranslatorContext);
  var urlTranslatorAndSanitizer = useSafeTranslatedUrls();
  var onClick = useMemo(function () {
    return disabled ? function (evt) {
      return evt.preventDefault();
    } : function (evt) {
      onMenuItemClick === null || onMenuItemClick === void 0 ? void 0 : onMenuItemClick();
      onClickProp === null || onClickProp === void 0 ? void 0 : onClickProp(evt);
    };
  }, [disabled, onMenuItemClick, onClickProp]);
  var linkRef = useAccessibleRef(focused);
  if (visible === false) {
    return null;
  }
  var translatedChildren = translator(children);
  var classes = cx(styles.link, _defineProperty({}, styles.disabled, disabled), className);
  var safeTo = to && urlTranslatorAndSanitizer(to);
  if (safeTo) {
    return React.createElement(NavLink, _extends({
      id: id,
      className: classes,
      activeClassName: activeClassName,
      onClick: onClick,
      disabled: disabled,
      "aria-disabled": disabled,
      innerRef: linkRef,
      tabIndex: disabled ? -1 : 0,
      to: safeTo
    }, other), translatedChildren);
  }
  var safeHref = href ? urlTranslatorAndSanitizer(href) : safeTo;
  var rel = target === '_blank' ? 'noreferrer' : relProp;
  return React.createElement("a", _extends({
    id: id,
    className: classes,
    onClick: onClick,
    href: safeHref,
    "aria-disabled": disabled,
    ref: linkRef,
    target: target,
    rel: rel,
    tabIndex: disabled ? -1 : 0
  }, other), translatedChildren);
};
DropdownMenuLink.propTypes = dropdownMenuLinkPropTypes;
DropdownMenuLink.__docgenInfo = {
  componentName: "DropdownMenuLink",
  packageName: "@jutro/router",
  description: "DropdownMenuLink",
  displayName: "DropdownMenuLink",
  methods: [],
  actualName: "DropdownMenuLink",
  metadataType: "action",
  composes: ["@jutro/prop-types"]
};