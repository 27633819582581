import _toConsumableArray from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/toConsumableArray";
import _reverseInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reverse";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _flatInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/flat";
import _spliceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/splice";
import _sliceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/slice";
import _toArray from "@babel/runtime-corejs3/helpers/toArray";
import React from 'react';
import { GridLayout } from './GridLayout';
import { childrenToArray } from './childrenToArray';
export var mergeColumnsToFirst = function mergeColumnsToFirst(children, newColumnsNumber) {
  var _context;
  var numberOfColumnsToMerge = children.length - newColumnsNumber;
  var _children = _toArray(children),
    firstColumn = _children[0],
    restChildren = _sliceInstanceProperty(_children).call(_children, 1);
  var columnsToMerge = _spliceInstanceProperty(restChildren).call(restChildren, 0, numberOfColumnsToMerge);
  var newFirstColumn = React.cloneElement(firstColumn, undefined, [].concat(_toConsumableArray(childrenToArray(firstColumn.props.children)), _toConsumableArray(_flatInstanceProperty(_context = _mapInstanceProperty(columnsToMerge).call(columnsToMerge, function (column) {
    return childrenToArray(column.props.children);
  })).call(_context))));
  return [newFirstColumn].concat(_toConsumableArray(restChildren));
};
export var mergeColumnsToLast = function mergeColumnsToLast(children, newColumnsNumber) {
  var _context2, _context3;
  return _reverseInstanceProperty(_context2 = mergeColumnsToFirst(_reverseInstanceProperty(_context3 = _toConsumableArray(children)).call(_context3), newColumnsNumber)).call(_context2);
};
export var stackColumnsToFirst = function stackColumnsToFirst(children, newColumnsNumber) {
  var numberOfColumnsToStack = children.length - newColumnsNumber;
  var childrenCopy = _toConsumableArray(children);
  var columnsToStack = _spliceInstanceProperty(childrenCopy).call(childrenCopy, 0, numberOfColumnsToStack + 1);
  return [React.createElement(GridLayout, {
    id: _mapInstanceProperty(columnsToStack).call(columnsToStack, function (column) {
      return column.props.id;
    }).join('-')
  }, _mapInstanceProperty(columnsToStack).call(columnsToStack, function (column) {
    return React.cloneElement(column, {
      key: column.props.id
    });
  }))].concat(_toConsumableArray(childrenCopy));
};
export var stackColumnsToLast = function stackColumnsToLast(children, newColumnsNumber) {
  var _context4, _context5;
  return _reverseInstanceProperty(_context4 = stackColumnsToFirst(_reverseInstanceProperty(_context5 = _toConsumableArray(children)).call(_context5), newColumnsNumber)).call(_context4);
};