import _defineProperty2 from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty2(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();
  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
      result;
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }
    return _possibleConstructorReturn(this, result);
  };
}
function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;
  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { uniqueInnerId } from '@jutro/platform';
import { nestedTooltipShape, intlMessageShape } from '@jutro/prop-types';
import styles from "./Card.module.css";
import { CardHeader } from './CardHeader';
export var Card = function (_React$Component) {
  _inherits(Card, _React$Component);
  var _super = _createSuper(Card);
  function Card() {
    var _this;
    _classCallCheck(this, Card);
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    _this = _super.call.apply(_super, [this].concat(args));
    _defineProperty(_assertThisInitialized(_this), "renderHeader", function () {
      var _this$props = _this.props,
        id = _this$props.id,
        title = _this$props.title,
        renderHeader = _this$props.renderHeader,
        headerClass = _this$props.headerClass,
        theme = _this$props.theme,
        tooltip = _this$props.tooltip;
      var _uniqueInnerId = uniqueInnerId(id, 'tooltipId'),
        tooltipId = _uniqueInnerId.tooltipId;
      var tooltipProps = tooltip ? _objectSpread({
        id: tooltipId
      }, tooltip) : null;
      return React.createElement(CardHeader, {
        className: headerClass,
        theme: theme,
        tooltip: tooltipProps
      }, title || renderHeader);
    });
    _defineProperty(_assertThisInitialized(_this), "renderCard", function () {
      var _this$props2 = _this.props,
        id = _this$props2.id,
        className = _this$props2.className,
        children = _this$props2.children,
        renderHeader = _this$props2.renderHeader,
        title = _this$props2.title;
      var cardClass = cx(styles.card, className);
      var _uniqueInnerId2 = uniqueInnerId(id, 'tooltipId'),
        tooltipId = _uniqueInnerId2.tooltipId;
      var ariaDescribedByProp = {};
      if (renderHeader) {
        ariaDescribedByProp['aria-describedby'] = tooltipId;
      }
      return React.createElement("div", _extends({
        id: id,
        className: cardClass
      }, ariaDescribedByProp), (title || renderHeader) && _this.renderHeader(), children);
    });
    return _this;
  }
  _createClass(Card, [{
    key: "render",
    value: function render() {
      return this.renderCard();
    }
  }]);
  return Card;
}(React.Component);
_defineProperty(Card, "propTypes", {
  id: PropTypes.string.isRequired,
  title: intlMessageShape,
  renderHeader: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
  tooltip: nestedTooltipShape
});
Card.__docgenInfo = {
  componentName: "Card",
  packageName: "@jutro/components",
  description: "Renders a card with a header and a body.",
  displayName: "Card",
  methods: [{
    name: "renderHeader",
    docblock: "renders card header element\n\n@returns {React.ReactElement} card header JSX",
    modifiers: [],
    params: [],
    returns: {
      description: "card header JSX",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "renders card header element"
  }, {
    name: "renderCard",
    docblock: "renders card\n@returns {React.ReactElement} card header JSX",
    modifiers: [],
    params: [],
    returns: {
      description: "card header JSX",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "renders card"
  }],
  actualName: "Card",
  metadataType: "container",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: true,
      description: "A unique ID of this card"
    },
    title: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "The header title to be displayed in the Card"
    },
    renderHeader: {
      type: {
        name: "func"
      },
      required: false,
      description: "Callback to render header to be displayed in the Card"
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "CSS class name for this component"
    },
    children: {
      type: {
        name: "node"
      },
      required: false,
      description: "The children elements to render inside of the Card body"
    },
    tooltip: {
      type: {
        name: "custom",
        raw: "nestedTooltipShape"
      },
      required: false,
      description: "Card header tooltip"
    }
  }
};