import _defineProperty from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import React from 'react';
import PropTypes from 'prop-types';
import { createHOC } from '@jutro/platform';
import { intlMessageShape } from '@jutro/prop-types';
import { ValueComponentWrapper } from '@jutro/components';
import { FormattedPhoneNumber, formattedPhoneNumberPropTypes } from './FormattedPhoneNumber';
var PhoneValueInternal = createHOC({
  component: FormattedPhoneNumber,
  wrapper: ValueComponentWrapper,
  displayName: 'PhoneValue'
});
var phoneValuePropTypes = _objectSpread({
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  path: PropTypes.string,
  tag: PropTypes.string,
  prefix: intlMessageShape,
  suffix: intlMessageShape
}, formattedPhoneNumberPropTypes, {
  _rest: PropTypes.any
});
export var PhoneNumberValue = function PhoneNumberValue(props) {
  return React.createElement(PhoneValueInternal, props);
};
PhoneNumberValue.propTypes = phoneValuePropTypes;
PhoneNumberValue.defaultProps = _objectSpread({
  tag: 'div'
}, FormattedPhoneNumber.defaultProps);
PhoneNumberValue.__docgenInfo = {
  componentName: "PhoneNumberValue",
  packageName: "@jutro/lab-preview-intl-phone-number",
  description: "Renders a formatted international phone number using the `tag` property to wrap\nthe value.",
  displayName: "PhoneNumberValue",
  methods: [],
  actualName: "PhoneNumberValue",
  metadataType: "element",
  props: {
    id: {
      type: {
        name: "string"
      },
      required: true,
      description: "Used to identify the component"
    },
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "CSS class name for this component"
    },
    path: {
      type: {
        name: "string"
      },
      required: false,
      description: "Hint path to value"
    },
    tag: {
      type: {
        name: "string"
      },
      required: false,
      description: "The html tag to use when rendering the outermost element of this component",
      defaultValue: {
        value: "'div'",
        computed: false
      }
    },
    prefix: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Optional prefix message to be attached in front of the value"
    },
    suffix: {
      type: {
        name: "union",
        value: [{
          name: "string"
        }, {
          name: "shape",
          value: {
            id: {
              name: "string",
              required: false
            },
            defaultMessage: {
              name: "string",
              required: false
            },
            args: {
              name: "shape",
              value: {},
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Optional suffix message to be attached at the end the value"
    },
    _rest: {
      type: {
        name: "any"
      },
      required: false,
      description: "Do not set this field directly - this is a placeholder for the rest od properties, same as you can pass to FormattedPhoneNumber component"
    }
  },
  composes: ["./FormattedPhoneNumber"]
};