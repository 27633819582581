import _toConsumableArray from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/toConsumableArray";
import _defineProperty from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _objectWithoutProperties2 from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/objectWithoutProperties";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _objectWithoutProperties from "@babel/runtime-corejs3/helpers/objectWithoutProperties";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { SideNavigation, NavRoutes, ApplicationHeader, RightSideBar, SubApplicationHeader } from '@jutro/router';
import { renderContentFromMetadata, resolveProps } from '@jutro/uiconfig';
import { routesShape, contextSwitcherShape, applicationHeaderPropTypes, sideContentShape, deprecateAll } from '@jutro/prop-types';
import AppFloorPlanLayout from './AppFloorPlanLayout';
import useRoutes_DEPRECATED from './useRoutes_DEPRECATED';
import useFloorPlanMatch from './useFloorPlanMatch';
var showNavTypes = ['top', 'left', 'split'];
var useNewRoutes = function useNewRoutes(_ref2) {
  var isLegacyAPI = _ref2.isLegacyAPI,
    showNav = _ref2.showNav,
    routes = _ref2.routes,
    sideRoutes = _ref2.sideRoutes;
  if (!isLegacyAPI) {
    return {
      routes: routes,
      sideRoutes: sideRoutes
    };
  }
  var location = useLocation();
  var _useRoutes_DEPRECATED = useRoutes_DEPRECATED({
      routes: routes,
      pathname: location.pathname,
      showNav: showNav
    }),
    _useRoutes_DEPRECATED2 = _useRoutes_DEPRECATED.topRoutes,
    topRoutes = _useRoutes_DEPRECATED2 === void 0 ? [] : _useRoutes_DEPRECATED2,
    _useRoutes_DEPRECATED3 = _useRoutes_DEPRECATED.sideRoutes,
    sideRoutesOld = _useRoutes_DEPRECATED3 === void 0 ? [] : _useRoutes_DEPRECATED3;
  return {
    routes: topRoutes,
    sideRoutes: sideRoutesOld
  };
};
export var AppFloorPlan = function AppFloorPlan(_ref3) {
  var componentMap = _ref3.componentMap,
    _ref3$callbackMap = _ref3.callbackMap,
    callbackMap = _ref3$callbackMap === void 0 ? {} : _ref3$callbackMap,
    _ref3$classNameMap = _ref3.classNameMap,
    classNameMap = _ref3$classNameMap === void 0 ? {} : _ref3$classNameMap,
    floorPlansProp = _ref3.floorPlans,
    _ref3$showNav = _ref3.showNav,
    showNavLegacy = _ref3$showNav === void 0 ? 'split' : _ref3$showNav,
    contextSwitcherLegacy = _ref3.contextSwitcher,
    props = _objectWithoutProperties2(_ref3, ["componentMap", "callbackMap", "classNameMap", "floorPlans", "showNav", "contextSwitcher"]);
  var isLegacyAPI = !floorPlansProp;
  var floorPlans = floorPlansProp || [props];
  var floorPlan = useFloorPlanMatch(floorPlans);
  var className = floorPlan.className,
    headerProps = floorPlan.header,
    rightSideProps = floorPlan.rightSide,
    _floorPlan$subHeader = floorPlan.subHeader,
    subHeaderProps = _floorPlan$subHeader === void 0 ? {} : _floorPlan$subHeader,
    _floorPlan$routes = floorPlan.routes,
    routesProp = _floorPlan$routes === void 0 ? [] : _floorPlan$routes,
    _floorPlan$sideRoutes = floorPlan.sideRoutes,
    sideRoutesProp = _floorPlan$sideRoutes === void 0 ? [] : _floorPlan$sideRoutes,
    _floorPlan$footer = floorPlan.footer,
    footerProps = _floorPlan$footer === void 0 ? {} : _floorPlan$footer,
    _floorPlan$leftSide = floorPlan.leftSide,
    leftSideProps = _floorPlan$leftSide === void 0 ? {} : _floorPlan$leftSide,
    _floorPlan$scrollCont = floorPlan.scrollContent,
    scrollContent = _floorPlan$scrollCont === void 0 ? true : _floorPlan$scrollCont,
    _floorPlan$showSubHea = floorPlan.showSubHeader,
    showSubHeaderProp = _floorPlan$showSubHea === void 0 ? true : _floorPlan$showSubHea,
    _floorPlan$showHeader = floorPlan.showHeader,
    showHeader = _floorPlan$showHeader === void 0 ? true : _floorPlan$showHeader,
    _floorPlan$showLeftSi = floorPlan.showLeftSide,
    showLeftSideProp = _floorPlan$showLeftSi === void 0 ? true : _floorPlan$showLeftSi,
    _floorPlan$showRightS = floorPlan.showRightSide,
    showRightSide = _floorPlan$showRightS === void 0 ? true : _floorPlan$showRightS,
    _floorPlan$showFooter = floorPlan.showFooter,
    showFooter = _floorPlan$showFooter === void 0 ? true : _floorPlan$showFooter;
  var _useNewRoutes = useNewRoutes({
      isLegacyAPI: isLegacyAPI,
      showNav: showNavLegacy,
      routes: routesProp,
      sideRoutes: sideRoutesProp
    }),
    routes = _useNewRoutes.routes,
    sideRoutes = _useNewRoutes.sideRoutes;
  var resolveAllProps = function resolveAllProps(allProps) {
    return resolveProps(allProps, callbackMap, classNameMap);
  };
  var resolvedHeaderProps = useMemo(function () {
    var _headerProps$commonAv;
    return headerProps && _objectSpread({}, resolveAllProps(headerProps), {
      commonAvatarRoutes: headerProps === null || headerProps === void 0 ? void 0 : (_headerProps$commonAv = headerProps.commonAvatarRoutes) === null || _headerProps$commonAv === void 0 ? void 0 : _mapInstanceProperty(_headerProps$commonAv).call(_headerProps$commonAv, function (route) {
        return route && resolveAllProps(route);
      })
    });
  }, [headerProps]);
  var header = showHeader && React.createElement(ApplicationHeader, _extends({}, resolvedHeaderProps, {
    helpPopoverItems: (headerProps === null || headerProps === void 0 ? void 0 : headerProps.helpPopoverItems) && renderContentFromMetadata(headerProps.helpPopoverItems, undefined, {
      resolveComponentMap: componentMap,
      resolveCallbackMap: callbackMap,
      resolveClassNameMap: classNameMap
    })
  }));
  var visibleRoutes = _filterInstanceProperty(routes).call(routes, function (route) {
    return route.showOnNavBar !== false;
  });
  var subHeaderContextSwitcherProp = subHeaderProps.contextSwitcher,
    otherSubHeaderProps = _objectWithoutProperties(subHeaderProps, ["contextSwitcher"]);
  var subHeaderContextSwitcherLegacy = showNavLegacy === 'top' ? contextSwitcherLegacy : undefined;
  var subHeaderContextSwitcher = isLegacyAPI ? subHeaderContextSwitcherLegacy : subHeaderContextSwitcherProp;
  var showSubHeader = showSubHeaderProp && (visibleRoutes.length > 0 || subHeaderContextSwitcher);
  var subHeader = showSubHeader && React.createElement(SubApplicationHeader, _extends({
    navigationRoutes: visibleRoutes,
    contextSwitcher: subHeaderContextSwitcher
  }, otherSubHeaderProps));
  var visibleLeftSideRoutes = _filterInstanceProperty(sideRoutes).call(sideRoutes, function (route) {
    return route.showOnNavBar !== false;
  });
  var leftSideContextSwitcherProp = leftSideProps.contextSwitcher,
    otherLeftSideProps = _objectWithoutProperties(leftSideProps, ["contextSwitcher"]);
  var leftSideContextSwitcherLegacy = showNavLegacy === 'left' ? contextSwitcherLegacy : undefined;
  var leftSideContextSwitcher = isLegacyAPI ? leftSideContextSwitcherLegacy : leftSideContextSwitcherProp;
  var showLeftSide = showLeftSideProp && (visibleLeftSideRoutes.length > 0 || leftSideContextSwitcher);
  var leftSide = showLeftSide && React.createElement(SideNavigation, _extends({
    routes: sideRoutes,
    contextSwitcher: leftSideContextSwitcher
  }, otherLeftSideProps));
  var _ref = rightSideProps || {},
    collapsible = _ref.collapsible,
    isInitiallyCollapsed = _ref.isInitiallyCollapsed,
    sides = _ref.sides;
  var rightSide = showRightSide && sides && sides.length > 0 && React.createElement(RightSideBar, {
    collapsible: collapsible,
    isInitiallyCollapsed: isInitiallyCollapsed,
    sides: sides,
    componentMap: componentMap
  });
  var footer = showFooter && renderContentFromMetadata({
    content: [_objectSpread({
      id: 'footer',
      component: 'Footer'
    }, footerProps)]
  }, undefined, {
    resolveComponentMap: componentMap,
    resolveCallbackMap: callbackMap,
    resolveClassNameMap: classNameMap
  });
  var allRoutes = [].concat(_toConsumableArray(routes), _toConsumableArray(sideRoutes));
  var floorPlanContent = allRoutes.length > 0 && React.createElement(NavRoutes, {
    resolveComponentMap: componentMap || {},
    routes: allRoutes
  });
  return React.createElement(AppFloorPlanLayout, {
    className: className,
    scrollContent: scrollContent,
    header: header,
    subHeader: subHeader,
    leftSide: leftSide,
    rightSide: rightSide,
    footer: footer
  }, floorPlanContent);
};
var subHeaderProps = {
  className: PropTypes.string,
  contextSwitcher: contextSwitcherShape
};
var rightSideProps = {
  isInitiallyCollapsed: PropTypes.bool,
  collapsible: PropTypes.bool,
  sides: PropTypes.arrayOf(sideContentShape.isRequired)
};
var footerProps = {
  component: PropTypes.string,
  componentProps: PropTypes.shape({}),
  content: PropTypes.array
};
var floorPlanProps = {
  showHeader: PropTypes.bool,
  showSubHeader: PropTypes.bool,
  showFooter: PropTypes.bool,
  showLeftSide: PropTypes.bool,
  showRightSide: PropTypes.bool,
  routes: routesShape,
  sideRoutes: routesShape,
  header: PropTypes.shape({
    commonAvatarRoutes: PropTypes.array,
    helpPopoverItems: PropTypes.array
  }),
  subHeader: PropTypes.shape(subHeaderProps),
  rightSide: PropTypes.shape(rightSideProps),
  footer: PropTypes.shape(footerProps),
  scrollContent: PropTypes.bool,
  className: PropTypes.string,
  leftSide: PropTypes.shape({
    contextSwitcher: contextSwitcherShape
  })
};
var footerProps_DEPRECATED = {
  content: PropTypes.array
};
var rightSideProps_DEPRECATED = {
  isInitiallyCollapsed: PropTypes.bool,
  collapsible: PropTypes.bool,
  sides: PropTypes.arrayOf(sideContentShape.isRequired)
};
var floorPlanProps_OLD = {
  routes: routesShape,
  contextSwitcher: contextSwitcherShape,
  header: PropTypes.shape(applicationHeaderPropTypes),
  rightSide: PropTypes.shape(rightSideProps_DEPRECATED),
  footer: PropTypes.shape(footerProps_DEPRECATED),
  scrollContent: PropTypes.bool,
  className: PropTypes.string,
  showNav: PropTypes.oneOf(showNavTypes)
};
var floorPlanProps_DEPRECATED = deprecateAll(floorPlanProps_OLD, '6.0.0', 'Use floorPlans prop instead');
var routeMatchProps = {
  exact: PropTypes.bool,
  strict: PropTypes.bool,
  sensitive: PropTypes.bool,
  path: PropTypes.string
};
var routeMatchShape = PropTypes.shape(routeMatchProps);
var floorPlanOverrideProps = _objectSpread({}, floorPlanProps, {
  matches: PropTypes.oneOfType([PropTypes.string, routeMatchShape, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, routeMatchShape]).isRequired)]).isRequired
});
var appFloorPlanPropTypes = _objectSpread({
  componentMap: PropTypes.shape({}),
  classNameMap: PropTypes.shape({}),
  callbackMap: PropTypes.shape({}),
  floorPlans: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape(floorPlanProps), PropTypes.shape(floorPlanOverrideProps)]).isRequired)
}, floorPlanProps_DEPRECATED);
AppFloorPlan.propTypes = appFloorPlanPropTypes;
AppFloorPlan.displayName = 'AppFloorPlan';
export default AppFloorPlan;
AppFloorPlan.__docgenInfo = {
  componentName: "AppFloorPlan",
  packageName: "@jutro/floorplan",
  description: "Floorplan for the app, giving ability to use footer, header and nav out of the box",
  displayName: "AppFloorPlan",
  methods: [],
  actualName: "AppFloorPlan",
  props: {
    componentMap: {
      type: {
        name: "shape",
        value: {}
      },
      required: false,
      description: "Resolve component map"
    },
    classNameMap: {
      type: {
        name: "shape",
        value: {}
      },
      required: false,
      description: "Resolve class name map",
      defaultValue: {
        value: "{}",
        computed: false
      }
    },
    callbackMap: {
      type: {
        name: "shape",
        value: {}
      },
      required: false,
      description: "Resolve callback map",
      defaultValue: {
        value: "{}",
        computed: false
      }
    },
    floorPlans: {
      type: {
        name: "arrayOf",
        value: {
          name: "union",
          value: [{
            name: "shape",
            value: {
              showHeader: {
                name: "bool",
                description: "Show/hide Header",
                required: false
              },
              showSubHeader: {
                name: "bool",
                description: "Defines whether the sub navigation is shown or not",
                required: false
              },
              showFooter: {
                name: "bool",
                description: "Show/hide footer",
                required: false
              },
              showLeftSide: {
                name: "bool",
                description: "Show/hide left navigation",
                required: false
              },
              showRightSide: {
                name: "bool",
                description: "Show/hide right panel",
                required: false
              },
              routes: {
                name: "arrayOf",
                description: "Routing metadata object",
                required: false,
                value: {
                  name: "custom",
                  raw: "routeShape"
                }
              },
              sideRoutes: {
                name: "arrayOf",
                description: "App routes to be shown in Left Side",
                required: false,
                value: {
                  name: "custom",
                  raw: "routeShape"
                }
              },
              header: {
                name: "shape",
                value: {
                  commonAvatarRoutes: {
                    name: "array",
                    required: false
                  },
                  helpPopoverItems: {
                    name: "array",
                    required: false
                  }
                },
                description: "Properties for Application Header component",
                required: false
              },
              subHeader: {
                name: "shape",
                value: {
                  className: {
                    name: "string",
                    required: false
                  },
                  contextSwitcher: {
                    name: "shape",
                    required: false,
                    value: {
                      defaultLabel: {
                        name: "custom",
                        raw: "intlMessageShape",
                        description: "Default button label when no context is active",
                        required: false
                      },
                      values: {
                        name: "arrayOf",
                        value: {
                          name: "custom",
                          raw: "contextShape.isRequired"
                        },
                        description: "Array of values for the contexts",
                        required: true
                      }
                    }
                  }
                },
                description: "Sub header config",
                required: false
              },
              rightSide: {
                name: "shape",
                value: {
                  isInitiallyCollapsed: {
                    name: "bool",
                    required: false
                  },
                  collapsible: {
                    name: "bool",
                    required: false
                  },
                  sides: {
                    name: "arrayOf",
                    value: {
                      name: "shape",
                      value: {
                        label: {
                          name: "custom",
                          raw: "intlMessageShape.isRequired",
                          description: "Label describing the content used for accessibility",
                          required: true
                        },
                        icon: {
                          name: "string",
                          description: "Icon that will represent the content on the icon bar",
                          required: false
                        },
                        component: {
                          name: "elementType",
                          description: "Component to render as content",
                          required: true
                        },
                        componentProps: {
                          name: "shape",
                          value: {},
                          description: "Component props",
                          required: false
                        }
                      }
                    },
                    required: false
                  }
                },
                description: "Right panel config",
                required: false
              },
              footer: {
                name: "shape",
                value: {
                  component: {
                    name: "string",
                    required: false
                  },
                  componentProps: {
                    name: "shape",
                    value: {},
                    required: false
                  },
                  content: {
                    name: "array",
                    required: false
                  }
                },
                description: "Children components config for Footer",
                required: false
              },
              scrollContent: {
                name: "bool",
                description: "Make content scrollable",
                required: false
              },
              className: {
                name: "string",
                description: "Additional class names for component.",
                required: false
              },
              leftSide: {
                name: "shape",
                value: {
                  contextSwitcher: {
                    name: "shape",
                    description: "Context switcher object",
                    required: false,
                    value: {
                      defaultLabel: {
                        name: "custom",
                        raw: "intlMessageShape",
                        description: "Default button label when no context is active",
                        required: false
                      },
                      values: {
                        name: "arrayOf",
                        value: {
                          name: "custom",
                          raw: "contextShape.isRequired"
                        },
                        description: "Array of values for the contexts",
                        required: true
                      }
                    }
                  }
                },
                description: "Props passed to SideNavigation",
                required: false
              }
            }
          }, {
            name: "shape",
            value: {
              matches: {
                name: "union",
                value: [{
                  name: "string"
                }, {
                  name: "custom",
                  raw: "routeMatchShape"
                }, {
                  name: "arrayOf",
                  value: {
                    name: "union",
                    value: [{
                      name: "string"
                    }, {
                      name: "custom",
                      raw: "routeMatchShape"
                    }]
                  }
                }],
                description: "The props to match against the current location. If match, the configuration override will be applied",
                required: true
              }
            }
          }]
        }
      },
      required: false,
      description: "Array with the default floorplan and floorplan overrides"
    },
    showNav: {
      defaultValue: {
        value: "'split'",
        computed: false
      },
      required: false
    }
  },
  composes: ["@jutro/prop-types"]
};