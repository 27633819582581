import _slicedToArray from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/slicedToArray";
import _defineProperty from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _Object$entries from "@babel/runtime-corejs3/core-js-stable/object/entries";
import _forEachInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/for-each";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _findInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find";
import { useMemo } from 'react';
import { matchPath } from 'react-router-dom';
var matchRoute = function matchRoute(routes, pathname) {
  return _findInstanceProperty(routes).call(routes, function (route) {
    return matchPath(pathname, {
      path: route.path || undefined,
      exact: route.exact || undefined
    });
  });
};
export default function useRoutes(_ref) {
  var routes = _ref.routes,
    pathname = _ref.pathname,
    showNav = _ref.showNav;
  return useMemo(function () {
    var shouldRenderTop = showNav === 'top';
    var shouldRenderLeft = showNav === 'left';
    var shouldRenderTopAndLeft = showNav === 'split';
    var result = {};
    if (!routes) {
      return result;
    }
    if (shouldRenderTopAndLeft) {
      var topRoutes = _mapInstanceProperty(routes).call(routes, function (route) {
        return _objectSpread({}, route, {
          routes: undefined
        });
      });
      var activeTopRoute = matchRoute(routes, pathname);
      var sideRootPath = activeTopRoute === null || activeTopRoute === void 0 ? void 0 : activeTopRoute.path;
      var sideRoutes = activeTopRoute === null || activeTopRoute === void 0 ? void 0 : activeTopRoute.routes;
      if (!sideRoutes || !sideRootPath) {
        result = {
          topRoutes: topRoutes
        };
      } else {
        var _context;
        var activeSideRoute = matchRoute(sideRoutes, pathname);
        var subPath = activeSideRoute === null || activeSideRoute === void 0 ? void 0 : activeSideRoute.path;
        var tempRoutes = _objectSpread({}, topRoutes, _defineProperty({}, sideRootPath, subPath));
        _forEachInstanceProperty(_context = _Object$entries(tempRoutes)).call(_context, function (_ref2) {
          var _ref3 = _slicedToArray(_ref2, 2),
            key = _ref3[0],
            value = _ref3[1];
          var redirectRoute = _findInstanceProperty(routes).call(routes, function (route) {
            if (route && 'path' in route) {
              var path = route.path,
                exact = route.exact;
              return path === key && exact;
            }
            return false;
          });
          if (redirectRoute) {
            redirectRoute.redirect = value;
          }
        });
        result = {
          topRoutes: topRoutes,
          sideRoutes: sideRoutes
        };
      }
    }
    if (shouldRenderTop) {
      result = {
        topRoutes: routes
      };
    }
    if (shouldRenderLeft) {
      result = {
        sideRoutes: routes
      };
    }
    return result;
  }, [routes, showNav, pathname]);
}