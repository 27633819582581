import _toConsumableArray from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/toConsumableArray";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _sliceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/slice";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _setTimeout from "@babel/runtime-corejs3/core-js-stable/set-timeout";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import _findIndexInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find-index";
import _parseInt from "@babel/runtime-corejs3/core-js-stable/parse-int";
import React, { useState, useLayoutEffect, useRef } from 'react';
import cx from 'classnames';
import merge from "lodash/merge";
import PropTypes from 'prop-types';
import { routesShape, contextSwitcherShape } from '@jutro/prop-types';
import { useDebounce } from '@jutro/platform';
import { TopNavigation } from '../NavBar/TopNavigation/TopNavigation';
import styles from "./ApplicationHeader.module.css";
import { createMoreButton } from './MoreButton';
var getElementPaddings = function getElementPaddings(element, padding) {
  return _parseInt(getComputedStyle(element).getPropertyValue(padding), 10);
};
var countNavItemToSlice = function countNavItemToSlice(initialWidth, elementWidth, arrayWithElements) {
  return arrayWithElements === null || arrayWithElements === void 0 ? void 0 : _findIndexInstanceProperty(arrayWithElements).call(arrayWithElements, function (el) {
    initialWidth += el;
    return initialWidth > elementWidth;
  });
};
var useElementWidth = function useElementWidth() {
  var _useState = useState(0),
    _useState2 = _slicedToArray(_useState, 2),
    elementWidth = _useState2[0],
    setElementWidth = _useState2[1];
  var element = arguments.length <= 0 ? undefined : arguments[0],
    navigationRoutes = arguments.length <= 1 ? undefined : arguments[1];
  useLayoutEffect(function () {
    var elementPaddings;
    if (element.current) {
      var _element$current;
      elementPaddings = getElementPaddings(element.current, 'padding-right') + getElementPaddings(element.current, 'padding-left');
      setElementWidth(((_element$current = element.current) === null || _element$current === void 0 ? void 0 : _element$current.offsetWidth) - elementPaddings);
    }
    var setElementWidthOnResize = function setElementWidthOnResize() {
      var _element$current2;
      element.current.classList.add(styles.hiddenOverflow);
      setElementWidth(((_element$current2 = element.current) === null || _element$current2 === void 0 ? void 0 : _element$current2.offsetWidth) - elementPaddings);
      _setTimeout(function () {
        return element.current.classList.remove(styles.hiddenOverflow);
      }, 1000);
    };
    window.addEventListener('resize', setElementWidthOnResize);
    return function () {
      return window.removeEventListener('resize', setElementWidthOnResize);
    };
  }, [element, navigationRoutes]);
  return elementWidth;
};
var useElementChildrenWidths = function useElementChildrenWidths() {
  var _useState3 = useState([]),
    _useState4 = _slicedToArray(_useState3, 2),
    initialElementChildrenWidthsArray = _useState4[0],
    setInitialElementChildrenWidthsArray = _useState4[1];
  var element = arguments.length <= 0 ? undefined : arguments[0],
    navigationRoutes = arguments.length <= 1 ? undefined : arguments[1];
  useLayoutEffect(function () {
    var _element$current3, _context;
    var navigationContentNode = (_element$current3 = element.current) === null || _element$current3 === void 0 ? void 0 : _element$current3.children;
    var navigationContentNodeWidthsArray = navigationContentNode ? _mapInstanceProperty(_context = _toConsumableArray(navigationContentNode)).call(_context, function (node) {
      return node.offsetWidth;
    }) : undefined;
    setInitialElementChildrenWidthsArray(navigationContentNodeWidthsArray);
  }, [element, navigationRoutes]);
  return initialElementChildrenWidthsArray;
};
var useRoutesForNavigation = function useRoutesForNavigation() {
  var arrayWithElementsWidth = arguments.length <= 0 ? undefined : arguments[0],
    navigationRoutes = arguments.length <= 1 ? undefined : arguments[1],
    elementWidth = arguments.length <= 2 ? undefined : arguments[2],
    moreButtonWidth = arguments.length <= 3 ? undefined : arguments[3];
  var _useState5 = useState(navigationRoutes),
    _useState6 = _slicedToArray(_useState5, 2),
    routesForSubHeader = _useState6[0],
    setRoutesForSubheader = _useState6[1];
  var _useState7 = useState([]),
    _useState8 = _slicedToArray(_useState7, 2),
    routesForMoreButton = _useState8[0],
    setRoutesForMoreButton = _useState8[1];
  var visibleNavigationRoutes = navigationRoutes === null || navigationRoutes === void 0 ? void 0 : _filterInstanceProperty(navigationRoutes).call(navigationRoutes, function (route) {
    return route.showOnNavBar !== false;
  });
  var _useState9 = useState([]),
    _useState10 = _slicedToArray(_useState9, 2),
    subHeaderNavLinksWidths = _useState10[0],
    setSubHeaderNavLinksWidths = _useState10[1];
  var _useState11 = useState([]),
    _useState12 = _slicedToArray(_useState11, 2),
    moreListNavLinksWidths = _useState12[0],
    setMoreListNavLinksWidths = _useState12[1];
  useLayoutEffect(function () {
    var navItemIndexToSlice = countNavItemToSlice(moreButtonWidth, elementWidth, arrayWithElementsWidth);
    if (navItemIndexToSlice > 0) {
      setRoutesForSubheader(visibleNavigationRoutes === null || visibleNavigationRoutes === void 0 ? void 0 : _sliceInstanceProperty(visibleNavigationRoutes).call(visibleNavigationRoutes, 0, navItemIndexToSlice));
      setRoutesForMoreButton(visibleNavigationRoutes === null || visibleNavigationRoutes === void 0 ? void 0 : _sliceInstanceProperty(visibleNavigationRoutes).call(visibleNavigationRoutes, navItemIndexToSlice));
      setSubHeaderNavLinksWidths(arrayWithElementsWidth === null || arrayWithElementsWidth === void 0 ? void 0 : _sliceInstanceProperty(arrayWithElementsWidth).call(arrayWithElementsWidth, 0, navItemIndexToSlice));
      setMoreListNavLinksWidths(arrayWithElementsWidth === null || arrayWithElementsWidth === void 0 ? void 0 : _sliceInstanceProperty(arrayWithElementsWidth).call(arrayWithElementsWidth, navItemIndexToSlice));
    }
    if (navItemIndexToSlice === 0) {
      setRoutesForSubheader([]);
      setRoutesForMoreButton(navigationRoutes);
      setSubHeaderNavLinksWidths([]);
    }
    if (navItemIndexToSlice === -1) {
      setRoutesForSubheader(navigationRoutes);
      setRoutesForMoreButton([]);
    }
  }, [arrayWithElementsWidth, elementWidth, moreButtonWidth, navigationRoutes]);
  return [routesForSubHeader, routesForMoreButton, subHeaderNavLinksWidths, moreListNavLinksWidths];
};
var useMoreListPosition = function useMoreListPosition() {
  var _useState13 = useState(true),
    _useState14 = _slicedToArray(_useState13, 2),
    moreButtonListMenuOnLeft = _useState14[0],
    changeMoreMenuPosition = _useState14[1];
  var moreButtonWidth = arguments.length <= 0 ? undefined : arguments[0],
    element = arguments.length <= 1 ? undefined : arguments[1],
    subHeaderNavLinksWidths = arguments.length <= 2 ? undefined : arguments[2],
    moreListNavLinksWidths = arguments.length <= 3 ? undefined : arguments[3],
    debouncedSubHeaderWidth = arguments.length <= 4 ? undefined : arguments[4];
  useLayoutEffect(function () {
    if (moreListNavLinksWidths.length) {
      var subHeaderPaddings = getElementPaddings(element.current, 'padding-right') + getElementPaddings(element.current, 'padding-left');
      var spaceLeftOnTheRightSideOfMoreButton = debouncedSubHeaderWidth + subHeaderPaddings - _reduceInstanceProperty(subHeaderNavLinksWidths).call(subHeaderNavLinksWidths, function (a, b) {
        return a + b;
      }, 0) - moreButtonWidth;
      if (spaceLeftOnTheRightSideOfMoreButton > Math.max.apply(Math, _toConsumableArray(moreListNavLinksWidths))) {
        changeMoreMenuPosition(false);
      } else {
        changeMoreMenuPosition(true);
      }
      if (!subHeaderNavLinksWidths.length) {
        changeMoreMenuPosition(false);
      }
    }
  }, [debouncedSubHeaderWidth, element, moreButtonWidth, moreListNavLinksWidths, subHeaderNavLinksWidths]);
  return moreButtonListMenuOnLeft;
};
var subApplicationHeaderPropTypes = {
  className: PropTypes.string,
  navigationRoutes: routesShape,
  contextSwitcher: contextSwitcherShape
};
export var SubApplicationHeader = function SubApplicationHeader(_ref) {
  var className = _ref.className,
    navigationRoutes = _ref.navigationRoutes,
    contextSwitcher = _ref.contextSwitcher;
  var classes = cx(styles.subApplicationHeader, className);
  var subHeaderRef = useRef();
  var _useState15 = useState(0),
    _useState16 = _slicedToArray(_useState15, 2),
    moreButtonWidth = _useState16[0],
    setMoreButtonWidth = _useState16[1];
  var subHeaderWidth = useElementWidth(subHeaderRef, navigationRoutes);
  var debouncedSubHeaderWidth = useDebounce(subHeaderWidth, 100);
  var initialChildrenWidthsArray = useElementChildrenWidths(subHeaderRef, navigationRoutes);
  var _useRoutesForNavigati = useRoutesForNavigation(initialChildrenWidthsArray, navigationRoutes, debouncedSubHeaderWidth, moreButtonWidth),
    _useRoutesForNavigati2 = _slicedToArray(_useRoutesForNavigati, 4),
    routesForSubHeader = _useRoutesForNavigati2[0],
    routesForMoreButton = _useRoutesForNavigati2[1],
    subHeaderNavLinksWidths = _useRoutesForNavigati2[2],
    moreListNavLinksWidths = _useRoutesForNavigati2[3];
  var moreButtonListMenuOnLeft = useMoreListPosition(moreButtonWidth, subHeaderRef, subHeaderNavLinksWidths, moreListNavLinksWidths, debouncedSubHeaderWidth);
  var returnRoutes = function returnRoutes(routes) {
    return _mapInstanceProperty(routes).call(routes, function (route) {
      var _route$routes;
      return merge({}, route, {
        navLink: {
          className: ((_route$routes = route.routes) === null || _route$routes === void 0 ? void 0 : _route$routes.length) > 0 ? "".concat(styles.navItem, " ").concat(styles.navigationDropDown) : styles.navItem,
          activeClassName: styles.activeNavItem
        }
      });
    });
  };
  var navRoutes = routesForSubHeader ? returnRoutes(routesForSubHeader) : returnRoutes(navigationRoutes);
  return React.createElement("nav", {
    className: classes
  }, React.createElement(TopNavigation, {
    wrap: true,
    contextSwitcher: contextSwitcher,
    navBarRef: subHeaderRef,
    routes: [].concat(_toConsumableArray(navRoutes), [createMoreButton({
      routes: routesForMoreButton,
      setMoreButtonWidth: setMoreButtonWidth,
      moreButtonListMenuOnLeft: moreButtonListMenuOnLeft
    })])
  }));
};
SubApplicationHeader.defaultProps = {
  navigationRoutes: []
};
SubApplicationHeader.propTypes = subApplicationHeaderPropTypes;
SubApplicationHeader.__docgenInfo = {
  componentName: "SubApplicationHeader",
  packageName: "@jutro/router",
  description: "SubApplicationHeader is subheader for the default header for all Guidewire applications.\nIt contains navigation.",
  displayName: "SubApplicationHeader",
  methods: [],
  actualName: "SubApplicationHeader",
  metadataType: "container",
  props: {
    className: {
      type: {
        name: "string"
      },
      required: false,
      description: "CSS class name for this component"
    },
    navigationRoutes: {
      type: {
        name: "arrayOf",
        value: {
          name: "custom",
          raw: "routeShape"
        }
      },
      required: false,
      description: "Display nav bar defined by routing metadata object. The className and activeClassName in navLink in each element is overwritten",
      defaultValue: {
        value: "[]",
        computed: false
      }
    },
    contextSwitcher: {
      type: {
        name: "shape",
        value: {
          defaultLabel: {
            name: "custom",
            raw: "intlMessageShape",
            description: "Default button label when no context is active",
            required: false
          },
          values: {
            name: "arrayOf",
            value: {
              name: "custom",
              raw: "contextShape.isRequired"
            },
            description: "Array of values for the contexts",
            required: true
          }
        }
      },
      required: false,
      description: "Context switcher object"
    }
  }
};