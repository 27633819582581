import _defineProperty2 from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty2(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _trimInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/trim";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _get from "@babel/runtime-corejs3/helpers/get";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();
  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
      result;
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }
    return _possibleConstructorReturn(this, result);
  };
}
function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;
  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}
import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { ChromePicker } from 'react-color';
import { mergeStyles } from '@jutro/platform';
import cx from 'classnames';
import _ from 'lodash';
import inputStyles from "../InputField/InputField.module.css";
import colorPickerStyles from "./ColorPicker.module.css";
import { FieldComponent } from '../FieldComponent/FieldComponent';
export var ColorPicker = function (_FieldComponent) {
  _inherits(ColorPicker, _FieldComponent);
  var _super = _createSuper(ColorPicker);
  function ColorPicker(props) {
    var _this;
    _classCallCheck(this, ColorPicker);
    _this = _super.call(this, props);
    _defineProperty(_assertThisInitialized(_this), "setClickListener", function () {
      window.addEventListener('click', _this.hideColorSelector);
    });
    _defineProperty(_assertThisInitialized(_this), "unsetClickListener", function () {
      window.removeEventListener('click', _this.hideColorSelector);
    });
    _defineProperty(_assertThisInitialized(_this), "setPageListeners", function () {
      window.addEventListener('scroll', _this.updatePlacement);
      window.addEventListener('resize', _this.updatePlacement);
    });
    _defineProperty(_assertThisInitialized(_this), "unsetPageListeners", function () {
      window.removeEventListener('scroll', _this.updatePlacement);
      window.removeEventListener('resize', _this.updatePlacement);
    });
    _defineProperty(_assertThisInitialized(_this), "hideColorSelector", function (evt) {
      if (!_this.state.isOpen) {
        return;
      }
      var colorSelector = _this.colorSelectorRef.current;
      var self = ReactDOM.findDOMNode(_assertThisInitialized(_this));
      if (self.contains(evt.target) || colorSelector.contains(evt.target)) {
        return;
      }
      _this.toggleColorSelector(false);
    });
    _defineProperty(_assertThisInitialized(_this), "toggleColorSelector", function (showOrHide) {
      var newIsOpen = showOrHide === undefined || showOrHide === null ? !_this.state.isOpen : showOrHide;
      if (_this.state.isOpen !== newIsOpen) {
        _this.setState({
          isOpen: newIsOpen
        });
      }
    });
    _defineProperty(_assertThisInitialized(_this), "updatePlacement", function () {
      if (!_this.colorSelectorRef.current) {
        return;
      }
      var inputSection = _this.inputRef.current;
      var viewportOffset = inputSection.getBoundingClientRect();
      var colorSelectorHeight = _this.getColorSelectorHeight();
      var fitsTop = viewportOffset.top > colorSelectorHeight;
      var fitsBottom = window.innerHeight - viewportOffset.bottom > colorSelectorHeight;
      var newPlacement = _this.props.placement;
      if (fitsTop && !fitsBottom) {
        newPlacement = 'top';
      } else if (fitsBottom && !fitsTop) {
        newPlacement = 'bottom';
      }
      _this.setState({
        placement: newPlacement
      });
    });
    _defineProperty(_assertThisInitialized(_this), "handleTextInputChange", function (evt) {
      var targetValue = evt.target.value;
      var newVal = _trimInstanceProperty(targetValue).call(targetValue);
      _this.notifyChange(newVal);
    });
    _defineProperty(_assertThisInitialized(_this), "handleOnBlur", function (evt) {
      _this.setState({
        editMode: false
      });
      _this.getInputFocusHandlers().onBlur(evt);
    });
    _defineProperty(_assertThisInitialized(_this), "handleOnFocus", function (evt) {
      _this.setState({
        editMode: true
      });
      _this.getInputFocusHandlers().onFocus(evt);
    });
    _defineProperty(_assertThisInitialized(_this), "inputSectionKeyDown", function (evt) {
      if (evt.key === ' ' && (!_this.props.openTyping || !_this.state.editMode)) {
        evt.preventDefault();
        _this.toggleColorSelector();
      }
    });
    _defineProperty(_assertThisInitialized(_this), "onColorSelectorChangeComplete", function (color) {
      return _this.notifyChange(_this.parseInputValue(color));
    });
    _defineProperty(_assertThisInitialized(_this), "setHashCSSVariable", function (input) {
      var hexRegex = new RegExp(/^([A-Fa-f0-9]{6}$)/);
      return hexRegex.test(input) ? "#".concat(input) : input;
    });
    _defineProperty(_assertThisInitialized(_this), "keepSelectorOpen", function (evt) {
      if (_this.state.isOpen) {
        evt.stopPropagation();
      }
    });
    _this.state = {
      isOpen: props.isInitiallyOpen,
      placement: props.placement,
      editMode: false
    };
    _this.colorSelectorRef = React.createRef();
    _this.inputRef = React.createRef();
    return _this;
  }
  _createClass(ColorPicker, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      if (this.state.isOpen) {
        this.setClickListener();
      }
      this.setPageListeners();
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      this.unsetClickListener();
      this.unsetPageListeners();
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(prevProps, prevState) {
      if (this.props.isInitiallyOpen !== prevProps.isInitiallyOpen) {
        this.toggleColorSelector(this.props.isInitiallyOpen);
      }
      if (this.state.isOpen !== prevState.isOpen) {
        if (this.state.isOpen) {
          this.setClickListener();
        } else {
          this.unsetClickListener();
        }
      }
    }
  }, {
    key: "render",
    value: function render() {
      return _get(_getPrototypeOf(ColorPicker.prototype), "render", this).call(this);
    }
  }, {
    key: "getColorSelectorHeight",
    value: function getColorSelectorHeight() {
      var self = ReactDOM.findDOMNode(this);
      var inputField = self.querySelector('div[class*="contentContainer"]');
      var inputFieldOffset = inputField.getBoundingClientRect();
      var colorSelectorOffset = this.colorSelectorRef.current.getBoundingClientRect();
      var topHeight = inputFieldOffset.top - colorSelectorOffset.top;
      var bottomHeight = colorSelectorOffset.bottom - inputFieldOffset.bottom;
      return Math.max(topHeight, bottomHeight);
    }
  }, {
    key: "parseInputValue",
    value: function parseInputValue(color) {
      var source = color.source;
      var value = color[source];
      switch (source) {
        case 'rgb':
          return "rgba(".concat(value.r, ", ").concat(value.g, ", ").concat(value.b, ", ").concat(value.a, ")");
        case 'hsl':
          {
            var h = Math.round(value.h);
            var s = Math.round(value.s * 100);
            var l = Math.round(value.l * 100);
            return "hsl(".concat(h, ", ").concat(s, "%, ").concat(l, "%)");
          }
        default:
          return value;
      }
    }
  }, {
    key: "renderControl",
    value: function renderControl(breakpointProps, options) {
      var _this2 = this;
      var id = breakpointProps.id,
        disabled = breakpointProps.disabled,
        value = breakpointProps.value,
        required = breakpointProps.required,
        openTyping = breakpointProps.openTyping,
        colorPreviewSize = breakpointProps.colorPreviewSize,
        placeholder = breakpointProps.placeholder;
      var _this$state = this.state,
        editMode = _this$state.editMode,
        isOpen = _this$state.isOpen,
        placement = _this$state.placement;
      var inputVal = _.isObject(value) ? this.parseInputValue(value) : this.setHashCSSVariable(value);
      var color = _.isObject(value) ? value : inputVal;
      var combinedStyles = mergeStyles(inputStyles, colorPickerStyles);
      var wrapperClasses = cx(combinedStyles.colorPicker, {
        invalid: !options.isValid
      }, {
        disabled: disabled
      });
      var inputSectionClasses = cx(combinedStyles.colorPicker, combinedStyles.input, combinedStyles.inputContainer, _defineProperty2({}, combinedStyles.focusWithin, editMode), {
        invalid: !options.isValid
      }, {
        disabled: disabled
      });
      var popoverClasses = cx(combinedStyles.popover, _defineProperty2({}, combinedStyles.top, placement === 'top'), _defineProperty2({}, combinedStyles.bottom, placement === 'bottom'));
      var selectorWrapperClasses = cx(combinedStyles.selectorWrapper);
      var colorPreviewStyles = cx(colorPickerStyles.colorPreview, _defineProperty2({}, colorPickerStyles.previewSmall, colorPreviewSize === 'small'));
      var colorPickerInputClasses = cx(combinedStyles.colorPickerInput, _defineProperty2({}, combinedStyles.openTyping, openTyping === true));
      var previewColorStyle = {
        '--color-picker-preview-value': inputVal
      };
      var colorPreview = React.createElement("span", {
        style: previewColorStyle,
        className: colorPreviewStyles
      });
      var inputField = React.createElement("input", _extends({
        id: id,
        type: "text",
        ref: this.inputRef,
        readOnly: !openTyping,
        className: colorPickerInputClasses,
        onClick: openTyping ? this.keepSelectorOpen : undefined,
        value: inputVal,
        placeholder: placeholder,
        onChange: this.handleTextInputChange,
        onBlur: this.handleOnBlur,
        onFocus: this.handleOnFocus,
        disabled: disabled,
        required: required
      }, this.generateDataPathProperty(), this.generateAccessibilityProperties()));
      var colorSelector = React.createElement("div", {
        className: popoverClasses,
        ref: this.colorSelectorRef
      }, React.createElement(ChromePicker, {
        color: color,
        onChangeComplete: this.onColorSelectorChangeComplete
      }));
      var inputSection = React.createElement("div", {
        className: inputSectionClasses,
        onClick: !disabled ? function () {
          return _this2.toggleColorSelector(!isOpen);
        } : undefined,
        "aria-disabled": disabled,
        onKeyPress: !disabled ? this.inputSectionKeyDown : undefined,
        role: "button",
        tabIndex: 0
      }, colorPreview, inputField);
      var selectorWrapper = React.createElement("div", {
        className: selectorWrapperClasses
      }, isOpen && colorSelector);
      return React.createElement("div", {
        className: wrapperClasses
      }, placement === 'top' && !disabled && selectorWrapper, inputSection, placement === 'bottom' && !disabled && selectorWrapper);
    }
  }]);
  return ColorPicker;
}(FieldComponent);
_defineProperty(ColorPicker, "propTypes", _objectSpread({}, FieldComponent.propTypes, {
  value: PropTypes.string,
  placement: PropTypes.string,
  openTyping: PropTypes.bool,
  colorPreviewSize: PropTypes.string,
  isInitiallyOpen: PropTypes.bool
}));
_defineProperty(ColorPicker, "defaultProps", _objectSpread({}, FieldComponent.defaultProps, {
  placement: 'bottom',
  openTyping: false,
  value: '',
  placeholder: '',
  required: false,
  disabled: false
}));
ColorPicker.displayName = 'ColorPicker';
ColorPicker.__docgenInfo = {
  componentName: "ColorPicker",
  packageName: "@jutro/components",
  description: "",
  displayName: "ColorPicker",
  methods: [{
    name: "setClickListener",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "unsetClickListener",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "setPageListeners",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "unsetPageListeners",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "hideColorSelector",
    docblock: "Hides color selector\n@param {object} [evt] - event to react to",
    modifiers: [],
    params: [{
      name: "evt"
    }],
    returns: null,
    description: "Hides color selector"
  }, {
    name: "toggleColorSelector",
    docblock: "Toggles color selector\n@param {boolean} [showOrHide] - flag whether to show or hide the color selector",
    modifiers: [],
    params: [{
      name: "showOrHide"
    }],
    returns: null,
    description: "Toggles color selector"
  }, {
    name: "getColorSelectorHeight",
    docblock: "helper function to get height of chrome picker including margin respective to input field depending where it's currently rendered\n\n@returns {number} - height of chrome picker with bottom or top margin",
    modifiers: [],
    params: [],
    returns: {
      description: "height of chrome picker with bottom or top margin",
      type: {
        name: "number"
      }
    },
    description: "helper function to get height of chrome picker including margin respective to input field depending where it's currently rendered"
  }, {
    name: "updatePlacement",
    docblock: "if position of color selector won't fit in screen it will change it's placement with respect to input field\n If it fits both or doesn't fit both it reverts to default.",
    modifiers: [],
    params: [],
    returns: null,
    description: "if position of color selector won't fit in screen it will change it's placement with respect to input field\n If it fits both or doesn't fit both it reverts to default."
  }, {
    name: "handleTextInputChange",
    docblock: "handles input box if openTyping is enabled\n\n@param {object} evt - React event wrapper",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: null,
    description: "handles input box if openTyping is enabled"
  }, {
    name: "handleOnBlur",
    docblock: "Disables the input edit mode when user leaves the input and calls default FieldComponent.onBlur method\n\n@param {object} evt - React event wrapper",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: null,
    description: "Disables the input edit mode when user leaves the input and calls default FieldComponent.onBlur method"
  }, {
    name: "handleOnFocus",
    docblock: "Enables the input edit mode when user focuses on the input and calls default FieldComponent.onFocus method\n\n@param {object} evt - React event wrapper",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: null,
    description: "Enables the input edit mode when user focuses on the input and calls default FieldComponent.onFocus method"
  }, {
    name: "inputSectionKeyDown",
    docblock: "Enables the user to toggle the color selector with 'space bar'  when user focuses on the input\n\n@param {object} evt - React event wrapper",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: null,
    description: "Enables the user to toggle the color selector with 'space bar'  when user focuses on the input"
  }, {
    name: "onColorSelectorChangeComplete",
    docblock: "updates color via 'react-color' tool when value is changed\n\n@param {object} color - color object provided by 'react-color' package\n@returns {string}",
    modifiers: [],
    params: [{
      name: "color",
      description: "color object provided by 'react-color' package",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: null,
      type: {
        name: "string"
      }
    },
    description: "updates color via 'react-color' tool when value is changed"
  }, {
    name: "parseInputValue",
    docblock: "parses color object value to a string depending on type of color source\n\n@param {object} color - color object provided by 'react-color' package\n@returns {string}",
    modifiers: [],
    params: [{
      name: "color",
      description: "color object provided by 'react-color' package",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: null,
      type: {
        name: "string"
      }
    },
    description: "parses color object value to a string depending on type of color source"
  }, {
    name: "setHashCSSVariable",
    docblock: "appends a hash if input string is of hex format without '#'\n\n@param {String|Object} input - color object provided by 'react-color' package or input string\n@returns {string}",
    modifiers: [],
    params: [{
      name: "input",
      description: "color object provided by 'react-color' package or input string",
      type: {
        name: "union",
        elements: [{
          name: "String"
        }, {
          name: "Object"
        }]
      },
      optional: false
    }],
    returns: {
      description: null,
      type: {
        name: "string"
      }
    },
    description: "appends a hash if input string is of hex format without '#'"
  }, {
    name: "keepSelectorOpen",
    docblock: "if open Typing is enabled and the selector is open this function will\nstop the selector from closing if the user clicks on the text input section\n\n@param {object} evt - input text field",
    modifiers: [],
    params: [{
      name: "evt",
      description: "input text field",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: null,
    description: "if open Typing is enabled and the selector is open this function will\nstop the selector from closing if the user clicks on the text input section"
  }, {
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@param {object} options - rendering options (like 'isInvalid', 'isValid', etc)\n\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: false
    }, {
      name: "options",
      description: "rendering options (like 'isInvalid', 'isValid', etc)",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }],
  actualName: "ColorPicker",
  metadataType: "field",
  props: {
    value: {
      type: {
        name: "string"
      },
      required: false,
      description: "sets the values for the color selector and input field.",
      defaultValue: {
        value: "''",
        computed: false
      }
    },
    placement: {
      type: {
        name: "string"
      },
      required: false,
      description: "default position for the color selector to render if there is insufficient space above and below input field.",
      defaultValue: {
        value: "'bottom'",
        computed: false
      }
    },
    openTyping: {
      type: {
        name: "bool"
      },
      required: false,
      description: "allows user to type into input field if active.",
      defaultValue: {
        value: "false",
        computed: false
      }
    },
    colorPreviewSize: {
      type: {
        name: "string"
      },
      required: false,
      description: "size of the color preview span. 'small' or 'medium' as default"
    },
    isInitiallyOpen: {
      type: {
        name: "bool"
      },
      required: false,
      description: "Should dropdown be initially opened"
    },
    placeholder: {
      defaultValue: {
        value: "''",
        computed: false
      },
      required: false
    },
    required: {
      defaultValue: {
        value: "false",
        computed: false
      },
      required: false
    },
    disabled: {
      defaultValue: {
        value: "false",
        computed: false
      },
      required: false
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
};