import _slicedToArray2 from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/slicedToArray";
import _defineProperty from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _Object$entries from "@babel/runtime-corejs3/core-js-stable/object/entries";
import _reduceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/reduce";
import _Object$values from "@babel/runtime-corejs3/core-js-stable/object/values";
import _everyInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/every";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import React, { useContext, useCallback, useState } from 'react';
import { Button } from '@jutro/components';
import set from "lodash/set";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { Flex } from '@jutro/layout';
import { MetadataForm } from '@jutro/uiconfig';
import { TranslatorContext } from '@jutro/locale';
import { messages } from './FilterBar.messages';
import internalStyles from "./FilterBar.module.css";
var FilterBarContentInternal = function FilterBarContentInternal(_ref) {
  var styles = _ref.styles,
    metadata = _ref.metadata,
    onFiltersChange = _ref.onFiltersChange,
    componentMap = _ref.componentMap,
    callbackMap = _ref.callbackMap,
    isFiltersEmpty = _ref.isFiltersEmpty,
    _ref$initialFilters = _ref.initialFilters,
    initialFilters = _ref$initialFilters === void 0 ? {} : _ref$initialFilters;
  var _context;
  var translator = useContext(TranslatorContext);
  var _useState = useState(initialFilters),
    _useState2 = _slicedToArray(_useState, 2),
    formData = _useState2[0],
    setFormData = _useState2[1];
  var isFormEmpty = _everyInstanceProperty(_context = _Object$values(formData)).call(_context, isEmpty);
  var readValue = function readValue(id, path) {
    return get(formData, path);
  };
  var overrideProps = {
    '@field': {
      className: internalStyles.filterItem
    }
  };
  var handleDataChange = function handleDataChange(value, path) {
    setFormData(function (prevState) {
      var newState = _objectSpread({}, prevState);
      set(newState, path, value);
      return newState;
    });
  };
  var applyFilters = useCallback(function () {
    var _context2;
    onFiltersChange(_reduceInstanceProperty(_context2 = _Object$entries(formData)).call(_context2, function (newFilters, _ref2) {
      var _ref3 = _slicedToArray2(_ref2, 2),
        path = _ref3[0],
        value = _ref3[1];
      if (!(value === undefined || value === null || value === '')) {
        newFilters[path] = value;
      }
      return newFilters;
    }, {}));
  }, [formData, onFiltersChange]);
  var handleClearFilter = useCallback(function () {
    setFormData({});
    onFiltersChange({});
  }, [onFiltersChange, setFormData]);
  var handleFormEnter = useCallback(function (event) {
    if (event.key === 'Enter' && !event.defaultPrevented) {
      applyFilters();
    }
  }, [applyFilters]);
  return React.createElement("div", {
    className: internalStyles.filterBar
  }, React.createElement("div", {
    className: internalStyles.titleBar
  }, React.createElement("div", {
    className: internalStyles.filterTitle
  }, translator(messages.title)), React.createElement("div", null, !isFormEmpty && !isFiltersEmpty && React.createElement(Button, {
    id: "clearFilter",
    type: "text",
    size: "small",
    onClick: handleClearFilter
  }, translator(messages.clearFilters)), React.createElement(Button, {
    id: "applyFilter",
    type: "filled",
    size: "small",
    disabled: isFormEmpty,
    onClick: applyFilters
  }, translator(messages.applyFilters)))), React.createElement("hr", {
    className: internalStyles.titleDivider
  }), React.createElement(Flex, {
    gap: "small",
    onKeyDown: handleFormEnter,
    role: "presentation"
  }, React.createElement(MetadataForm, {
    uiProps: metadata,
    onDataChange: handleDataChange,
    classNameMap: styles,
    resolveValue: readValue,
    callbackMap: callbackMap,
    componentMap: componentMap,
    overrideProps: overrideProps,
    data: formData
  })));
};
FilterBarContentInternal.displayName = 'FilterBarContent';
export var FilterBarContent = React.memo(FilterBarContentInternal);
FilterBarContentInternal.__docgenInfo = {
  componentName: "FilterBarContent",
  packageName: "@jutro/datatable",
  description: "",
  displayName: "FilterBarContent",
  methods: [],
  actualName: "FilterBarContentInternal",
  props: {
    initialFilters: {
      defaultValue: {
        value: "{}",
        computed: false
      },
      required: false
    }
  }
};