import _defineProperty2 from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty2(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _startsWithInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/starts-with";
import _Number$isNaN from "@babel/runtime-corejs3/core-js-stable/number/is-nan";
import _Number$MAX_SAFE_INTEGER from "@babel/runtime-corejs3/core-js-stable/number/max-safe-integer";
import _trimInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/trim";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _get from "@babel/runtime-corejs3/helpers/get";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();
  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
      result;
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }
    return _possibleConstructorReturn(this, result);
  };
}
function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;
  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { IntlContext } from '@jutro/locale';
import { mergeStyles } from '@jutro/platform';
import { isEmptyValue, getDecimalRegex, isSafeNumber } from '@jutro/data';
import { DATA_TYPE_OBJECT, dataTypeShapeWithNumber, currencyValueShape } from '@jutro/prop-types';
import { CurrencyValue } from '../../values/CurrencyValue';
import { valueParserMap } from '../../values/FormattedCurrency';
import currencyStyles from "./CurrencyField.module.css";
import inputStyles from "../InputField/InputField.module.css";
import { FieldComponent } from '../FieldComponent/FieldComponent';
var NO_VALUE_STRING = '-';
export var CurrencyField = function (_FieldComponent) {
  _inherits(CurrencyField, _FieldComponent);
  var _super = _createSuper(CurrencyField);
  function CurrencyField() {
    var _this;
    _classCallCheck(this, CurrencyField);
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    _this = _super.call.apply(_super, [this].concat(args));
    _defineProperty(_assertThisInitialized(_this), "state", {});
    _defineProperty(_assertThisInitialized(_this), "handleChange", function (evt) {
      var targetValue = evt.target.value;
      var decimalNumberRegex = _this.inputRegex;
      if (!decimalNumberRegex.test(targetValue) && !isEmptyValue(targetValue)) {
        return;
      }
      var newAmount = evt.target.type === 'text' ? _trimInstanceProperty(targetValue).call(targetValue) : targetValue;
      if (typeof newAmount === 'string' && !isSafeNumber(newAmount) || newAmount > _Number$MAX_SAFE_INTEGER) {
        return;
      }
      _this.updateValue(newAmount);
    });
    _defineProperty(_assertThisInitialized(_this), "updateValue", function (newValue) {
      var dataType = _this.props.dataType;
      _this.notifyChange(valueParserMap[dataType].newValue(newValue, _this.getCurrency()));
    });
    _defineProperty(_assertThisInitialized(_this), "handleOnBlur", function (evt) {
      _this.updateInputValueToNumber();
      _this.setState({
        editMode: false
      });
      _this.getInputFocusHandlers().onBlur(evt);
    });
    _defineProperty(_assertThisInitialized(_this), "handleOnFocus", function (evt) {
      _this.setState({
        editMode: true
      });
      _this.getInputFocusHandlers().onFocus(evt);
    });
    _defineProperty(_assertThisInitialized(_this), "handleKeyDown", function (event) {
      var ARROW_UP = 38;
      var ARROW_DOWN = 40;
      if (event.keyCode !== ARROW_UP && event.keyCode !== ARROW_DOWN) return;
      var amount = _this.getValue();
      var numberValue;
      if (!isEmptyValue(amount)) {
        numberValue = Math.floor(Number(amount));
        if (_Number$isNaN(numberValue)) {
          numberValue = 0;
        }
      } else {
        numberValue = 0;
      }
      var newVal = event.keyCode === ARROW_UP ? numberValue + 1 : numberValue - 1;
      _this.updateValue(newVal);
      event.preventDefault();
    });
    return _this;
  }
  _createClass(CurrencyField, [{
    key: "render",
    value: function render() {
      return _get(_getPrototypeOf(CurrencyField.prototype), "render", this).call(this);
    }
  }, {
    key: "getCurrency",
    value: function getCurrency() {
      var _this$props = this.props,
        value = _this$props.value,
        defaultCurrency = _this$props.defaultCurrency,
        dataType = _this$props.dataType;
      return valueParserMap[dataType].getCurrency(value, defaultCurrency);
    }
  }, {
    key: "getFractionDigits",
    value: function getFractionDigits() {
      var showFractions = this.props.showFractions;
      return showFractions ? undefined : 0;
    }
  }, {
    key: "renderControlReadOnly",
    value: function renderControlReadOnly(breakpointProps) {
      var id = breakpointProps.id,
        currencyDisplay = breakpointProps.currencyDisplay,
        showFractions = breakpointProps.showFractions;
      var currency = this.getCurrency();
      var classes = cx(currencyStyles.currency, currencyStyles.currencyReadOnly);
      return React.createElement(CurrencyValue, {
        id: id,
        className: classes,
        amount: this.getValue(),
        currency: currency,
        currencyDisplay: currencyDisplay,
        placeholder: FieldComponent.defaultROEmptyValue,
        showFractions: showFractions
      });
    }
  }, {
    key: "getCurrencySymbol",
    value: function getCurrencySymbol(intl) {
      var _context;
      var formatterConfig = {
        style: 'currency',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      };
      var formattedValue = this.formatValue(0, formatterConfig, intl);
      var currencySymbol = _trimInstanceProperty(_context = formattedValue.replace(/\d/g, '')).call(_context);
      return {
        startsWithSymbol: _startsWithInstanceProperty(formattedValue).call(formattedValue, currencySymbol),
        currencySymbol: currencySymbol
      };
    }
  }, {
    key: "getValue",
    value: function getValue() {
      var _this$props2 = this.props,
        value = _this$props2.value,
        dataType = _this$props2.dataType;
      return valueParserMap[dataType].getValue(value);
    }
  }, {
    key: "formatValue",
    value: function formatValue(value, params, intl) {
      if (value === NO_VALUE_STRING) {
        return value;
      }
      var currency = this.getCurrency();
      var formatConfig = _objectSpread({
        currency: currency.length !== 3 ? 'USD' : currency
      }, params);
      return intl.formatters.getNumberFormat(intl.locale, formatConfig).format(value);
    }
  }, {
    key: "updateInputValueToNumber",
    value: function updateInputValueToNumber() {
      var inputVal = this.getValue();
      if (inputVal !== NO_VALUE_STRING) {
        var newVal = isEmptyValue(inputVal) ? null : Number(inputVal);
        this.updateValue(newVal);
      }
    }
  }, {
    key: "isEmpty",
    value: function isEmpty() {
      return isEmptyValue(this.getValue());
    }
  }, {
    key: "generateAccessibilityProperties",
    value: function generateAccessibilityProperties(intl) {
      var formattedPlaceholder = this.formattedPlaceholder(intl);
      return _objectSpread({}, _get(_getPrototypeOf(CurrencyField.prototype), "generateAccessibilityProperties", this).call(this), {
        'aria-placeholder': formattedPlaceholder,
        placeholder: formattedPlaceholder
      });
    }
  }, {
    key: "formatConfig",
    value: function formatConfig() {
      var minimumFractionDigits = this.getFractionDigits();
      var maximumFractionDigits = this.getFractionDigits();
      var currency = this.getCurrency();
      return {
        style: 'currency',
        currencyDisplay: 'symbol',
        currency: currency,
        minimumFractionDigits: minimumFractionDigits,
        maximumFractionDigits: maximumFractionDigits
      };
    }
  }, {
    key: "formattedPlaceholder",
    value: function formattedPlaceholder(intl) {
      var placeholder = this.props.placeholder;
      var _this$getCurrencySymb = this.getCurrencySymbol(intl),
        currencySymbol = _this$getCurrencySymb.currencySymbol;
      var formattedPlaceholder = placeholder;
      if (placeholder && !_Number$isNaN(+placeholder)) {
        var _context2;
        formattedPlaceholder = _trimInstanceProperty(_context2 = intl.formatNumber(placeholder, this.formatConfig()).replace(currencySymbol, '')).call(_context2);
      }
      return formattedPlaceholder;
    }
  }, {
    key: "renderComponent",
    value: function renderComponent(props, intl, options) {
      var id = props.id,
        disabled = props.disabled,
        required = props.required,
        className = props.className,
        controlClassName = props.controlClassName,
        currencyDisplay = props.currencyDisplay;
      var editMode = this.state.editMode;
      var _this$getCurrencySymb2 = this.getCurrencySymbol(intl),
        startsWithSymbol = _this$getCurrencySymb2.startsWithSymbol,
        currencySymbol = _this$getCurrencySymb2.currencySymbol;
      var inputVal = this.getValue();
      if (isEmptyValue(inputVal)) {
        inputVal = '';
      }
      if (!editMode && !isEmptyValue(inputVal) && inputVal !== NO_VALUE_STRING) {
        var _context3;
        inputVal = _trimInstanceProperty(_context3 = intl.formatNumber(inputVal, this.formatConfig()).replace(currencySymbol, '')).call(_context3);
      }
      var combinedStyles = mergeStyles(inputStyles, currencyStyles);
      var wrapperClasses = cx(combinedStyles.input, combinedStyles.currency, combinedStyles.inputContainer, _defineProperty2({}, combinedStyles.focusWithin, editMode), {
        invalid: !options.isValid
      }, {
        disabled: disabled
      }, className);
      var inputClasses = cx(combinedStyles.currencyInput, controlClassName);
      var currencyValue = currencyDisplay === 'symbol' ? this.getCurrencySymbol(intl).currencySymbol : this.getCurrency();
      var symbol = React.createElement("div", {
        className: combinedStyles.currencySymbol
      }, React.createElement("span", null, currencyValue));
      var inputField = React.createElement("input", _extends({
        id: id,
        type: "text",
        className: inputClasses,
        value: inputVal,
        onChange: this.handleChange,
        disabled: disabled,
        required: required,
        inputMode: "decimal",
        autoComplete: "off",
        onBlur: this.handleOnBlur,
        onFocus: this.handleOnFocus,
        onKeyDown: this.handleKeyDown
      }, this.generateDataPathProperty(), this.generateAccessibilityProperties(intl)));
      return React.createElement("div", {
        className: wrapperClasses
      }, startsWithSymbol && symbol, inputField, !startsWithSymbol && symbol);
    }
  }, {
    key: "renderControl",
    value: function renderControl(breakpointProps, options) {
      var _this2 = this;
      return React.createElement(IntlContext.Consumer, null, function (intl) {
        return _this2.renderComponent(breakpointProps, intl, options);
      });
    }
  }, {
    key: "inputRegex",
    get: function get() {
      var showFractions = this.props.showFractions;
      return showFractions ? getDecimalRegex(2) : getDecimalRegex(0);
    }
  }]);
  return CurrencyField;
}(FieldComponent);
_defineProperty(CurrencyField, "propTypes", _objectSpread({}, FieldComponent.propTypes, {
  value: currencyValueShape,
  defaultCurrency: PropTypes.string,
  currencyDisplay: PropTypes.oneOf(['code', 'name', 'symbol']),
  showFractions: PropTypes.bool,
  defaultValue: currencyValueShape,
  dataType: dataTypeShapeWithNumber
}));
_defineProperty(CurrencyField, "defaultProps", _objectSpread({}, FieldComponent.defaultProps, {
  defaultCurrency: 'USD',
  currencyDisplay: 'symbol',
  showFractions: true,
  dataType: DATA_TYPE_OBJECT
}));
_defineProperty(CurrencyField, "contextType", FieldComponent.contextType);
CurrencyField.__docgenInfo = {
  componentName: "CurrencyField",
  packageName: "@jutro/components",
  description: "Use it to enter or display a monetary value. The amount is displayed based on the locale.\n\nIf you want to use it to display the localized value as text, and not an input, set `readOnly` to `true`.\n\nThe component can handle dataTypes of `object` or `number` which is set by dataType prop.",
  displayName: "CurrencyField",
  methods: [{
    name: "inputRegex",
    docblock: "creates regex for input field.\nAllows decimal point '.' and 0 to 2 numbers to the right if showFractions is true.\nDoes not allow a decimal point if showFractions is false,\n\n@returns {Function} regex for input field",
    modifiers: ["get"],
    params: [],
    returns: {
      description: "regex for input field",
      type: {
        name: "Function"
      }
    },
    description: "creates regex for input field.\nAllows decimal point '.' and 0 to 2 numbers to the right if showFractions is true.\nDoes not allow a decimal point if showFractions is false,"
  }, {
    name: "handleChange",
    docblock: "Override FieldComponent.handleChange to deal with value as an object\n\n@param {object} evt - React event wrapper",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: null,
    description: "Override FieldComponent.handleChange to deal with value as an object"
  }, {
    name: "getCurrency",
    docblock: "Returns currency\n@returns {object}",
    modifiers: [],
    params: [],
    returns: {
      description: null,
      type: {
        name: "object"
      }
    },
    description: "Returns currency"
  }, {
    name: "getFractionDigits",
    docblock: "Returns faction digits\n@returns {(string|undefined)}",
    modifiers: [],
    params: [],
    returns: {
      description: null,
      type: {
        name: "union",
        elements: [{
          name: "string"
        }, null]
      }
    },
    description: "Returns faction digits"
  }, {
    name: "renderControlReadOnly",
    docblock: "Render readonly control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render readonly control for this component."
  }, {
    name: "getCurrencySymbol",
    docblock: "Returns the currency symbol and its position\n\n@param {object} intl - i18n context\n@returns {object}",
    modifiers: [],
    params: [{
      name: "intl",
      description: "i18n context",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: null,
      type: {
        name: "object"
      }
    },
    description: "Returns the currency symbol and its position"
  }, {
    name: "getValue",
    docblock: "Helper to return the value\n@returns {number | string}",
    modifiers: [],
    params: [],
    returns: {
      description: null,
      type: {
        name: "union",
        elements: [{
          name: "number"
        }, {
          name: "string"
        }]
      }
    },
    description: "Helper to return the value"
  }, {
    name: "formatValue",
    docblock: "Formatter helper to return the value with dots, commas and spaces based on locale and configs provided\n\n@param {number | string} value\n@param {object} params\n@param {number} params.value\n@param {string} params.currency\n@param {number} params.minimumFractionDigits\n@param {number} params.maximumFractionDigits\n@param {object} intl - i18n context\n@returns {string}",
    modifiers: [],
    params: [{
      name: "value",
      description: null,
      type: {
        name: "union",
        elements: [{
          name: "number"
        }, {
          name: "string"
        }]
      },
      optional: false
    }, {
      name: "params",
      description: null,
      type: {
        name: "object"
      },
      optional: false
    }, {
      name: "intl",
      description: "i18n context",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: null,
      type: {
        name: "string"
      }
    },
    description: "Formatter helper to return the value with dots, commas and spaces based on locale and configs provided"
  }, {
    name: "updateValue",
    docblock: "Helper function that calls notify change with the correct format depending on value prop dataType\n@param {number|string} newValue",
    modifiers: [],
    params: [{
      name: "newValue",
      description: null,
      type: {
        name: "union",
        elements: [{
          name: "number"
        }, {
          name: "string"
        }]
      },
      optional: false
    }],
    returns: null,
    description: "Helper function that calls notify change with the correct format depending on value prop dataType"
  }, {
    name: "updateInputValueToNumber",
    docblock: "Helper function that sets value amount to dataType number",
    modifiers: [],
    params: [],
    returns: null,
    description: "Helper function that sets value amount to dataType number"
  }, {
    name: "handleOnBlur",
    docblock: "Updates the input type to \"text\" when user leaves the input and call default FieldComponent.onBlur method\n\n@param {object} evt - React event wrapper",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: null,
    description: "Updates the input type to \"text\" when user leaves the input and call default FieldComponent.onBlur method"
  }, {
    name: "handleOnFocus",
    docblock: "Updates the input type to \"number\" when user focus in the input and call default FieldComponent.onFocus method\n\n@param {object} evt - React event wrapper",
    modifiers: [],
    params: [{
      name: "evt",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: null,
    description: "Updates the input type to \"number\" when user focus in the input and call default FieldComponent.onFocus method"
  }, {
    name: "handleKeyDown",
    docblock: "Specific onKeyDown handler function for number inputs\n\n@param {object} event - React event wrapper",
    modifiers: [],
    params: [{
      name: "event",
      description: "React event wrapper",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: null,
    description: "Specific onKeyDown handler function for number inputs"
  }, {
    name: "isEmpty",
    docblock: "Helper to check if value amount is empty\n\n@returns {boolean} true or false if value is empty",
    modifiers: [],
    params: [],
    returns: {
      description: "true or false if value is empty",
      type: {
        name: "boolean"
      }
    },
    description: "Helper to check if value amount is empty"
  }, {
    name: "generateAccessibilityProperties",
    docblock: "generates accessibility properties for the field component\n@param {object} intl - i18n context\n@returns {object} set of applicable wai-aria tags",
    modifiers: [],
    params: [{
      name: "intl",
      description: "i18n context",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: "set of applicable wai-aria tags",
      type: {
        name: "object"
      }
    },
    description: "generates accessibility properties for the field component"
  }, {
    name: "formatConfig",
    docblock: null,
    modifiers: [],
    params: [],
    returns: null
  }, {
    name: "formattedPlaceholder",
    docblock: null,
    modifiers: [],
    params: [{
      name: "intl",
      optional: undefined,
      type: null
    }],
    returns: null
  }, {
    name: "renderComponent",
    docblock: "Render control for this component.\n\n@param {object} props - breakpoint-specific props\n@param {object} intl - i18n context\n@param {object} options - rendering options (like 'isInvalid', 'isValid', etc)\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "props",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: false
    }, {
      name: "intl",
      description: "i18n context",
      type: {
        name: "object"
      },
      optional: false
    }, {
      name: "options",
      description: "rendering options (like 'isInvalid', 'isValid', etc)",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }, {
    name: "renderControl",
    docblock: null,
    modifiers: [],
    params: [{
      name: "breakpointProps",
      optional: undefined,
      type: null
    }, {
      name: "options",
      optional: undefined,
      type: null
    }],
    returns: null
  }],
  actualName: "CurrencyField",
  metadataType: "field",
  props: {
    value: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }, {
          name: "shape",
          value: {
            amount: {
              name: "union",
              value: [{
                name: "number"
              }, {
                name: "string"
              }],
              required: false
            },
            currency: {
              name: "string",
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Value to display in the Currency field"
    },
    defaultCurrency: {
      type: {
        name: "string"
      },
      required: false,
      description: "Default currency",
      defaultValue: {
        value: "'USD'",
        computed: false
      }
    },
    currencyDisplay: {
      type: {
        name: "enum",
        value: [{
          value: "'code'",
          computed: false
        }, {
          value: "'name'",
          computed: false
        }, {
          value: "'symbol'",
          computed: false
        }]
      },
      required: false,
      description: "How to display the currency in currency formatting, 'code', 'symbol' or 'name'",
      defaultValue: {
        value: "'symbol'",
        computed: false
      }
    },
    showFractions: {
      type: {
        name: "bool"
      },
      required: false,
      description: "If true, displays the fraction part of the amount",
      defaultValue: {
        value: "true",
        computed: false
      }
    },
    defaultValue: {
      type: {
        name: "union",
        value: [{
          name: "number"
        }, {
          name: "string"
        }, {
          name: "shape",
          value: {
            amount: {
              name: "union",
              value: [{
                name: "number"
              }, {
                name: "string"
              }],
              required: false
            },
            currency: {
              name: "string",
              required: false
            }
          }
        }]
      },
      required: false,
      description: "Default value"
    },
    dataType: {
      type: {
        name: "enum",
        value: [{
          value: "'object'",
          computed: false
        }, {
          value: "'string'",
          computed: false
        }, {
          value: "'number'",
          computed: false
        }]
      },
      required: false,
      description: "Sets the datatype type of the value prop. When set to 'string' accepts\nstrings containing currency symbol i.e. '123.23 usd'",
      defaultValue: {
        value: "'object'",
        computed: false
      }
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
};