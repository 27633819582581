import _defineProperty from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _Array$isArray from "@babel/runtime-corejs3/core-js-stable/array/is-array";
import _someInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/some";
import _filterInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/filter";
import _findInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/find";
import _slicedToArray from "@babel/runtime-corejs3/helpers/slicedToArray";
import { useMemo } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { isFloorPlanOverride, isFloorPlanDefault } from './helpers';
export default function useFloorPlanMatch(floorPlans) {
  var location = useLocation();
  var _useMemo = useMemo(function () {
      return [_findInstanceProperty(floorPlans).call(floorPlans, isFloorPlanDefault), _filterInstanceProperty(floorPlans).call(floorPlans, isFloorPlanOverride)];
    }, [floorPlans]),
    _useMemo2 = _slicedToArray(_useMemo, 2),
    defaultFloorPlan = _useMemo2[0],
    floorPlanOverrides = _useMemo2[1];
  return useMemo(function () {
    var matchedFloorplanOverride = _findInstanceProperty(floorPlanOverrides).call(floorPlanOverrides, function (_ref) {
      var matches = _ref.matches;
      var _context;
      return _someInstanceProperty(_context = _Array$isArray(matches) ? matches : [matches]).call(_context, function (pathMatch) {
        return matchPath(location.pathname, pathMatch);
      });
    });
    return _objectSpread({}, defaultFloorPlan, {}, matchedFloorplanOverride);
  }, [defaultFloorPlan, floorPlanOverrides, location.pathname]);
}