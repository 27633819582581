import _defineProperty2 from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _toConsumableArray from "D:\\VE\\POC\\SWIFMod_CC_Portal_11_4_1\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/esm/toConsumableArray";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty2(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _Reflect$construct from "@babel/runtime-corejs3/core-js-stable/reflect/construct";
import _extends from "@babel/runtime-corejs3/helpers/extends";
import _mapInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/map";
import _spliceInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/splice";
import _indexOfInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/index-of";
import _includesInstanceProperty from "@babel/runtime-corejs3/core-js-stable/instance/includes";
import _classCallCheck from "@babel/runtime-corejs3/helpers/classCallCheck";
import _createClass from "@babel/runtime-corejs3/helpers/createClass";
import _assertThisInitialized from "@babel/runtime-corejs3/helpers/assertThisInitialized";
import _get from "@babel/runtime-corejs3/helpers/get";
import _inherits from "@babel/runtime-corejs3/helpers/inherits";
import _possibleConstructorReturn from "@babel/runtime-corejs3/helpers/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime-corejs3/helpers/getPrototypeOf";
import _defineProperty from "@babel/runtime-corejs3/helpers/defineProperty";
function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();
  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
      result;
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = _Reflect$construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }
    return _possibleConstructorReturn(this, result);
  };
}
function _isNativeReflectConstruct() {
  if (typeof Reflect === "undefined" || !_Reflect$construct) return false;
  if (_Reflect$construct.sham) return false;
  if (typeof Proxy === "function") return true;
  try {
    Date.prototype.toString.call(_Reflect$construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { dataTypeShape, availableValueObjectShape } from '@jutro/prop-types';
import { FieldComponent } from '../FieldComponent/FieldComponent';
import { CheckboxField } from '../CheckboxField/CheckboxField';
import styles from "./CheckboxGroupField.module.css";
export var CheckboxGroupField = function (_FieldComponent) {
  _inherits(CheckboxGroupField, _FieldComponent);
  var _super = _createSuper(CheckboxGroupField);
  function CheckboxGroupField() {
    var _this;
    _classCallCheck(this, CheckboxGroupField);
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    _this = _super.call.apply(_super, [this].concat(args));
    _defineProperty(_assertThisInitialized(_this), "handleValueChange", function (code) {
      var value = _this.props.value ? _toConsumableArray(_this.props.value) : [];
      if (!_includesInstanceProperty(value).call(value, code)) {
        value.push(code);
      } else {
        var index = _indexOfInstanceProperty(value).call(value, code);
        _spliceInstanceProperty(value).call(value, index, 1);
      }
      _this.notifyChange(value);
    });
    return _this;
  }
  _createClass(CheckboxGroupField, [{
    key: "render",
    value: function render() {
      return _get(_getPrototypeOf(CheckboxGroupField.prototype), "render", this).call(this);
    }
  }, {
    key: "renderLabel",
    value: function renderLabel() {
      return _get(_getPrototypeOf(CheckboxGroupField.prototype), "renderLabel", this).call(this, this.props);
    }
  }, {
    key: "renderTooltipIcon",
    value: function renderTooltipIcon(props) {
      var _tooltip$text;
      var tooltip = this.props.tooltip;
      var tooltipText = (_tooltip$text = tooltip === null || tooltip === void 0 ? void 0 : tooltip.text) !== null && _tooltip$text !== void 0 ? _tooltip$text : tooltip;
      var tooltipClassName = tooltip === null || tooltip === void 0 ? void 0 : tooltip.className;
      var newProps = _objectSpread({}, props, {
        tooltip: _objectSpread({}, tooltip, {
          text: tooltipText,
          className: cx(tooltipClassName, styles.tooltip)
        })
      });
      return _get(_getPrototypeOf(CheckboxGroupField.prototype), "renderTooltipIcon", this).call(this, newProps);
    }
  }, {
    key: "renderCheckboxValues",
    value: function renderCheckboxValues(props, values) {
      var _this2 = this;
      var disabled = props.disabled,
        value = props.value;
      var classes = cx(styles.checkboxGroup);
      return _mapInstanceProperty(values).call(values, function (_ref) {
        var code = _ref.code,
          name = _ref.name,
          subtitle = _ref.subtitle,
          secondaryLabel = _ref.secondaryLabel;
        var isCheckedVal = value && _includesInstanceProperty(value).call(value, code);
        var onValueChange = function onValueChange() {
          return _this2.handleValueChange(code);
        };
        return React.createElement(CheckboxField, {
          id: code,
          label: name,
          secondaryLabel: subtitle || secondaryLabel,
          key: code,
          className: classes,
          showInlineLabel: true,
          required: false,
          disabled: disabled,
          value: isCheckedVal,
          onValueChange: onValueChange
        });
      });
    }
  }, {
    key: "generateAccessibilityProperties",
    value: function generateAccessibilityProperties(props) {
      var translator = this.translator;
      var label = props.label;
      return _objectSpread({}, _get(_getPrototypeOf(CheckboxGroupField.prototype), "generateAccessibilityProperties", this).call(this), {
        'aria-label': translator(label)
      });
    }
  }, {
    key: "renderControl",
    value: function renderControl(breakpointProps) {
      var id = breakpointProps.id,
        availableValues = breakpointProps.availableValues,
        controlClassName = breakpointProps.controlClassName;
      var checkboxes = this.renderCheckboxValues(breakpointProps, availableValues);
      return React.createElement("div", _extends({
        id: id,
        className: controlClassName
      }, this.generateAccessibilityProperties(breakpointProps)), checkboxes);
    }
  }]);
  return CheckboxGroupField;
}(FieldComponent);
_defineProperty(CheckboxGroupField, "propTypes", _objectSpread({}, FieldComponent.propTypes, {
  availableValues: PropTypes.arrayOf(availableValueObjectShape),
  value: PropTypes.arrayOf(PropTypes.string),
  dataType: dataTypeShape
}));
_defineProperty(CheckboxGroupField, "defaultProps", _objectSpread({}, FieldComponent.defaultProps, {
  availableValues: []
}));
CheckboxGroupField.__docgenInfo = {
  componentName: "CheckboxGroupField",
  packageName: "@jutro/components",
  description: "",
  displayName: "CheckboxGroupField",
  methods: [{
    name: "handleValueChange",
    docblock: "Handles value change\n@param {string} code",
    modifiers: [],
    params: [{
      name: "code",
      description: null,
      type: {
        name: "string"
      },
      optional: false
    }],
    returns: null,
    description: "Handles value change"
  }, {
    name: "renderLabel",
    docblock: "Render label for this component. Override FieldComponent renderLabel\n\n@returns {React.ReactElement} JSX for the label",
    modifiers: [],
    params: [],
    returns: {
      description: "JSX for the label",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render label for this component. Override FieldComponent renderLabel"
  }, {
    name: "renderTooltipIcon",
    docblock: null,
    modifiers: [],
    params: [{
      name: "props",
      optional: undefined,
      type: null
    }],
    returns: null
  }, {
    name: "renderCheckboxValues",
    docblock: "Render the checkboxes for the specified values\n\n@param {object} props\n@param {Array<any>} values - values to render\n@returns {React.ReactElement} JSX for the radio buttons",
    modifiers: [],
    params: [{
      name: "props",
      description: null,
      type: {
        name: "object"
      },
      optional: false
    }, {
      name: "values",
      description: "values to render",
      type: {
        name: "Array",
        elements: [{
          name: "any"
        }]
      },
      optional: false
    }],
    returns: {
      description: "JSX for the radio buttons",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render the checkboxes for the specified values"
  }, {
    name: "generateAccessibilityProperties",
    docblock: "generates accessibility properties for the field component\n\n@param {object} props\n@returns {object} set of applicable wai-aria tags",
    modifiers: [],
    params: [{
      name: "props",
      description: null,
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: "set of applicable wai-aria tags",
      type: {
        name: "object"
      }
    },
    description: "generates accessibility properties for the field component"
  }, {
    name: "renderControl",
    docblock: "Render control for this component.\n\n@param {object} breakpointProps - breakpoint-specific props\n@returns {React.ReactElement} JSX for the control",
    modifiers: [],
    params: [{
      name: "breakpointProps",
      description: "breakpoint-specific props",
      type: {
        name: "object"
      },
      optional: false
    }],
    returns: {
      description: "JSX for the control",
      type: {
        name: "React.ReactElement"
      }
    },
    description: "Render control for this component."
  }],
  actualName: "CheckboxGroupField",
  metadataType: "field",
  props: {
    availableValues: {
      type: {
        name: "arrayOf",
        value: {
          name: "union",
          value: [{
            name: "shape",
            value: {
              code: {
                name: "union",
                value: [{
                  name: "string"
                }, {
                  name: "number"
                }],
                required: false
              },
              name: {
                name: "custom",
                raw: "intlMessageShape",
                required: false
              },
              subtitle: {
                name: "custom",
                raw: "intlMessageShape",
                required: false
              },
              secondaryLabel: {
                name: "custom",
                raw: "intlMessageShape",
                required: false
              }
            }
          }, {
            name: "shape",
            value: {
              id: {
                name: "union",
                value: [{
                  name: "string"
                }, {
                  name: "number"
                }],
                required: false
              },
              displayName: {
                name: "custom",
                raw: "intlMessageShape",
                required: false
              },
              subtitle: {
                name: "custom",
                raw: "intlMessageShape",
                required: false
              },
              secondaryLabel: {
                name: "custom",
                raw: "intlMessageShape",
                required: false
              }
            }
          }]
        }
      },
      required: false,
      description: "Array of available checkboxes",
      defaultValue: {
        value: "[]",
        computed: false
      }
    },
    value: {
      type: {
        name: "arrayOf",
        value: {
          name: "string"
        }
      },
      required: false,
      description: "List of the selected checkboxes codes."
    },
    dataType: {
      type: {
        name: "enum",
        value: [{
          value: "'object'",
          computed: false
        }, {
          value: "'string'",
          computed: false
        }]
      },
      required: false,
      description: "The format of the items in the value array"
    }
  },
  composes: ["../FieldComponent/FieldComponent"]
};